/*
 * dispenseISpiroDevicePopupController
 * */
/* eslint-disable no-underscore-dangle */
class DispenseISpiroDevicePopupController {
	constructor($log, $timeout, iSpiroService, loadingIndicatorService,
		signatureService, appI18n, appConfig, $stateParams, $q,
		ertBasePopupService, notificationService, userService) {
		'ngInject';
		this.$log = $log;
		this.$timeout = $timeout;
		this.$stateParams = $stateParams;
		this.iSpiroService = iSpiroService;
		this.loadingIndicatorService = loadingIndicatorService;
		this.signatureService = signatureService;
		this.ertBasePopupService = ertBasePopupService;
		this.notificationService = notificationService;
		this.userService = userService;
		this.appI18n = appI18n;
		this.appConfig = appConfig;
		this.$q = $q;
		this.onlineHelpId = 'MSP3.ClinicalData.ISpiro.DispenseDevice';

		this.isLoading = false;
		this.$API = null;
		this.iSpiroForm = null;
		this.state = null;
		this.hasDOB = false;
		this.dobIsInFutureMsg = null;
		this.errors = {
			genericError: false
		};

		// SITES
		this.sites = null;
		this.selectedSite = null;
		this.sitesOptions = {
			label: 'app.common.siteId',
			disabled: false,
			id: 'sitesDropdown',
			required: true,
			placeholder: 'clinicaldata.subject.create.selectSite',
			uiValue: 'displaySiteName'
		};

		// SUBJECTS
		this.subjects = [];
		this.selectedSubject = null;
		this.subjectNumber = null;
		this.subjectsOptions = {
			label: 'app.common.subjectKey',
			id: 'subjectsDropdown',
			required: true,
			placeholder: 'clinicaldata.subject.i-spiro.close-dispense.selectSubject',
			uiValue: 'subjectKey'
		};

		// SUBJECT FIELDS
		this.subjectFields = null;

		// PARAMETER FIELDS
		this.parameterFields = [];
		this.parametersModel = {
			FEV1: null,
			FVC: null,
			PEF: null
		};

		// PARAMETERS VALIDATION
		this.onlyNumeric = {
			fn: (inputValue) => {
				if (!inputValue) { return true; }
				return !isNaN(Number(inputValue));
			}
		};

		this.getMinMaxValidator = (field) => {
			return {
				fn: (inputValue) => {
					if (!inputValue) { return true; }
					return !(inputValue < +field.minimumValue || inputValue > +field.maximumValue);
				}
			};
		};

		this.getNumberOfDecimalsValidator = (field) => {
			return {
				fn: (inputValue) => {
					if (!inputValue) { return true; }

					const decimals = field.numberOfDecimals;
					const fraction = `${inputValue}`.split('.');
					const decimalsNr = fraction[1] && fraction[1].length;

					return !(decimalsNr && decimalsNr > decimals);
				}
			};
		};

		this.isRequiredText = 'clinicaldata.subject.create.errors.required.general';
		this.onlyNumericText = 'clinicaldata.subject.create.validation.onlyNumeric';
		 
		this.getMinMaxMessage = (field) => {
			return this.appI18n.translateImmediate('clinicaldata.subject.create.validation.minMaxText').supplant({
				minValue: field.minimumValue,
				maxValue: field.maximumValue
			});
		};

		this.getNumberOfDecimalsText = (field) => {
			return this.appI18n.translateImmediate('clinicaldata.subject.create.validation.numberOfDecimals').supplant({
				numberOfDecimals: field.numberOfDecimals
			});
		};

		this.getNumberOfDecimalsValidationText = (field) => {
			return field.numberOfDecimals > 0
			? (this.appI18n.translateImmediate('clinicaldata.subject.create.validation.withDecimalMessage')
			.supplant({
				numberOfDecimals: field.numberOfDecimals
			})) : this.appI18n.translateImmediate('clinicaldata.subject.create.validation.withoutDecimalMessage');
		};

		// ACTION BUTTONS
		this.signSubmitBtn = {
			action: () => {
				this.initiateSignature();
			},
			cssClass: '-es-primary',
			displayName: 'app.buttons.sign-submit',
			isDisabled: false,
			type: 'submit'
		};

		this.cancelBtn = {
			action: () => {
				this.$API.close();
			},
			displayName: 'app.buttons.cancel',
			isDisabled: false
		};

		this.actions = [this.signSubmitBtn, this.cancelBtn];
		this.isEditBlocked = false;
	}

	$onInit() {
		this.$log = this.$log.getInstance('DispenseISpiroDevicePopupController');
		this.$log.debug('loaded');

		this.configure();

		this.$API = this.setApi();
		this.componentReady && this.componentReady({ $API: this.$API });

		if (this.$stateParams.signKey) {
			const state = this.signatureService.getState(this.$stateParams.signKey);

			if (state) {
				if (state.sigType === this.getSignatureType()) {
					if (this.signatureService.checkSignatureWorkflow(this.$stateParams.signKey,
						this.$stateParams.tokenId,
						this.doSubmit, this.onSignatureCancel, this)) {
						return;
					}
				}
			}
		}
	}

	configure() {
		this.popupHeading = 'clinicaldata.subject.i-spiro.close-dispense.dispenseTitle';
	}

	makeFormsDirty() {
		angular.forEach(this.iSpiroForm.$error.required, (field) => {
			field.$setDirty();
		});
		this.iSpiroForm.showValidation = true;
		
		this.isHeightRequiredValid = this.validateHeight();
		this.isAgeRequiredValid = this.validateAge();
	}

	initiateSignature() {
		if (this.errors.genericError) {
			return;
		}

		if (this.iSpiroForm.$invalid) {
			this.makeFormsDirty();
			return;
		}
		/*if editing is blocked so marking height and age as valid even though they are empty*/
		if (!this.isEditBlocked && !this.isHeightRequiredValid && !this.isHeightValidOnSubmit()) {
			this.isHeightValid = false;
			return;
		}

		if (!this.isEditBlocked && !this.isAgeRequiredValid && !this.isAgeValidOnSubmit()) {
			this.ageIsInvalid = true;
			return;
		}

		const sigType = this.getSignatureType();
		this.updateHeightModelValue();
		// save state for signature process
		this.state = {
			_openPopupParams: {
				preselectedSite: this.preselectedSiteId,
				preselectedSubject: this.preselectedSubjectId
			},
			_sites: this.sites,
			selectedSite: this.selectedSite,

			_subjects: this.subjects,
			selectedSubject: this.selectedSubject,

			_languages: this.languages,
			selectedLanguage: this.selectedLanguage,
			languageFieldDisabled: this.languagesOptions && this.languagesOptions.disabled,

			initials: this.initialsValue,
			dob: this.dobValue,
			height: this.heightValue,
			age: this.ageValue,
			dobForAge: this.dobForAgeValue,
			subjectId: this.subjectIdValue,

			subjectId2: this.subjectId2Value,
			subjectNumber: this.subjectNumber,
			_ethnicities: this.ethnicities,
			selectedEthn: this.selectedEthn,

			_genders: this.genders,
			selectedGender: this.selectedGender,

			_parameterFields: this.parameterFields,
			parametersModel: this.parametersModel,
			isRemoteAction: this.isRemoteAction,
			isEditBlocked: this.isEditBlocked,
			sigType
		};

		this.additionalInfo = {
			action: (this.isCreatingSubject())
				? this.appI18n.translateImmediate('signature.action.createSubject')
				: this.appI18n.translateImmediate('signature.action.editSubject'),
			infoKey1: this.appI18n.translateImmediate('signature.info.site'),
			infoValue1: this.selectedSite.sponsorSiteId,
			infoKey2: this.appI18n.translateImmediate('signature.info.subject'),
			infoValue2: this.isCreatingSubject() ? this.subjectIdValue : this.selectedSubject.subjectKey
		};

		this.signatureService.sign(sigType, this.additionalInfo, this.state);
	}

	getSignatureType() {
		return this.appConfig.sigType.createEditISpiroSubject;
	}

	doSubmit(state) {
		const action = this.chooseSubmitAction(state);

		if (!action) {
			return;
		}

		this.loadingIndicatorService.show();
		action.then((resp) => {
			if (resp.data.isValidationError) {
				const msg = (resp.data && resp.data.responseMessageTranslationKey)
					  ? resp.data.responseMessageTranslationKey
					  : 'clinicaldata.subject.create.errors.genericSaveError';

				this.notificationService.showError(this.appI18n.translateImmediate(msg));
				this.open = true;
				this.restorePopupState(state);
			} else {
				const isCreateSubject = (state.selectedSubject.subjectId === null);

				const qrCode = isCreateSubject
					  ? resp.data[0].qrCode
					  : resp.data.qrCode;

				this.ertBasePopupService.popup('iSpiroPopup')
					.open(state.selectedSite.countryId, state.selectedSite.siteId,
						  isCreateSubject
						  ? resp.data[0].subjectId
						  : state.selectedSubject.subjectId,
						  action.actionId,
						  state.selectedSite.displaySiteName,
						  isCreateSubject
						  ? resp.data[0].subjectKey
						  : state.selectedSubject.subjectKey,
						  qrCode);

				if (isCreateSubject) {
					this.showSaveDelayWarning();
				}
			}
		}, (/*error*/) => {
			this.open = true;
			this.restorePopupState(state);
		}).finally(() => {
			this.loadingIndicatorService.hide();
		});
	}

	showSaveDelayWarning() {
		this.appI18n.translate(['clinicaldata.subject.create.saveDelay']).then((translations) => {
			const msg = translations['clinicaldata.subject.create.saveDelay'];

			this.notificationService.showSuccess(msg);
		});

		return false;
	}

	chooseSubmitAction(state) {
		let action;
		const isCreateSubject = (state.selectedSubject.subjectId === null);
		const isDispenseOnly = !this.parametersAreEditable(state._parameterFields);

		if (isCreateSubject) {
			const payload = this.generateCreateRequestPayload(state);

			action = this.iSpiroService.createSubject(payload);
			action.actionId = this.appConfig.iSpiroActionId.dispense;
		} else {
			// the parameters and language are not editable for dispense only
			if (isDispenseOnly && state.languageFieldDisabled) {
				// call popup with dispense qr code
				this.ertBasePopupService.popup('iSpiroPopup').open(
					state.selectedSite.countryId, state.selectedSite.siteId,
					state.selectedSubject.subjectId, this.appConfig.iSpiroActionId.dispense,
					state.selectedSite.displaySiteName, state.selectedSubject.subjectKey);
				return null;
			}
			
			// is dispensing device + change threshold values
			const payload = this.generateUpdateRequestPayload(state);

			action = this.iSpiroService.updateDeviceValues(payload, state.selectedSubject.subjectId, true);
			action.actionId = this.appConfig.iSpiroActionId.dispense;
		}
		return action;
	}

	onSignatureCancel(state) {
		this.$timeout(() => {
			this.open = true;
			this.restorePopupState(state);
		});
	}

	restorePopupState(state) {

		if (state._openPopupParams.preselectedSite) {
			this.sitesOptions.disabled = true;
		}
		if (state._openPopupParams.preselectedSubject) {
			this.subjectsOptions.disabled = true;
		}
		this.sites = state._sites;
		this.selectedSite = state.selectedSite;

		this.subjects = state._subjects;
		this.selectedSubject = state.selectedSubject;

		let getSubjectInfo;

		this.isLoading = true;
		if (this.isCreatingSubject()) {
			getSubjectInfo = this.getCreateSubjectInfo(this.selectedSite.countryId, this.selectedSite.siteId);
		} else {
			getSubjectInfo = this.getEditSubjectInfo(this.selectedSite.countryId, this.selectedSite.siteId,
				this.selectedSubject.subjectId);
		}

		return getSubjectInfo.then((data) => {

			this.subjectFields = data.fields;
			this.ethnicities = data.ethnicities;
			this.isEditBlocked = state.isEditBlocked;
			this.generateSubjectFields(this.subjectFields, data.languages, this.selectedSite.sponsorSiteId);

			this.selectedLanguage = state.selectedLanguage;
			// restore enabled/disabled language field setting
			this.languagesOptions.disabled = state.languageFieldDisabled;

			this.initialsValue = state.initials;
			this.dobValue = state.dob;
			this.subjectIdValue = state.subjectId;
			this.subjectId2Value = state.subjectId2;
			this.selectedEthn = state.selectedEthn;
			this.selectedGender = state.selectedGender;
			this.ageValue = state.age;
			this.heightValue = state.height;
			this.dobForAgeValue = state.dobForAge;
			this.preLoadHeight(this.subjectFields, this.heightValue);

			this.parametersModel['FEV1'] = state.parametersModel['FEV1'];
			this.parametersModel['FVC'] = state.parametersModel['FVC'];
			this.parametersModel['PEF'] = state.parametersModel['PEF'];

			this.isLoading = false;
		});
	}

	generateCreateRequestPayload(state) {
		return {
			siteId: +state.selectedSite.siteId, //number
			subjectId1: state.subjectId,
			subjectId2: state.subjectId2,
			gender: state.selectedGender ? state.selectedGender.name : null,
			genderId: state.selectedGender ? state.selectedGender.id : null,
			ethnicityName: state.selectedEthn ? state.selectedEthn.name : null,
			ethnicityId: state.selectedEthn ? state.selectedEthn.id : null,
			dob: state.dob,
			dateOfBirth: state.dob,
			initials: state.initials,
			Pef: state.parametersModel.PEF,
			Fev1: state.parametersModel.FEV1,
			FVC: state.parametersModel.FVC,
			languageId: state.selectedLanguage.id,
			signKey: this.$stateParams.signKey,
			signToken: this.$stateParams.tokenId,
			age: state.age,
			height: state.height,
			isEditBlocked: this.isEditBlocked
		};
	}

	generateUpdateRequestPayload(state) {
		return {
			siteId: +state.selectedSite.siteId, //number
			subjectId1: state.subjectId,
			subjectId2: state.subjectId2,
			Pef: state.parametersModel.PEF,
			Fev1: state.parametersModel.FEV1,
			FVC: state.parametersModel.FVC,
			languageId: state.selectedLanguage.id,
			signKey: this.$stateParams.signKey,
			signToken: this.$stateParams.tokenId,
			age: state.age,
			height: state.height,
			dob: state.dob,
			gender: state.selectedGender ? state.selectedGender.name : null,
			genderId: state.selectedGender ? state.selectedGender.id : null,
			ethnicityName: state.selectedEthn ? state.selectedEthn.name : null,
			ethnicityId: state.selectedEthn ? state.selectedEthn.id : null,
			isEditBlocked: this.isEditBlocked
		};
	}

	setApi() {
		const $API = {
			open: (isRemoteAction, countryId, siteId, subjectId, displaySiteName, subjectKey) => {
				this.errors.genericError = false;
				this.loadingIndicatorService.show();
				this.isRemoteAction = isRemoteAction;
				this.countryId = countryId;
				if (siteId && subjectId) {
					this.hideContentMessage = true;
					this.preselectedSiteId = siteId;
					this.selectedSite = {
						countryId: +countryId,
						siteId: +siteId,
						displaySiteName
					};
					this.sites = [this.selectedSite];
					this.sitesOptions.disabled = true;

					this.preselectedSubjectId = subjectId;
					this.selectedSubject = {
						subjectKey,
						subjectId
					};
					this.subjects = [this.selectedSubject];
					this.subjectsOptions.disabled = true;

					this.proceedWithEditSubject(this.selectedSubject).then(() => {
						this.open = true;
					}).finally(() => {
						this.loadingIndicatorService.hide();
					});

				} else {
					this.hideContentMessage = false;
					this.onPopupShow().then(() => {
						siteId && this.preselectSite(siteId.toString());
						this.open = true;
					}).finally(() => {
						this.loadingIndicatorService.hide();
					});
				}

			},
			close: () => {
				this.state = null;
				this.sites = null;
				this.preselectedSiteId = null;
				this.sitesOptions.disabled = false;
				this.subjectsOptions.disabled = false;
				this.subjects = [];
				this.selectedSite = null;
				this.selectedSubject = null;
				this.resetDemographics();
				this.open = false;
			}
		};

		return $API;
	}

	onPopupShow() {
		return this.$q.all([
			this.iSpiroService.getSitesMinimal(null, this.countryId).then((res) => {
				this.sites = res.data;
			}),
			this.appI18n.translate(['clinicaldata.subject.buttons.create']).then(
				(res) => {
					this.translations = res;
				}
			),
			this.userService.getUserHasPermission(
				this.appConfig.businessPermissions.iSpiroCreateSubject)
				.then((res) => {
					this.canCreateSubjects = res;
				})
		]);
	}

	getEditSubjectInfo(countryId, siteId, subjectId) {
		return this.iSpiroService.getDispenseSubjectData(countryId, siteId, subjectId).then((res) => {
			return res.data;
		});
	}

	getCreateSubjectInfo(country, site) {
		return this.iSpiroService.getCreateSubjectData(country, site).then((res) => {
			return res.data;
		}, (error) => {
			this.errors.genericError = true;
			this.isLoading = false;
			return error;
		});
	}

	cancelAction() {
		this.$API.close();
	}

	onSiteChange(newVal) {

		this.resetDemographics();
		this.selectedSite = newVal;

		if (!newVal) {
			this.subjects = [];
			return;
		}

		this.isLoading = true;
		this.getSubjects(newVal).then((res) => {
			this.subjects = res.subjects;
			this.isEditBlocked = res.config.blockSubjectCreation;
		}).finally(() => {
			this.isLoading = false;
		});
	}

	getSubjects(newVal) {
		return this.iSpiroService.getSubjects(
			newVal.countryId, newVal.siteId,
			this.appConfig.iSpiroActionId.dispense)
			.then((res) => {
				const subjects = res.data.subjects;
				const config = res.data.config;
				const blockSubjectCreation = config.blockSubjectCreation; //This is a flag to block subject creation
				if (this.canCreateSubjects && !blockSubjectCreation) {
					subjects.unshift({
						'subjectKey': this.translations['clinicaldata.subject.buttons.create'],
						subjectId: null
					});
				}

				if (subjects && subjects.length > 0) {
					this.subjectsOptions.placeholder = 'clinicaldata.subject.i-spiro.close-dispense.selectSubject';
				} else {
					this.subjectsOptions.placeholder = 'clinicaldata.subject.i-spiro.close-dispense.noSubject';
				}
				return res.data;
			});
	}

	preselectSite(siteId) {
		this.preselectedSiteId = siteId;
		const siteToSelect = this.sites.find((s) => {
			return s.siteId === siteId;
		});

		this.selectedSite = siteToSelect || null;
		if (this.selectedSite) {
			this.sitesOptions.disabled = true;
			this.onSiteChange(this.selectedSite);
		}
	}

	onSubjectChange(newVal) {
		this.resetDemographics();
		this.selectedSubject = newVal;
		if (!newVal) { return; }

		if (this.isCreatingSubject()) {
			this.proceedWithCreateSubject();
		} else {
			this.proceedWithEditSubject(newVal);
		}
	}

	proceedWithEditSubject(newVal) {
		this.isLoading = true;
		return this.getEditSubjectInfo(this.selectedSite.countryId, this.selectedSite.siteId, newVal.subjectId)
			.then((data) => {
				this.subjectFields = data.fields;
				this.ethnicities = data.ethnicities;
				this.isEditBlocked = data.blockSubjectCreation;
				this.generateSubjectFields(this.subjectFields, data.languages, this.selectedSite.sponsorSiteId);
				this.initEditSubjectFields(data.subjectData, this.subjectFields);
				this.isLoading = false;
			}, (/*error*/) => {
				this.isLoading = false;
			});
	}

	proceedWithCreateSubject() {
		this.isLoading = true;
		this.getCreateSubjectInfo(this.selectedSite.countryId, this.selectedSite.siteId).then((data) => {
			this.subjectFields = data.fields;
			this.ethnicities = data.ethnicities;
			this.generateSubjectFields(this.subjectFields, data.languages, this.selectedSite.sponsorSiteId);
			this.isLoading = false;
		});
	}

	initEditSubjectFields(subjectData, subjectFields) {
		this.selectedLanguage = this.languages.find((lang) => {
			return lang.id === subjectData.languageId;
		});

		this.initialsValue = subjectData.initials;

		this.dobValue = subjectData.dob;
		this.ageValue = subjectData.age;
		this.heightValue = subjectData.height;

		this.subjectIdValue = subjectData.subjectId1;
		this.subjectId2Value = subjectData.subjectId2;
		this.subjectNumber = subjectData.subjectNumber;

		if (this.ethnicities) {
			this.selectedEthn = this.ethnicities.find((eth) => {
				return eth.id === subjectData.ethnicityId;
			});
		}
		if (this.genders) {
			this.selectedGender = this.genders.find((gen) => {
				return +gen.id === +subjectData.genderId;
			});
		}

		this.parametersModel['FEV1'] = (subjectData['fev1']) ? +subjectData['fev1'] : null;
		this.parametersModel['FVC'] = (subjectData['fvc']) ? +subjectData['fvc'] : null;
		this.parametersModel['PEF'] = (subjectData['pef']) ? +subjectData['pef'] : null;
		
		this.preLoadHeight(subjectFields, this.heightValue);
	}

	generateSubjectFields(fieldsData, languages, sponsorSiteId) {
		this.initLanguagesSelect(languages);
		this.hasDOB = this.hasFieldsHaveDateOfBirth(fieldsData);

		fieldsData.forEach((field) => {
			if (field.category === 'ISpiroParameter') {
				this.initParameterField(field);
				return;
			}

			switch (+field.id) {
				case this.appConfig.demographics.initials:
					this.initInitialsField(field);
					break;
				case this.appConfig.demographics.dob:
					this.initDOBField(field);
					break;
				case this.appConfig.demographics.subjectId1:
					this.initSubjectIdField(field, sponsorSiteId);
					break;
				case this.appConfig.demographics.subjectId2:
					this.initSubjectID2Field(field);
					break;
				case this.appConfig.demographics.ethnicity:
					this.initEthnicityField(field);
					break;
				case this.appConfig.demographics.gender:
					this.initGender(field);
					break;
				case this.appConfig.demographics.height:
					this.initHeight(field);
					break;
				case this.appConfig.demographics.age:
					this.initAge(field);
					break;
					
			}
		});
	}

	initParameterField(field) {
		this.parameterFields.push(field);
	}

	canEditParameters() {
		return false;
	}

	hasFieldsHaveDateOfBirth(fields) {
		const res = fields.some((p) => {
			return +p.id === this.appConfig.demographics.dob;
		});

		return res;
	}

	initLanguagesSelect(languages) {
		this.languages = languages;
		this.selectedLanguage = null;
		this.languagesOptions = {
			label: 'clinicaldata.subject.create.language',
			disabled: false,
			id: 'languagesDropdown',
			required: true,
			placeholder: 'clinicaldata.subject.create.selectLanguage',
			uiValue: 'name'
		};
		this.onLanguageChange = (newVal) => {
			this.selectedLanguage = newVal;
		};
	}

	initInitialsField(field) {
		this.initialsValue = null;
		this.initialsOptions = {
			label: field.label,
			id: 'initialsInput',
			required: field.isRequired && !this.isEditBlocked,
			disabled: !this.isCreatingSubject() || this.isEditBlocked,
			type: 'text',
			placeholder: '',
			fieldFormatHint: field.formatHint,
			validators: {
				inputFormat: {
					fn: (inputValue) => {
						if (!inputValue) {
							return true;
						}
						return this.validateFormat(inputValue, field);
					}
				}
			}
		};
	}

	initDOBField(field) {
		this.dobValue = null;
		this.dobOptions = {
			label: field.label,
			id: 'dobInput',
			required: field.isRequired && !this.isEditBlocked,
			disabled: field.isReadOnly || this.isEditBlocked,
			type: 'text',
			placeholder: field.isPartialDob ? 'MMM-YYYY/YYYY' : 'DD-MMM-YYYY',
			fieldFormatHint: field.formatHint,
			validators: {
				inputFormat: {
					fn: (inputValue) => {
						if (!inputValue) { return true; }
						return this.validateFormat(inputValue, field);
					}
				},
				dobIsInFuture: {
					fn: (inputValue) => {
						if (!inputValue) { return true; }
						if (this.dateIsInFuture(inputValue)) {
							this.dobIsInFutureMsg = this.appI18n.translateImmediate('app.messages.dobInPast');
							return false;
						}
						return true;
					}
				}
			}
		};
	}

	initSubjectIdField(field, sponsorSiteId) {
		this.subjectIdValue = null;
		this.subjectIdOptions = {
			label: field.label,
			id: 'subjectIdInput',
			required: field.isRequired && !this.isEditBlocked,
			disabled: !this.isCreatingSubject() || this.isEditBlocked,
			type: 'text',
			placeholder: '',
			fieldFormatHint: field.formatHint,
			validators: {
				siteIdPrefix: {
					fn: (inputValue) => {
						if (!inputValue || !field.isSitePrefix || !sponsorSiteId ||
							!this.isCreatingSubject()) { return true; }
						return inputValue.startsWith(sponsorSiteId);
					}
				},
				inputFormat: {
					fn: (inputValue) => {
						if (!inputValue) { return true; }
						return this.validateFormat(inputValue.trim(), field);
					}
				}
			}
		};

		if (field.isSitePrefix && sponsorSiteId && this.isCreatingSubject()) {
			this.subjectIdValue = sponsorSiteId;
		}
	}


	initSubjectID2Field(field) {
		this.subjectId2Value = null;
		this.subjectId2Options = {
			label: field.label,
			id: 'dobInput',
			required: field.isRequired && !this.isEditBlocked,
			disabled: !this.isCreatingSubject() || this.isEditBlocked,
			type: 'text',
			placeholder: '',
			fieldFormatHint: field.formatHint,
			validators: {
				inputFormat: {
					fn: (inputValue) => {
						if (!inputValue) { return true; }
						return this.validateFormat(inputValue, field);
					}
				}
			}
		};
	}

	initEthnicityField(field) {
		this.selectedEthn = null;
		this.ethnOptions = {
			label: field.label,
			disabled: field.isReadOnly || this.isEditBlocked,
			id: 'ethnDropdown',
			required: field.isRequired,
			placeholder: 'clinicaldata.subject.create.selectEthnicity',
			uiValue: 'name'
		};

		this.onEthnChange = (newVal) => {
			this.selectedEthn = newVal;
		};
	}

	initGender(field) {
		this.selectedGender = null;
		this.genders = [{ name: 'Male', id: 1 }, { name: 'Female', id: 2 }];
		this.genderOptions = {
			label: field.label,
			disabled: field.isReadOnly || this.isEditBlocked,
			id: 'genderDropdown',
			required: field.isRequired && !this.isEditBlocked,
			placeholder: '',
			uiValue: 'name'
		};

		this.onGenderChange = (newVal) => {
			this.selectedGender = newVal;
		};
	}

	transformHeightValidationMessage(field) {
		const msg = field.formatHint;
		return `${msg.substring(0, 21)}  ${this.getNumberOfDecimalsValidationText(field)}
				${msg.substring(21, msg.length)}`;
	}

	initHeight(field) {
		this.heightValue = null;
		this.heightInInchValue = null;
		this.heightInCmValue = null;
		this.isHeightValid = true;
		this.heightOptions = {
			label: field.label,
			required: field.isRequired && !this.isEditBlocked,
			fieldFormatHint: this.transformHeightValidationMessage(field),
			inches: {
				label: 'in',
				id: 'inputHeight_in',
				disabled: field.isReadOnly || this.isEditBlocked
			},
			centimeters: {
				label: 'cm',
				id: 'inputHeight_cm',
				disabled: field.isReadOnly || this.isEditBlocked
			}
		};

		this.OnCmHeightChanges = (newVal) => {
			if (!newVal) {
				this.heightInInchValue = 0;
				return;
			}
			this.isHeightRequiredValid = false;
			this.heightInInchValue = this.convertCmToInches(newVal, field.numberOfDecimals);
			if (!this.validateDecimalsForHeight(field, newVal)) {
				this.isHeightValid = false;
				return;
			}
			this.validateHeightRange(field);
		};

		this.OnInchHeightChanges = (newVal) => {
			if (!newVal) {
				this.heightInCmValue = 0;
				return;
			}
			this.isHeightRequiredValid = false;
			this.heightInCmValue = this.convertInchesToCm(newVal, field.numberOfDecimals);
			this.validateHeightRange(field);
		};
	}

	updateHeightModelValue() {

		if (!this.heightInCmValue || !this.heightInInchValue) {
			return;
		}

		const heightField = this.subjectFields.find((field) => {
			return (+(field.id) === this.appConfig.demographics.height);
		});

		if (!heightField) {
			return;
		}
		
		this.heightInInchValue = this.roundOffHeight(this.heightInInchValue, heightField.numberOfDecimals);
		this.heightInCmValue = this.roundOffHeight(this.heightInCmValue, heightField.numberOfDecimals);

		if (heightField.measuringUnit === 'in') {
			this.heightValue = `${this.heightInInchValue}${heightField.measuringUnit}`;
		} else {
			this.heightValue = `${this.heightInCmValue}cm`;
		}
	}


	countDecimals(value) {
		if (!value || value.toString() === '') {
			return 0;
		}
		return value.toString().includes('.') ? value.toString().split('.')[1].length || 0 : 0;
	}

	validateDecimalsForHeight(field, value) {
		const decimals = this.countDecimals(value);
		return (decimals === field.numberOfDecimals);
	}

	isHeightValidOnSubmit() {
		const heightField = this.subjectFields.find((field) => {
			return (+(field.id) === this.appConfig.demographics.height);
		});
		/*if height field is not present then return true as height is valid*/
		if (!heightField) {
			return true;
		}
		
		return this.validateHeightRange(heightField);
	}

	isAgeValidOnSubmit() {
		const ageField = this.getFieldConfig(this.appConfig.demographics.age);
		/*if the age field is not present then return true*/
		if (!ageField) {
			return true;
		}
		return this.validateRange(ageField, +(this.ageValue));
	}

	validateHeightRange(field) {
		this.isHeightValid = this.validateRange(field, this.heightInCmValue) &&
							this.validateDecimalsForHeight(field, this.heightInCmValue);
		return this.isHeightValid;
	}

	validateHeight() {
		const error = this.iSpiroForm['\'height_cm\'']?.$error;
		return (error && error.required);
	}

	validateAge() {
		const error = this.iSpiroForm['\'age\'']?.$error;
		return (error && error.required);
	}

	convertInchesToCm(inches, decimal) {
		const cmPerInch = 2.54;
		return this.roundOffHeight((Number(inches) * cmPerInch), decimal);
	}
	convertCmToInches(cm, decimal) {
		const inchPerCm = (1 / 2.54);
		return this.roundOffHeight((Number(cm) * inchPerCm), decimal);
	}

	roundOffHeight(val, decimals) {
		return ((Number.parseFloat(val)).toFixed(decimals));
	}

	preLoadHeight(fieldData, heightValue) {

		const heightField = fieldData.find((field) => {
			return (+(field.id) === this.appConfig.demographics.height);
		});

		if (heightField && heightValue) {
			if (heightValue.includes('in')) {
				this.heightInInchValue = heightValue.substring(0, heightValue.length - 2);
				this.heightInCmValue = this.convertInchesToCm(this.heightInInchValue, heightField.numberOfDecimals);
			} else {
				//for cm or nothing
				this.heightInCmValue = (heightValue && heightValue.includes('cm'))
										? heightValue.substring(0, heightValue.length - 2) : heightValue;
				this.heightInInchValue = this.convertCmToInches(this.heightInCmValue, heightField.numberOfDecimals);
			}
		}
	}

	initAge(field) {
		/*if DOB is already present then, no need to add age options*/
		if (this.hasDOB) {
			return;
		}
		this.ageIsInvalid = false;
		this.isAgeRequiredValid = false;
		this.ageValue = null;
		this.ageOptions = {
			label: field.label,
			// eslint-disable-next-line max-len
			disabled: false, //marking this field as disabled as it is not editable the value will be calculated based on DOB
			id: 'inputAge',
			required: field.isRequired,
			placeholder: '',
			uiValue: 'name',
			type: 'text',
			fieldFormatHint: field.formatHint,
			validators: {
				rangeValidator: {
					fn: (inputValue) => {
						if (!inputValue) {return true;}
						return (this.validateRange(field, inputValue.trim()) &&
						 this.validateFormat(inputValue.trim(), field));
					}
				}
			}
		};
		this.onAgeChange = (newVal) => {
			this.ageValue = newVal;
		};
		this.initDOBForAgeField();
	}

	setReadOnlyDOBForAgeField() {
		if (this.isCreatingSubject()) {
			return false;
		}

		const ageField = this.getFieldConfig(this.appConfig.demographics.age);

		if (!ageField) {
			return false;
		}

		return ageField.isReadOnly;
	}

	initDOBForAgeField() {

		const field = {
			// eslint-disable-next-line max-len
			format: '^((((0?[1-9]|[12][0-9]|3[01])[-]([Jj][Aa][Nn]|[Mm][Aa][Rr]|[Mm][Aa][Yy]|[Jj][Uu][Ll]|[Aa][Uu][Gg]|[Oo][Cc][Tt]|[Dd][Ee][Cc]))|((0?[1-9]|[12][0-9]|30)[-]([Aa][Pp][Rr]|[Jj][Uu][Nn]|[Ss][Ee][Pp]|[Nn][Oo][Vv]))|((0?[1-9]|[12][0-9])[-]([Ff][Ee][Bb])))[-]((19|20)\\d\\d))?$',
			placeholder: 'DD-MMM-YYYY',
			isRequired: false,
			isReadOnly: this.setReadOnlyDOBForAgeField() || this.isEditBlocked,
			formatHint: this.appI18n.translateImmediate('clinicaldata.subject.create.validation.invalidDateFormat'),
			label: 'DOB'
		};
		
		this.dobForAgeOptions = {
			label: field.label,
			id: 'dobForAgeInput',
			required: field.isRequired,
			disabled: field.isReadOnly,
			type: 'text',
			placeholder: field.placeholder,
			fieldFormatHint: field.formatHint,
			validators: {
				inputFormat: {
					fn: (inputValue) => {
						if (!inputValue) { return true; }
						return this.validateFormat(inputValue, field);
					}
				},
				dobIsInFuture: {
					fn: (inputValue) => {
						if (!inputValue) { return true; }
						if (this.dateIsInFuture(inputValue)) {
							this.dobIsInFutureMsg = this.appI18n.translateImmediate('app.messages.dobInPast');
							return false;
						}
						return true;
					}
				}
			}
		};

		this.onDOBForAgeChange = (newVal) => {
			this.dobForAgeValue = newVal;
			if (!this.dobForAgeValue || !this.validateFormat(this.dobForAgeValue, field)) {
				this.ageValue = null;
				this.ageIsInvalid = false;
				return;
			}
			this.isAgeRequiredValid = false;
			const ageField = this.getFieldConfig(this.appConfig.demographics.age);
			this.ageValue = this.calculateAge(this.dobForAgeValue, ageField.numberOfDecimals);

			if (!this.validateRange(ageField, +(this.ageValue))) {
				this.ageIsInvalid = true;
			} else {
				this.ageIsInvalid = false;
			}
		};
	}

	getAgeDifferenceInYears(birthDate, currentDate) {
		const yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();
		const birthMonth = birthDate.getMonth();
		const currentMonth = currentDate.getMonth();
		const birthDay = birthDate.getDate();
		const currentDay = currentDate.getDate();
		
		if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
		  // If the current date is before the birthdate in the same month or day, subtract a year
		  return yearsDiff - 1;
		}
		return yearsDiff;
	  }
  
	dayOfYear(date) {
		  const start = new Date(date.getFullYear(), 0, 0);
		  const diff = date - start;
		  const oneDay = 1000 * 60 * 60 * 24;
		  return Math.floor(diff / oneDay);
	}
	
  
	calculateAge(dob, allowedDecimals) {
	  const birthDate = new Date(dob); //date of birth of user
	  const currentDate = new Date(); //current date
	  
	  let age = this.calculateDifferenceInYears(birthDate, currentDate); //calculate the age
	  age = this.truncateAge(age, allowedDecimals); //truncate the age to the allowed number of decimals

	  const decimalsInAge = this.countDecimals(age);
	  // If the age has no decimals and decimals are allowed, add a decimal point and zeros
	  if (decimalsInAge === 0 && allowedDecimals > 0) {
		age += '.';
		for (let i = 0; i < allowedDecimals; i++) {
			age += '0';
		}
		return age;
	  }
	  return age;
	}


	calculateDifferenceInYears(birthDate, toDate) {
		// Check for invalid dates
		if (isNaN(birthDate.getTime()) || isNaN(toDate.getTime())) {
			return -1;
		}
	
		// Clone the toDate and set the time to midnight
		const toCopy = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate());
	
		// Get the last birthday
		let fullYears = toCopy.getFullYear() - birthDate.getFullYear();
		const lastBirthday = new Date(birthDate);
		lastBirthday.setFullYear(lastBirthday.getFullYear() + fullYears);
	
		// If the last birthday is still in the future, move it one year back
		if (lastBirthday > toCopy) {
			lastBirthday.setFullYear(lastBirthday.getFullYear() - 1);
			fullYears--;
		}
		
		// Get the next birthday
		const nextBirthday = new Date(lastBirthday);
		nextBirthday.setFullYear(lastBirthday.getFullYear() + 1);
	
		// Calculate the number of days in the current year (between last and next birthday)
		const daysInCurrentYear = Math.round((nextBirthday - lastBirthday) / (1000 * 60 * 60 * 24));
	
		// Calculate the number of days passed since last birthday
		const daysOfCurrentYearPassed = Math.round((toCopy - lastBirthday) / (1000 * 60 * 60 * 24));
	
		// Calculate the exact age as a floating point number
		const exactAge = fullYears + (daysOfCurrentYearPassed / daysInCurrentYear);
	
		return exactAge;
	}

	truncateAge(age, decimalPlaces) {
		// Multiply by 10^decimalPlaces to shift decimal point
		const temp = age * Math.pow(10, decimalPlaces);
	
		// Floor the number to truncate decimal part
		const tempInteger = Math.floor(temp);
	
		// Divide by 10^decimalPlaces to bring the decimal point back
		return tempInteger / Math.pow(10, decimalPlaces);
	}

	validateFormat(inputValue, field) {
		const matches = inputValue.match(field.format);

		return !matches ? false : true;
	}
	
	validateRange(field, val) {
		const min = Number(field.minimumValue);

		const max = Number(field.maximumValue);
		
		const inputVal = Number(val);
		
		return (inputVal >= min && inputVal <= max);
	}

	dateIsInFuture(dateString) {
		if (!dateString) {
			return true;
		}
		const dob = this.parseDate(dateString);
		const now = new Date();
		const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

		return dob >= tomorrow;
	}

	parseDate(s) {
		const months = {
			jan: 0, feb: 1, mar: 2, apr: 3, may: 4, jun: 5,
			jul: 6, aug: 7, sep: 8, oct: 9, nov: 10, dec: 11
		};

		const p = s.split('-');
		
		if (p.length === 3) {
			return new Date(p[2], months[p[1].toLowerCase()], p[0]);
		} else if (p.length === 2) {
			return new Date(p[1], months[p[0].toLowerCase()], 1);
		} else if (p.length === 1) {
			return new Date(p[0], 0, 1);
		}
		return null;
	}

	isCreatingSubject() {
		return this.selectedSubject.subjectId === null;
	}

	parametersAreEditable(parameterFields) {
		const res = parameterFields.some((p) => {
			return p.isReadOnly === false;
		});

		return !!res;
	}

	getFieldConfig(id) {
		const field = this.subjectFields.find((f) => {
			return (+(f.id) === id); //the f.id is string and id is number so using + to convert to number
		});
		return field;
	}

	resetDemographics() {

		this.languages = [];
		this.selectedLanguage = null;

		this.initialsOptions = null;
		this.initialsValue = null;

		this.dobOptions = null;
		this.dobValue = null;

		this.subjectIdOptions = null;
		this.subjectIdValue = null;

		this.subjectId2Options = null;
		this.subjectId2Value = null;

		this.ethnOptions = null;
		this.selectedEthn = null;

		this.genderOptions = null;
		this.selectedGender = null;

		this.ageOptions = null;
		this.ageValue = null;

		this.heightOptions = null;
		this.heightValue = null;

		this.dobForAgeOptions = null;
		this.dobForAgeValue = null;

		this.parametersModel = {
			FEV1: null,
			FVC: null,
			PEF: null
		};
		this.parameterFields = [];
	}
}
/* eslint-enable no-underscore-dangle */
export default DispenseISpiroDevicePopupController;
