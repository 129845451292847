/*
 * dashboardModule
 * */

import dashboardComponent from './dashboard.component';
import announcementsWidgetModule from './announcementsWidget/index';
import openDcrWidgetModule from './openDcrWidget/index';
import openQueriesWidgetModule from './openQueriesWidget/index';
import studyMetricsWidgetModule from './studyMetricsWidget/index';
import enrollmentGraphWidgetModule from './enrollmentGraphWidget/index';
import visitOverviewWidgetModule from './visitOverviewWidget/index';
import uploadWidgetModule from './uploadWidget/index';

const dashboardModule = angular.module('app.components.dashboard', [
	announcementsWidgetModule,
	openDcrWidgetModule,
	openQueriesWidgetModule,
	studyMetricsWidgetModule,
	enrollmentGraphWidgetModule,
	visitOverviewWidgetModule,
	uploadWidgetModule
]).component('dashboard', dashboardComponent)
	.name;

export default dashboardModule;
