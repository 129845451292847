(function () {
	"use strict";

	angular
		.module("portalApp")
		.controller("CardiacUploadCtrl", CardiacUploadController);

	/**
	 * @ngInject
	 */

	function CardiacUploadController($log, appConfig, appSession, $sce) {
		$log = $log.getInstance("CardiacUploadCtrl", "color:blue");
		var vm = this;
		vm.$sce = $sce;
		vm.$onInit = onInit;
		vm.$log = $log;
		vm.createdCardiacUploaderUrl = createdCardiacUploaderUrl;
		vm.appSession = appSession; 
		vm.appConfig = appConfig;		
		vm.cardiacUploaderUrl = null;
		vm.errorMessage = "No study found, while loading Cardiac Upload";
		

		function onInit() {
			$log.debug("Cardiac Upload Controller Loaded");
            vm.createdCardiacUploaderUrl();
        }

		function createdCardiacUploaderUrl() {
			const currentStudy = vm.appSession.getStudy();
			const cardiacUploaderUrl = vm.appConfig.external.cardiacUploadUrl;
			
			if(!currentStudy) { 
				vm.$log.error(vm.errorMessage);
				return;
			}
			const urlToRedirect = `${cardiacUploaderUrl}home?studyAccount=${currentStudy.number}`;
			vm.cardiacUploaderUrl = vm.$sce.trustAsResourceUrl(urlToRedirect);
		}
	}	

})();
