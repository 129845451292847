/*
 * uploadWidgetComponent
 * */

import templateUrl from './uploadWidget.html';
import controller from './uploadWidget.controller';
import './uploadWidget.scss';

const uploadWidgetComponent = {
	bindings: {
		heading: '@',
		config: '<'
	},
	templateUrl,
	controller
};

export default uploadWidgetComponent;
