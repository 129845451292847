(function () {
	'use strict';

	angular.module('portalApp').config(i18nConfig);

	/**
	 *  @ngInject
	 */
	function i18nConfig($translateProvider, appConfig) {

		$translateProvider.useSanitizeValueStrategy('escaped');
		$translateProvider.useInterpolation('studyVocabularyInterpolation');

		// Adding a translation table for the English language
		$translateProvider.translations('en', {
			'help': {
				title: 'Help',
				'contactus': {
					'title': 'Contact Customer Care',
					'info': `For questions or issues regarding Clario's products or services please contact Clario Customer Care using the form below.
							If you wish to call us directly, please refer to the
							<a href="https://clario.com/contact/customer-support/" target="_blank">Contact Us</a>
							for the correct phone number for your location.
							`,
					'study-contact-info': 'Study & Contact Information',
					'protocol-title': 'PROTOCOL NAME',
					'site-title': 'SPONSOR SITE ID',
					'investigator-title': 'INVESTIGATOR NAME',
					'login-title': 'LOGIN ID',
					'first-name': 'First Name',
					'last-name': 'Last Name',
					'phone': 'Phone Number',
					'email': 'Email Address',
					'issue-description': 'Question or Description of issue',
					'issue-date': 'Date the issue occurred',
					'issue': `Please describe the issue including the product area, Subject ID, Visit number
							or any other information that may help us to answer your question or resolve your issue.`,
					'previously-contact': ' Have you previously contacted Clario about this issue?',
					'Yes': ' Yes',
					'No': ' No',
					'previously-contact-ticket': 'Please enter the Ticket Number(if known):',
					'response-information': 'Response Information',
					'response-information-address': `Please enter any other email addresses that
					 			should be copied on our response back to you.
						`,
					'submit-button': 'SUBMIT',
					'add-button': 'Add',
					'remove-button': 'Remove',
					'submit-dialog-title': 'Success',
					'submit-dialog-content': `
						    Thank you for contacting Clario. Your request has been sent successfully and a summary of your
						    input will be sent via email within the next few minutes.
						    <br/><br/>
							<b>If your request is urgent</b>, please call the Clario Helpdesk to get immediate help.
							When calling Clario, please provide the agent with the ticket number from the summary email
							and have the affected system or device within reach when making the call.
							<br/><br/>
							Thank you very much!

					`,
					error: 'An error has occurred',

				}
			},
			"login": {
				"info": "Please use your existing, verified user account and valid password to login to Clario Portal. ",
				info2: 'Note: This account is usually your individual email address. Your email address must belong only to you. Shared email addresses cannot be used for Portal accounts due to regulatory requirements.',
				"title": "Welcome to Clario Portal",
				'redirect-title': 'Return to Login Page',
				'redirect-message': `
						You can close current window or <a href="${appConfig.auth.gssoLoginUrl}">return to Login page</a>
					`,
				"button-login": "Log in",
				"link-reset-unlock": "Reset Password / Unlock Account",
				"site-reg": "New Site Registration",
				"problem": "Problems logging in?",
				"goto-help": "Go to login help",
				"change-password": "Change Password",
				"change-password-title": "Change Password",
				"messages": {
					'invalidateToken': 'Your user account does not have access to any active studies.errorCode: wrong token',
					'wrongStudy': 'Your user account does not have access to any active studies. If you think you should have access to active studies, please call Customer Care.',
					'noStudy': 'not study pass to my study portal',
					'noUser': 'Your user account does not have access to any active studies. errorCode:no user',
					'serverError': 'Server Error. If this problem persists, please call Customer Care.',
					'otherError': 'Oops! Something went wrong. If this problem persists, please call Customer Care.',
					"got-it": "Got It",
					"login-warning": "WARNING: You are about to enter a private website that is restricted to authorized use worldwide by Clario and authorized agents for business purposes only. The actual or attempted unauthorized access, use, or modification of the website or its content is strictly prohibited. Unauthorized users and/or use are subject to criminal and/or civil penalties in accordance with applicable domestic and foreign laws. The use of this website may be monitored and recorded for administrative and security reasons. If such monitoring and/or recording reveals possible evidence of criminal activity, Clario, Inc. may provide the monitored evidence of such activity to law enforcement officials. Access to this application from an untrusted workstation, including but not limited to cyber cafes and public kiosks, is prohibited.",
					"auth-failed": "You may have entered an unknown email address or an incorrect password.",
					"no-studies": "Your user account does not have access to any active studies. If you think you should have access to active studies, please call Customer Care.",
					unconfimed: "Your email address has not been confirmed. Please check your email for a confirmation message or click the Resend Confirmation Message under the login help link",
					generic: "Error during login. If this problem persists, please call Customer Care.",
					locked: "Your account is locked. Please try the Reset Password / Unlock Account link to unlock your account.",
					changePassword: {
						expired: "Your password has expired, please set a new password",
						passwordComplexity: "The password must have at least one digit, one uppercase and one lowercase character.",
						passwordLength: "Your password must be between eight to twenty characters long.",
						passwordMatch: "The Confirm New Password must match the New Password entry.",
						passwordSameAsUsername: "You cannot use your username as password",
						shortPassword: "Please select a password that is 8-20 characters long and includes at least one lower case character, one upper case character and one number",
						missingDigitPassword: "Please select a password that is 8-20 characters long and includes at least one lower case character, one upper case character and one number",
						missingLowerCasePassword: "Please select a password that is 8-20 characters long and includes at least one lower case character, one upper case character and one number",
						missingUpperCasePassword: "Please select a password that is 8-20 characters long and includes at least one lower case character, one upper case character and one number",
						passwordSameAsOldPassword: "You have already used this password. Please select a password different than your last 10 passwords",
						oldPasswordNotMatched: "Username and/or old password given was invalid",
						generic: "Change password failed"
					}
				},
				resendConfirmation: {
					title: 'Confirmation Email Resent',
					instructions: 'If you have not received your confirmation email and want us to send another confirmation email, please enter you email address in the text box below.',
					email: 'Your Email Address',
					error: 'An error has occurred',
					sentSubHeader: 'We have sent another email confirmation message to ',
					sentNote1: 'In order to join this study you need to verify that this is your email address.',
					sentNote2: 'Please check your email inbox for our email confirmation message and follow the instructions described there.',
					sentNote3: 'If you do not receive that email within the next 4 hours please contact ',
					customerCare: 'Clario Customer Care'
				},
				help: {
					title: 'Problems Logging In',
					faq: {
						q1: "What are my login credentials?",
						a1: "Your User Name is the email address on the ‘Welcome to Clario Portal’ email sent to you from Clario. If this is your first log in, your initial password is a temporary password set by Clario and sent to your registered email address with the subject ‘Welcome to Clario Portal – Confirmation and Password’.",
						q2: "I forgot my password",
						a2: "Click here to reset your password.",
						q3: "My account is locked and I cannot login",
						a3: "Click here to reset your password.",
						q4: "I get a “User Not Confirmed” message when I try to log in after successfully confirming my Email address and creating a new password",
						a4_1: "Try logging in after a few moments allowing the systems to update your credentials.",
						a4_2: "If you still have trouble logging in, ",
						a4_2cc: "click here to contact Clario Customer Care.",
						q5: "My site is registered to an ongoing study but I do not have an account to login to the portal",
						a5_1: "Ask another person (Study Coordinator) at your site who has access to the portal, to create an account for you in the Study Permissions page.",
						a5_2: "If you still cannot have your account created, ",
						a5_2cc: "click here to contact Clario Customer Care to have your account set up.",
						q6: "I’m a Sponsor/CRO employee and I have a Study PIN but I do not have an account to login to the portal",
						a6_1: "Click here to contact Clario Customer Care and have your account set up.",
						a6_2: "Please have your Study PIN available.",
						q7: "I want to register my site",
						a7: "Click here to register your site.",
						q8: "I cannot find the Confirmation email(s) sent from Clario.",
						a8_1: "Check your spam and junk folders for an email from Clario to find the ‘Welcome to Clario Portal – Confirmation’ email for your study; click the confirmation link in this email and enter your current password. ",
						a8_2_1: "If you cannot find the Confirmation email, ",
						a8_2_2: ". Type in your email address and click the Submit button to have a new Confirmation email sent to your email address.",
						a8_2ce: "click here for Clario to resend your Confirmation email",
						q9: "I deleted the Confirmation email sent to me",
						a9rc: "Click here for Clario to resend your Confirmation email.",
						a9: " Type in your email address and click the Submit button to have a new Confirmation email sent to your email address.",
						q10: "I do not know how to confirm my email address for this study",
						a10_1: "Locate the ‘Welcome to Clario Portal – Confirmation’ email for your study; click the confirmation link in this email and enter your current password.",
						a10_2_1: "If you cannot find the Confirmation email, ",
						a10_2_2: " Type in your email address and click the Submit button to have a new Confirmation email sent to your email address.",
						a10_2rc: "click here for Clario to resend your Confirmation email.",
						q11: "I want to change my initial temporary password",
						a11_1: "When you click on the Confirmation email sent to you from Clario, you will be prompted to change your temporary password",
						a11_2_1: "If you cannot find the Confirmation email, ",
						a11_2_2: " Type in your email address and click the Submit button to have a new Confirmation email sent to your email address.",
						a11_2rc: "click here for Clario to resend your Confirmation email.",
						q12: "I cannot confirm my email by clicking on the Confirmation URL in the Confirmation email sent to my email address",
						a12_1: "To confirm your email manually, please have your email address, password and the token, which is the code at the end of the Confirmation URL sent to your email address ready. (For example only: The token from this example URL \"https://expertportal.ert.com/Portal.Login/SQF/ConfirmEmail.aspx?t=c68784df-0adf-4808-9886-cfd6890d504b\" is \"c68784df-0adf-4808-9886-cfd6890d504b\".) ",
						a12_2: "Click here to confirm your email manually."
					}
				},
				resetPassword: {
					title: "Reset Password / Unlock Account",
					description: "Enter your email address to have a new password sent to your email address.",
					successMessage: "Your new temporary password has been sent to you. You will be asked to change your password on your first login",
					requiredFields: "Required Fields",
					email: "Your Email Address",
					buttons: {
						"continue": "Continue",
						cancel: "Cancel",
						finish: "Finish"
					}
				},
				changePassword: {
					requiredFields: "Required Fields",
					email: "Your Email Address",
					password: "Password",
					newPassword: "New Password",
					newPasswordConfirmation: "Confirm New Password",
					successMessage: "Your password has been changed successfully!",
					emailConfirmed: "Your email address has been confirmed.",
					alreadyConfirmed: "Your email address has already been confirmed.",
					buttons: {
						"continue": "Continue",
						cancel: "Cancel",
						finish: "Finish"
					}
				},
				confirmEmail: {
					requiredFields: "Required Fields",
					email: "Your Email Address",
					password: "Password",
					token: "Token",
					changePassword: "Please set your new password",
					loading: "Loading data...",
					canLoginNow: "Please proceed to the login.",
					canCloseTab: 'You can close this browser tab.',
					pleseEnterEmailAndPassword: "To complete the confirmation process please enter your email address and token.",
					errorHeader: "An error occurred during the email confirmation.",
					emailConfirmed: "Your email address has been confirmed.",
					alreadyConfirmed: "Your email address has already been confirmed.",
					buttons: {
						"continue": "Continue",
						cancel: "Cancel",
						close: "Close",
						finish: "Finish"
					}
				},
				register: {
					messages: {
						invalidEmail: "Please enter a valid email address",
						invalidFirstName: "Please enter a valid first name",
						invalidLastName: "Please enter a valid last name",
					},
					buttons: {
						"continue": "Continue",
						cancel: "Cancel",
						close: "Close",
						finish: "Finish"
					},
					dialog: {
						studyConfirmTitle: 'Confirm Your Study',
						studyConfirmPartMessage1: "You are registering your {{Site}} for study: ",
						studyConfirmPartMessage2: ". Is this the correct study?",
						studyIncorrectMessageTitle: 'Confirm Your Study',
						studyIncorrectMessage: "Please check your study PIN number and confirm with your study team that it is correct. If you are registering for a different study, a different PIN number must be entered.",
					}
				},
				"select-study": {
					"title": "Please select a study",
					"button-select": "Select"
				},
				multipleUsers: {
					title: 'Multiple linked Clario Portal Users',
					message1: 'The following Clario Portal users are linked to your global account and have access to study {studyName}',
					message2: 'Clario Portal will automatically log you in as {selectedUser}. If you are missing any data or functionality associated with another Clario Portal account, please call ',
					message3: ' to have these accounts merged.'
				}
			},

			"app": {
				"warning-notice": "Warning Notice",
				"privacy-policy": "Privacy Policy",
				"data-privacy-statement": "Data Privacy Statement",
				"imprint": "Imprint",
				"customer-care": "Customer Care",
				"buttons": {
					"back": 'Back',
					"delete": 'Delete',
					"cancel": 'Cancel',
					"close": 'Close',
					"dispense": 'Dispense',
					"continue": 'Continue',
					"edit": 'Edit',
					"next": 'Next',
					"no": 'No',
					"ok": 'OK',
					"save": 'Save',
					"show-more": 'Show More',
					'sign-submit': 'Sign & Submit',
					'sign-approve': 'Sign & Approve',
					'new-entry': 'Add New Entry',
					"submit": 'Submit',
					"view": 'View',
					"previous": 'Previous',
					"yes": 'Yes',
					sendRequest: 'Send Request',
					print: 'Print',
					reset: 'Reset',
					reject: 'Reject',
					chooseSingleFile: 'Choose file',
					chooseMultipleFiles: 'Choose files'
				},
				common: {
					'loading': 'Loading',
					'loadingProgress': 'Loading...',
					'saving': 'Saving',
					'select': 'Select',
					'deselect': 'Deselect',
					'all': 'All',
					'address': 'Address',
					'noOptions': 'No Options',
					'emailAddress': 'Email address',
					'email': 'Email',
					'phone': 'Phone',
					'mobile': 'Mobile',
					'enterEmail': 'Enter Email',
					'description': 'Description',
					'cancel': 'Cancel',
					'close': 'Close',
					'save': 'Save',
					'submit': 'Submit',
					'signAndSubmit': 'Submit',
					'user': 'User',
					'date': 'Date',
					'time': 'Time',
					'comment': 'Comment',
					'timezone': 'Time Zone',
					'firstName': 'First Name',
					'lastName': 'Last Name',
					'signature-cancel': 'Data not yet submitted.',
					'commentsPlaceHolder': 'Please provide your comments here.',
					'subject-information': '{{Subject}} Information',
					'information': 'Information',
					'visitName': 'Visit',
					'status': 'Status',
					'dateRange': 'Date Range',
					'yes': 'Yes',
					'no': 'No',
					'na': 'N/A',
					'site': '{{Site}}',
					'siteId': '{{Site}} ID',
					'siteNumber': '{{Site}} #',
					'subjectNumber': '{{Subject}} #',
					'subjectKey': '{{Subject}} Key',
					'visit': 'Visit',
					'event': 'Event',
					'country': 'Country',
					'warning': 'Warning',
					'pi': 'Principal Investigator'
				},
				messages: {
					textInput: 'Text Input',
					requiredFieldLegend: 'Required Field',
					requiredFieldsLegend: 'Required Field(s)',
					requiredField: 'This field is required',
					requiredFieldNA: 'This field is required. Specify parameter value or enter \'na\' or \'n/a\'.',
					maskField: 'Value does not match required format',
					patternField: 'Invalid Input',
					emailField: '$viewValue + " is not a valid email address"',
					phonePatternField: 'It is not a valid phone number',
					emailPatternField: 'It is not a valid email address',
					restrictedInputPatternField: "Only numbers, alphabets and other characters like \",' _.$&/À-ÿ-\" are allowed here",
					maxField: 'The max value allowed is',
					minField: 'The min value allowed is',
					maxLength: 'The maximum number of characters allowed is',
					unsavedChanges: 'Unsaved changes if any will be lost, do you want to continue?',
					usaZipCodeField: 'For US postal codes, use the following format: 55555-5555 or 55555 or 555555555',
					hoursMinutesOnly: 'Enter a valid time in 24 HR format (HH:mm)',
					hoursMinutesSecondsOnly: 'Enter a valid time in 24 HR format (HH:mm:ss)',
					dateFormatForPicker: 'Enter a valid date in DD-MMM-YYYY format',
					dateRange: 'Event Date must be in the past',
					allFieldsOrNone: 'The fields marked in red are required',
					fieldComparison: 'The field has discrepancies',
					allowDecimalsAndNA: 'Values allowed: numeric with up to 3 decimals, na or n/a',
					allowDigitsAndNA: 'Values allowed: numeric with up to {0} digits, na or n/a',
					dobInPast: 'Date of Birth must be in the past',
					DateInPast: 'Date must be in the past',
					endDateGreaterStartDate: `Visit End Date must be later than the Visit Start Date`,
					endTimeGreaterStartTime: `Time for Visit End Date must be later than Time for Start Date`,
					visitEndDateEmpty: `Enter a valid Visit End Date or remove Time for Visit End Date`,
					visitEndTimeEmpty: `Enter a valid Visit End Time or remove Vist End Date`,

				},
				dialog: {
					confirmTitle: 'Confirm',
					confirmDeleteTitle: 'Confirm Delete',
					sqfSubmitted: 'SQF Submitted',
					sqfContactConfirmTitle: 'Confirm Contacts'
				},
				errors: {
					genericLoadError: 'Error retrieving data',
				},
				toaster: {
					tryLaterError: 'Sorry! Your action cannot be completed at this time. Please check your internet connection and try after some time. If it still doesn\'t work, please contact <a href="https://clario.com/contact/customer-support/" target="_blank">Clario Customer Care</a>.',
					contactCustomerCareError: 'Sorry! Your action cannot be completed at this time. Please contact <a href="https://clario.com/contact/customer-support/" target="_blank">Clario Customer Care</a> to resolve this error.'
				},
				help: {
					title: 'Help',
					onlineHelp: 'Online Help',
					customerCareTicket: 'Customer Care Ticket'
				},
				menu: {
					'paper-entry-title': 'Clinical Data Entry'
				},
				addAttachment: {
					addByERTMessage: 'Added by Clario',
					addBySiteMessage: 'Added by {{Site}}',
				},
				fileUpload: {
					uploadFile: 'Upload file',
					chooseSingleFile: 'Choose file',
					chooseFiles: 'Choose files',
					invalidFileTypeMessage: 'You have selected an invalid file type.',
					invalidFileSizeMessage: 'File exceeds the maximum allowed limit of ',
					uploadAll: 'Upload all',
					clearAll: 'Clear all',
					cancelAll: 'Cancel all',
					uploadErrorMsg: 'Error while uploading the file.',
					textAreaLabel: 'Description',
					checkingForVirusesMsg: 'Checking for viruses ...',
					dragAndDropInfo: 'Drop your files here'
				}
			},

			'header': {
				'study-switch': 'Select another Study',
				'study-config': 'Study Configuration',
				'logout': 'Logout',
				'changePassword': 'Change Password',
				'user-name': '{0} {1}',
				expertUnavailable: 'Limited functionality is available due to ongoing maintenance. Complete system functionality will be available shortly.',
				ebsUnavailable: 'Logistics functionality is limited due to ongoing maintenance. Complete system functionality will be available shortly.',
				expertUnavailableShort: 'Limited functionality due to ongoing maintenance',
				ebsUnavailableShort: 'Logistics functionality is limited',
				mode: {
					study: 'Study Mode',
					training: 'Training Mode',
					proficiency: 'Proficiency Mode',
					sample: 'Sample Mode',
					uat: 'Client UAT Mode'
				},
				'download-error': {
					'title': 'Download Error',
					'error-msg': 'An error occured during document download. Please contact Customer Care.',
					'report-names': 'The following reports couldn\'t be downloaded:'
				},
				'edit-contact': {
					'pageTitle': 'Edit Contact Information',
					'selectAddressTxt': 'Please select an address',
					'addressBook': 'Address Book',
					'studyRoles': 'Study(s) - Role(s):',
					'sites': '{{Site}}s',
					'newContact': 'New Contact',
					'incompleteUnconfirmedAddresses': 'Incomplete / Unconfirmed Addresses',
					'notification': 'Please review/amend your contact information. Fill out all required fields, then press submit.',
					'notificationCompleteIncomplete': 'Press the \'Use this address\' button on the contact address you would like to use to edit and/or review your address before confirming it on the next screen.',
					'updateCompleteTitle': 'Update successful',
					'updateCompleteTxt': 'Thank you for updating your contact data. Please note that it may take a few minutes until the changes take effect.',
					'genericError': 'Error submitting contact change request. Please contact customer Care.',
					'selectContact': 'For the assignment of your contact data, you need to select a contact on the right.',
					'mail': 'Email',
					'title': 'Title',
					'selectTitle': 'Select Title',
					'firstName': 'First Name',
					'lastName': 'Last Name',
					'siteType': '{{Site}} Type',
					'institutionName': 'Institution Name',
					'individual': 'Individual',
					'institution': 'Institution',
					'address': 'Address',
					'address1': 'Address 1',
					'address2': 'Address 2',
					'address3': 'Address 3',
					'country': 'Country',
					'selectCountry': 'Select Country',
					'city': 'City',
					'postalCode': 'Postal Code',
					'province': 'Province',
					'state': 'State',
					'phone': 'Phone',
					'mobile': 'Mobile',
					'fax': 'Fax',
					'communicationChannel': 'Preferred communication channel',
					'buttons': {
						'create': 'Submit',
						'back': 'Back',
						'useThisAddress': 'Use this address',
						'completeThisAddress': 'Use this address',
						'enterNewAddress': 'Enter new address',
						'cancel': 'Cancel'
					},
					confirmation: {
						heading: 'Warning',
						message: 'Are you sure you want to cancel? By clicking OK, your contact will not be edited and you will not be able to join the study.'
					},

					'errors': {
						'required': {
							'title': 'This field is required',
							'firstName': 'This field is required',
							'lastName': 'This field is required',
							'siteType': 'This field is required',
							'institutionName': 'This field is required',
							'address1': 'This field is required',
							'address2': 'This field is required',
							'address3': 'This field is required',
							'country': 'This field is required',
							'city': 'This field is required',
							'postalCode': 'This field is required',
							'province': 'This field is required',
							'state': 'This field is required',
							'phone': 'This field is required',
							'mobile': 'This field is required',
							'fax': 'This field is required',
							'communicationChannel': 'This field is required'
						},
						'validation': {
							'title': 'Please enter a valid title ',
							'firstName': "Only numbers, alphabets and other characters like \",' _.$&\\/À-ÿ-\" are allowed here",
							'lastName': "Only numbers, alphabets and other characters like \",' _.$&\\/À-ÿ-\" are allowed here",
							'siteType': 'Please enter a valid {{site}} type ',
							'institutionName': "Only numbers, alphabets and other characters like \",' _.$&\\/À-ÿ-\" are allowed here",
							'address1': "Only numbers, alphabets and other characters like \",' _.$&\\/À-ÿ-\" are allowed here",
							'address2': "Only numbers, alphabets and other characters like \",' _.$&\\/À-ÿ-\" are allowed here",
							'address3': "Only numbers, alphabets and other characters like \",' _.$&\\/À-ÿ-\" are allowed here",
							'country': 'Please enter a valid country ',
							'city': "Only numbers, alphabets and other characters like \",' _.$&\\/À-ÿ-\" are allowed here",
							'postalCode': "Only numbers, alphabets and other characters like \",' _.$&\\/À-ÿ-\" are allowed here",
							'postalCodeUS': "For US postal codes, use the following format: 55555-5555 or 55555 or 555555555",
							'province': "Only numbers, alphabets and other characters like \",' _.$&\\/À-ÿ-\" are allowed here",
							'state': 'Please enter a valid state ',
							'phone': "Only digits, '-', 'x', space and '+' prefix are allowed here",
							'phoneMinLength': 'Requires minimum 7 characters',
							'mobile': "Only digits, '-', 'x', space and '+' prefix are allowed here",
							'mobileMinLength': 'Requires minimum 7 characters',
							'fax': "Only digits, '-', 'x', space and '+' prefix are allowed here",
							'faxMinLength': 'Requires minimum 7 characters',
							'communicationChannel': 'Please enter a valid communication channel '

						}

					}
				}
			},
			"study-switch": {
				"title": "Please select a study",
				"button-select": "Select"
			},
			"dashboard": {
				"details": "Details",
				"open-queries-title": "Open Queries to {{Site}}",
				"pending-site-input": {
					"title": "Pending Site Input",
				},
				"loading-error": "Error loading data",
				"study-metrics": {
					"title": "Study Metrics",
					"sites": "{{Site}}s",
					"subjects": "{{Subject}}s",
					"site-info": "{{Site}} Information",
					"ecg-info": "ECG",
					"total-sites": "Total number of {{site}}s",
					"total-subjects": "Total number of {{subject}}s",
					"holter-info": "Holter Arrhythmia *",
					"holter-text": "* The number of {{site}}s and {{subject}}s include only those that have completed transmittals.",
					"analyzed-ecg": "Total # of ECGs analyzed",
					"rhythm-ecg": "% of ECGs Abnormal Rhythms",
					"ectopy-ecg": "% of ECGs Abnormal Ectopy",
					"conduction-ecg": "% of ECGs Abnormal Conduction",
					"morphology-ecg": "% of ECGs Abnormal Morphology",
					"infarction-ecg": "% of ECGs Abnormal Myocardial Infarction",
					"st-ecg": "% of ECGs Abnormal ST Segment",
					"twave-ecg": "% of ECGs Abnormal T Waves",
					"uwave-ecg": "% of ECGs Abnormal U Waves",
					"comparison-ecg": "Total {{Subject}}s Completed Baseline Comparison Visit",
					"date-ecg": "Date First Completed Baseline Comparison Visit",
					"total-holter": "Total # of Holter Arrhythmia Transmittals Analyzed: ",
					"no-ecg-data": "No ECG data available",
					"no-holter-data": "No Holter data available",
					"na": "N/A"
				},
				"announcements": {
					"title": "Announcements",
					"title-hdr": "Title",
					"created-by": "Created By {user} on {timestamp}",
					"modified-by": "Modified By {user} on {timestamp}"
				},
				"open-dcr": {
					"title": "Open DCRs",
					"action": "All Data Corrections",
					"site": "{{Site}}",
					"pi": "Principal Investigator",
					"dcrs": "Open DCRs",
					"queries": "Open Queries",
					"psi": "Pending Site Input",
					"site-warning": "{siteCtr} {{site}}(s) have more than {threshold} open DCR/Queries",
					"pi-site-warning": "Your {{site}} has more than {threshold} open DCR/Queries",
					"query-site-warning": "{siteCtr} {{site}}(s) have more than {threshold} open Queries/Pending Site Input",
					"query-pi-site-warning": "Your {{site}} has more than {threshold} open Queries/Pending Site Input"
				},
				"visit-overview": {
					"planned": "Planned Visits",
					"recent": "Recent Visits",
					"site-pi": "{{Site}}/PI",
					"visit-name": "Visit Name",
					"visit-date": "Visit Date"
				}

			},

			"grid": {
				"header": {
					"view-mode-desc": "Grid view mode",
					"view-mode-group": "Group View",
					"view-mode-grid": "Grid View",
					"view-mode-tile": "Tile View",
					"pager-desc": "Results pages",
					"filter-basic": "Basic",
					"filter-advanced": "Advanced",
					"actions": "Actions",
					"expand-all": "Expand All",
					"collapse-all": "Collapse All",
					"filter-date-desc": "Date range:",
					"filter-number-desc": "Number range:",
					"filter-select": "Select filter",
					"filter-text": "Filter text",
					"filter-from": "from",
					"filter-to": "to",
					"filter-input": "Filter Input",
					"select-value": "Select Value",
					"select-column": "Select Column",
					"select-all-text": "All {0} rows on this page are selected.",
					"click-select-all-text": "Select all {0} rows on this page.",
					"select-all-group-text": "All {0} rows in this group are selected.",
					"btn-clear": "Clear",
					"btn-apply": "Apply",
					"btn-add": "Add Filter",
					"btn-clear-all": "Clear All",
					"export": {
						'action-txt': 'Export',
						'csv-txt': 'Excel 2003 (CSV)',
						'xlsx-txt': 'Excel 2007+ (XLSX)'
					},
					'btn-hide-available-answers': 'Hide Available Answers',
					'btn-show-available-answers': 'Show Available Answers'
				},
				"error": {
					'report-not-available': 'This report is currently not available. Please contact Customer Care.'
				},
				"no-filtered-data-text": 'No data to display, please change your filter settings',
				"no-data-text": 'No data to display',
				"event-report-title": 'Reports',
				"event-attachments-title": 'Attachments'
			},
			"clinicaldata": {
				"breadcrumb-root": "Study",
				"breadcrumb-homebp": "Home BP",
				"breadcrumb-cough": "Cough Device",
				"breadcrumb-homebpAndCough": "Home BP & Cough Device",
				"breadcrumb-patch": "Patch Device",
				"breadcrumb-patch-homeBP": "Patch & Home BP Device",
				"breadcrumb-patch-cough": "Patch & Home BP Device",
				"breadcrumb-patch-homeBP-cough": "Patch & Home BP & Cough Device",
				"clinical-data-title": "Clinical Data",
				"countries-title": "Countries",
				"sites-title": "{{Site}}s",
				"subjects-title": "{{Subject}}s",
				"visits-title": "Visits",
				"ecoaphone-viewer-title": "Questionnaire Data",
				"diarypro-viewer-title": "DIARYpro Sessions",
				"am-viewer-title": "AM Sessions",
				"site-device-viewer-title": "Clinical Outcome Assessments ({{Site}})",
				"subject-device-viewer-title": "Clinical Outcome Assessments ({{Subject}})",
				ecoa: {
					visitClosed: 'Visit was closed manually at {0} (UTC) by {1}.',
					'no-alerts': 'No Alerts',
					'no-scoring-results': 'No Scoring Results',
					noQuestionnaire: 'No questionnaire available',
					measurementTime: 'Measurement Time',
					'session-summary': 'Session Summary:',
					'scoring-results': 'Scoring Results:',
					'vacation-mode': 'Vacation Mode',
					'alerts': 'Alerts:',
					'show-more': 'Show {0} More...',
					pef: 'PEF',
					fev1: 'FEV1',
					fvc: 'FVC',
					mef25: 'MEF25',
					mef50: 'MEF50',
					module: 'Module:',
					complete: 'Complete',
					incomplete: 'Incomplete',
					missed: 'Missed',
					moreAlerts: ' more alerts...',
					hasAudio: 'Has Audio',
					hasAlerts: 'Has Alerts',
					hasAcq: 'Has Acq'
				},
				"country": {
					"country-info": {
						"no-enrollment-data": "Enrollment data not available",
						"local-supporter": "Local Supporter Information",
						"phone": "Phone:",
						"fax": "Fax:",
						"email": "E-Mail:",
						"mobile": "Mobile:",
						"address": "Postal Address:",
						"no-local-supporter": "No Local Supporter Information available"

					}
				},
				"site": {
					"info": "Info",
					"calendar": "Calendar",
					"site-info": {
						'no-email-given': 'No email given',
						"no-enrollment-data": "Enrollment data not available",
						"no-device-data": "Devices data not available",
						"title": "{{Site}} Info",
						"header": "{{Site}} Information",
						"error": "Error in loading {{Site}} Information",
						"sponsor": "Sponsor {{Site}} Number",
						"name": "{{Site}} Name",
						"institution": "Institution Name",
						"country": "Country",
						"pi-header": "Principal Investigator Information",
						"pi-name": "Name",
						"pi-email": "E-Mail",
						"pi-fax": "Fax #",
						"pi-phone": "Phone #",
						"pi-mobile": "Mobile",
						"pi-postal": "Postal Address",
						"na": "Not available",
						"user-list-header": "User List (Users that have access to this {{site}})",
						"user-list-text": "",
						"user-name": "User Name",
						"first-name": "First Name",
						"last-name": "Last Name",
						"role": "Roles",
						"devices-header": "Devices",
						"enrollment-header": "Enrollment Graph"
					}
				},
				"subject": {
					"kardia": {
						dropdownActionsText: 'Home ECG Actions',
						dispensePopupText: "To dispense a device, select {{site}} and {{subject}} or create a new {{subject}} below.",
						"button-action": 'Home ECG Actions',
						"button-close": 'Home ECG Close Device',
						"please-select": 'Please Select',
						"device-action": 'Home ECG Actions',
						closeTriggeredMessage: 'The device has been remotely closed and you can now dispense a new device to the {{subject}}.',
						registrationCodeExplanation: 'Please use the registration code below to register with Clario Home ECG Service',
						registrationCode: 'Registration Code',
						"close-dispense": {
							closeTitle: 'Close Device',
							dispenseTitle: 'Dispense Device',
							closeText: 'By clicking "Submit", you will remotely close the Home ECG device assigned to this {{subject}}.',
							selectSite: 'Select {{Site}}',
							selectSubject: 'Select {{Subject}}',
							noSubject: 'No {{Subject}}s',
							noSite: 'No {{Site}}s'
						},
					},
					"i-spiro": {
						auditReport: {
							studyOrSiteStudy:  'All {{site}}s, single report',
							studyOrSiteStudyZipped:  'All {{site}}s, individual reports',
							studyOrSiteSite: 'Single {{site}} only',
							successMessage: 'We are now generating your audit report and it will be available in the <a href="{0}" class="ert-notification-link">My Downloads</a> section soon.',
						},
						dropdownActionsText: 'iSpiro Device Actions',
						editPopupHeading: 'Edit Device Values',
						dispensePopupText: "To dispense a device, select {{site}} and {{subject}} or create a new {{subject}} below.",
						editValuesPopupText: "If you do not see the desired patient in this list, you must first dispense a device to that patient.",
						"button-action": 'iSpiro Device Actions',
						"button-close": 'iSpiro Close Device',
						"please-select": 'Please Select',
						"device-action": 'iSpiro Device Actions',
						"auditReportPopupTitle": 'iSpiro Audit Report',
						lostTriggeredMessage: 'The lost device has been remotely closed and you can now dispense a new device to the {{subject}}.',
						"close-dispense": {
							closeTitle: 'Close Device',
							dispenseTitle: 'Dispense Device',
							editTitle: 'Edit Device Values',
							editTextAfter: 'The device values will be updated automatically if there is an online connection within the next 3 hours. Alternatively, scanning the QR code will start the process immediately.',
							lostTitle: 'Lost Device',
							lostText: 'By clicking "Submit", you will remotely close the lost iSpiro device assigned to this {{subject}}. Once the action is completed you will be able to dispense a new device to this {{subject}}. If found, please return the original device to Clario - it cannot be reused in the study.',
							resetPinTitle: 'Reset PIN',
							resetPinText: 'By clicking "Submit", you will remotely trigger a PIN reset for the iSpiro device assigned to this {{subject}}.',
							resetPinTextAfter: 'The PIN will be reset automatically if there is an online connection within the next 3 hours. Alternatively, scanning the QR code will start the process immediately.',
							remoteCloseTitle: 'Close Device',
							remoteCloseText: 'By clicking "Submit", you will remotely close the iSpiro device assigned to this {{subject}}.',
							remoteCloseTextAfter: 'The device will be closed automatically if there is an online connection within the next 3 hours. Alternatively, scanning the QR code will start the process immediately.',
							selectSite: 'Select {{Site}}',
							selectSubject: 'Select {{Subject}}',
							noSubject: 'No {{Subject}}s',
							noSite: 'No {{Site}}s'
						},
						scheduleEvent: {
							title: 'Schedule Event',
							rescheduleTitle: 'Reschedule Event',
							selectVisit: 'Select Visit',
							selectEvent: 'Select Event',
							date: 'Select Date',
							remoteVideo: 'Remote Video',
							afterScheduleQrCodeDescription: 'The device will be updated automatically if there is an online connection within the next 3 hours. Alternatively, scanning the QR code will start the process immediately.',
							errors: {
								genericLoadError: 'Error retrieving data',
								scheduleDateInPast: 'Please enter a date/time in the future',
								genericErrorSaveScheduledEvent: 'The event could not be scheduled. Please contact Customer Care if this problem persists.'
							},
							validation: {
								dateInPast: 'Please enter a date in the future',
								timeInPast: 'Please enter a date and time in the future'
							}
						}
					},
					"subject-info": {
						"title": "Device Information",
						"no-device": "No Device Information available"
					},
					noDeviceSubjectTitle: 'Missing demographics',
					noDeviceSubjectMessage: 'The eCOA Phone related demographics are missing for the selected {{subject}}. Please enter the missing information within the following window.',
					buttons: {
						info: "Info",
						calendar: "Calendar",
						sendPin: 'Set/Send PIN',
						editContactData: 'Contact Address',
						editSubject: 'Edit {{Subject}}',
						editDeviceValues: 'Edit Device Values',
						dispenseDevice: 'Dispense Device',
						closeDevice: 'Close Device',
						lostDevice: 'Lost Device',
						resetPin: 'Reset PIN',
						scheduleEvent: 'Schedule Event',
						closeNextVisit: 'Close Next Visit',
						noActionAvailable: 'No action available',
						create: "Create {{Subject}}",
						generateISpiroReport: "iSpiro Audit Report",

					},
					qrCode: {
						title: 'Device App Info',
						url: 'Study Url:',
						code: 'Setup Code:',
						language: 'Language:',
						instructionsText: 'For additional details please see the Setup Instructions for Subject and Setup Instructions for Study Coordinator documentation in the Reference Materials section.'
					},
					edit: {
						title: 'Edit {{Subject}}',
						buttons: {
							update: 'Update'
						},
						statusChangeErrorTitle: 'Error changing {{Subject}} status.',
						statusChangeError: 'There was an error in changing the {{Subject}} status. Please try again later or contact Customer Care.',
						unexpectedEditError: 'Unexpected validation error in read-only or invisible field: '
					},
					status: {
						confirmDeactivateTitle: 'Confirm Deactivation',
						confirmDeactivate: 'Are you sure you want to deactivate this {{subject}}?',
						confirmActivateTitle: 'Confirm Activation',
						confirmActivate: 'Are you sure you want to activate this {{subject}}?',
						errors: {
							genericSaveErrorActivate: 'Error activating {{subject}}',
							genericSaveErrorDeactivate: 'Error deeactivating {{subject}}'
						},
						saveDelayTitleStatusChange: 'Status changed',
						saveDelayStatusChange: 'The {{subject}} status has been updated successfully, but it can take a few minutes until the change will be visible in Clario Portal.',

						buttons: {
							activate: 'Activate',
							deactivate: 'Deactivate'
						}
					},
					create: {
						title: 'Create {{Subject}}',
						site: '{{Site}} ID',
						selectSite: 'Select {{Site}}',
						selectSchedule: 'Select Schedule',
						selectTimezone: 'Select Timezone',
						selectGender: 'Select Gender',
						selectEthnicity: 'Select Ethnicity',
						selectLanguage: 'Select Language',
						schedule: 'Visit Schedule',
						timezone: 'Time Zone',
						language: 'Language',
						dob: 'Date of Birth',
						subjectId: '{{Subject}} ID1',
						ethnicity: 'Ethnicity',
						emailPin: 'Email PIN to',
						saveEmail: 'Save Email for {{Subject}} Contact',
						isRollover: '{{Subject}} is Rollover?',
						enterDate: 'Please enter date/time of the last visit in the core study. Time will be set using the time zone selected above.',
						visitCompleteDate: 'Visit Complete Date',
						visitCompleteTime: 'Visit Complete Time',
						saveDelayTitle: '{{Subject}} saved',
						saveDelay: 'Your new {{subject}} has been created successfully, but it can take a few minutes until your newly created {{subject}} will be visible in Clario Portal.',
						saveDelayTitleUpdate: '{{Subject}} updated',
						saveDelayUpdate: 'Your {{subject}} has been updated successfully, but it can take a few minutes until your changes will be visible in Clario Portal.',
						deviceInformation: 'Please use the following data to setup your device.',
						inactiveDelayTitle: 'Study is inactive',
						inactiveDelay: 'This study status is not currently active therefore {{subject}} and visit creation or modification will not be displayed within the Portal. Do you still wish to continue?',
						newSubject: 'Create New {{Subject}}',
						editSubject: 'Edit {{Subject}}',
						buttons: {
							create: 'Create',
							cancel: 'Cancel',
							update: 'Update'
						},
						validation: {
							invalidPassword: 'Given password is incorrect',
							invalidUsername: 'Username does not match',
							invalidDob: 'Please provide a valid date of birth',
							missingRollover: 'Please provide values for visit complete date and time when {{subject}} is rollover',
							dobBeforeFirstVisit: "The date of birth should be before the date of the first visit",
							invalidRollover: "Please provide valid values for visit complete date and time when {{subject}} is rollover",
							rolloverAfterDob: "The rollover date should be after the date of birth",
							invalidCompleteDate: "Please provide valid values for visit complete date and time",
							completeDateAfterStudyStart: "The visit complete date should be after the start date of the study",
							completeDateAfterLastCompleteVisit: "The visit complete date should be after the date of the last completed or missed visit",
							completeDateInPast: "The visit complete date should be in the past",
							completeTimeInPast: "The visit complete time should be in the past",
							completeDateAfterDob: "The visit complete date should be after the {{subject}}'s date of birth",
							subjectNotFound: "{{Subject}} not found",
							timezoneNotFound: "Timezone not found",
							naForStudy: "Not available for given study",
							subjectIdStartWithSiteId: "{{Subject}} ID must start with {{site}} ID",
							subjectIdMustBeNumeric: "{{Subject}} ID must be numeric, beacause range is defined",
							subjectIdInRange: "{{Subject}} ID must be in range",
							subjectIdMatchFieldFormat: "{{Subject}} ID must match field format",
							subjectIdOtherDevice: "This {{Subject}} ID already exists in our system. If this is the correct {{Subject}} ID, please use 'Edit {{Subject}}' to enter all missing information to allow the collection of eCOA assessments for this {{subject}}.",
							subjectIdUnique: "{{Subject}} number already exists. The identification of a {{subject}} must be unique within the study. Please enter a unique {{subject}} number.",
							initialsInvalid: "Initials invalid",
							dobInvalid: "Date of birth invalid",
							subjectId2Invalid: "SubjectId2 invalid",
							ageInvalid: "Age invalid",
							heightInvalid: "Height invalid",
							weightInvalid: "Weight invalid",
							auth1Invalid: "Auth1 invalid",
							auth2Invalid: "Auth2 invalid",
							timezoneInvalid: "Timezone invalid",
							languageInvalid: "Language invalid",
							scheduleInvalid: "Schedule invalid",
							emailPin: 'Email invalid',
							dobInPast: "Please provide a date of birth in the past.",
							onlyNumeric: 'Only numeric values are allowed.',
							minMaxText: 'The value must be between {minValue} and {maxValue}.',
							numberOfDecimals: 'Maximum number of decimals allowed is {numberOfDecimals}.',
							invalidDateFormat: 'Enter a valid date in the format DD-MMM-YYYY',
							withDecimalMessage: 'with exactly {numberOfDecimals} decimal(s)',
							withoutDecimalMessage: 'without decimals,',

						},
						errors: {
							genericLoadError: 'Error retrieving data',
							genericSaveError: 'Error saving {{subject}}',
							sitePrefix: 'The value needs to start with the {{site}} ID',
							noSchedule: 'No visit schedule is available. The {{subject}} creation is temporarily disabled.',
							noSites: 'There are no {{site}}s available for {{subject}} creation',
							required: {
								general: " is required",
								dob: "Date of birth is required",
								siteId: '{{Site}} ID is required',
								schedule: 'Visit Schedule is required',
								timezone: 'Timezone is required',
								language: 'Language is required',
								emailPin: 'Email is required',
								rollover: 'Rollover is required',
								completeDate: 'Visit Complete Date is required',
								completeTime: 'Visit Complete Time is required'
							}
						}
					},
					closeVisit: {
						title: 'Close Visit',
						siteId: '{{Site}} ID',
						visitName: 'Visit',
						visit: 'Visit',
						note: 'Visits should only be closed manually if the data for this visit was collected using an alternative method. Missed visits should not be closed manually. ',
						prompt: 'Please enter the desired date and time to close out this visit',
						visitCompleteDate: 'Visit Complete Date',
						visitCompleteTime: 'Visit Complete Time',
						timezone: 'Time Zone',
						cannotCloseVisitTitle: 'Cannot close visit',
						cannotCloseVisit: 'This visit cannot be closed',
						saveDelayTitle: 'Close Visit Saved',
						saveDelay: 'The Visit has been closed manually but it can take some minutes until this action is visible in Clario Portal.',
						validation: {
							closeTimeInvalid: 'Close timestamp needs to be in the past and can not be less than the last visit timestamp'
						},
						errors: {
							genericLoadError: 'Error retrieving data',
							genericSaveError: 'Error saving {{subject}}',
							required: {
								completeDate: 'Visit Complete Date is required',
								completeTime: 'Visit Complete Time is required'
							}
						}
					},
					pin: {
						title: 'Reset and Send PIN',
						siteId: '{{Site}} ID',
						wantToChangePin: 'Do you want to change the {{subject}} PIN?',
						changePinNo: 'No, I do not want to change the PIN',
						changePinYesAuto: 'Yes, I want to automatically generate a new PIN',
						changePinYesManually: 'Yes, I want to enter my own number for the new PIN',
						pinEmail: 'Email',
						newPin: 'New PIN',
						confirmPin: 'Confirm PIN',
						wantToSendPin: 'Do you want to send the {{subject}} PIN to anyone?',
						sendPinNo: 'No, I do not want to send the PIN.',
						sendPinYesSubjectAddress: 'Yes, send the PIN to the {{subject}}\'s stored email address.',
						sendPinYesFollowingAddress: 'Yes, send the PIN to the following email address:',
						errors: {
							required: {
								pin: 'PIN is required',
								confirmPin: 'Confirm PIN is required',
								pinEmail: 'Email is required'
							},
							genericLoadError: 'Error retrieving data',
							genericSaveError: 'Error sending pin',
							pinMismatch: 'Please enter identical values into the two input fields for the PIN',
							pinFormat: 'The PIN has to be a numeric entry with a minimum length of 4 and a maximum length of 20 characters',
							generatedPinMustBeSend: 'Automatically generated PINs are unknown values and must be sent to someone. Please either send the automatic PIN to an email address or choose another option',
							nothingToDo: 'Nothing to do. Please select at least one of the options to either modify the PIN or send it per email',
							pinEmailFormat: 'Please enter a valid email address into the text field'

						}
					},
					contact: {
						title: '{{Subject}} Contact Details',
						siteId: '{{Site}} ID',
						email: 'Email Address',
						phoneFormat: 'Phone Format',
						phoneFormatUs: 'US/North America',
						phoneFormatInternational: 'International',
						phoneNumber: 'Phone Number',
						mobileNumber: 'Mobile Number',
						deleteEmail: 'Email address for this {{subject}} will be removed',
						deletePhone: 'Phone number for this {{subject}} will be removed',
						deleteMobile: 'Mobile number for this {{subject}} will be removed',
						infoText1: 'The contact information below may be set for the purpose of ePRO system communications sent directly to the {{subject}}, such as password notification and reminder messages. This information is confidential and may not be used for any purpose other than stated intend.',
						infoText2: 'For security reasons, the information entered here will not be displayed  once it is set. Entry of this information will overwrite any existing values that may already exist.',

						errors: {
							genericLoadError: 'Error retrieving data',
							genericSaveError: 'Error sending pin',
							nothingToDo: 'No changes to be saved',
							emailFormat: 'Please enter a valid email address',
							phoneFormatUS: 'Phone number is invalid. (3-digit area code, 7-digit phone number)',
							mobileFormatUS: 'Mobile number is invalid. (3-digit area code, 7-digit phone number)',
							phoneFormatInternational: 'Phone number is invalid. (Enter up to 15 digits)',
							mobileFormatInternational: 'Mobile number is invalid. (Enter up to 15 digits)'
						}
					},
					"homebp": {
						dropdownActionsText: 'Home BP Device Actions',
					},
					"coughDevice": {
						dropdownActionsText: 'Cough Device Actions',
					},
					"homebpAndCoughDevice": {
						dropdownActionsText: 'Home BP & Cough Device Action',
					},
					"patch": {
						dropdownActionsText: 'Patch Device Actions'
					},
					"patchAndHomeBP": {
						dropdownActionsText: 'Patch & Home BP Device Action'
					},
					"patchAndCough": {
						dropdownActionsText: 'Patch & Cough Device Action'
					},
					"patchAndHomeBPAndCough": {
						dropdownActionsText: 'Patch & Home BP & Cough Device Action'
					}
				},
				calendar: {
					title: 'Visit Calendar',
					dropdownCountry: 'Country',
					dropdownSite: '{{Site}}',
					siteVisitTitle: 'Visit Calendar',
					phoneTitle: 'Phone Calendar',
					siteVisits: '{{Site}} Visits',
					phoneVisits: 'Phone Visits',
					filterApply: 'Apply',
					filterClear: 'Clear',
					filterSelect: 'Please Select',
					filterCurrentMonth: 'Date - Current Month',
					filterTwoMonths: 'Date - 2 Months',
					filterThreeMonths: 'Date - 3 Months',
					filterFromTo: 'From... To...',
					filterLabel: 'Filter',
					dateColumnCaption: 'Date',
					visitColumnCaption: 'Visit',
					statusColumnCaption: 'Status',
					dateRangeColumnCaption: 'Date Range',
					noResultsTxt: 'No data to display',
					datePrinted: 'Date printed: ',
					legend: {
						completed: 'Completed Visits:',
						normal: 'Normal Visit',
						randomization: 'Randomization Visit',
						unscheduled: 'Unscheduled Visit',
						termination: 'Termination Visit',
						planned: 'Planned Visits (Future):',
						plannedOverdue: 'Planned Visits (Overdue):'
					}
				},
				visit: {
					infoPopup: {
						title: 'Event Details',
						operatorName: 'Operator Name',
						transmittalNumber: 'Transmittal Number'
					},
					download: {
						title: 'Download By Report Type',
						gridAction: 'Download',
						selected: 'Download All Selected Reports',
						byType: 'Download By Report Type',
						byTypePopupButton: 'Download',
						byTypePopupNoReports: 'No reports available.',
						byTypeDownloading: 'Preparing download...'
					},
					scoring: {
						title: 'Scoring Result',
						module: 'Module',
						routine: 'Scoring Routine',
						loadError: 'Error loading data',
						result: 'Score/Result'
					},
					alerts: {
						title: 'Alerts Triggered',
						module: 'Module',
						loadError: 'Error loading data',
						sessionId: 'Session ID',
						datetime: 'Date/Time',
						scoringName: 'Event/Scoring Name',
						type: 'Alert Type',
						alertName: 'Alert Name',
						report: 'Report',
						download: 'Download',
						documentLoadErrorTitle: 'Error',
						documentLoadError: 'The requested document could not be retrieved'
					}
				},

				scheduledEvents: {
					title: 'Scheduled Events',
					confirmCancel: 'Are you sure you want to cancel this event?',
					cancelError: 'The scheduled event could not be canceled. Please contact Customer Care if this problem persists.',
					cancelSuccess: 'The scheduled event was canceled successfully.',
					activateError: 'Starting this Session is not possible. Sessions can only be started 4hrs prior to or 8hrs after the scheduled time.',
					actions: {
						start: {
							displayName: 'Start',
							disabledInfo: 'This event does not have remote video enabled',
							enabledInfo: 'Start remote video session'
						},
						resume: {
							displayName: 'Resume',
							disabledInfo: 'This event does not have remote video enabled',
							enabledInfo: 'Resume remote video session'
						},
						reschedule: {
							displayName: 'Reschedule',
							disabledInfo: 'This event cannot be rescheduled',
							enabledInfo: 'Reschedule this event to a different date/time'
						},
						cancel: {
							displayName: 'Cancel',
							disabledInfo: 'This event cannot be canceled',
							enabledInfo: 'Cancel this event'
						}
					}

				}
			},
			sso: {
				tableau: {
					failure: 'Login to tableau failed. Please refresh the page to try again and contact Customer Care if this problem persists.',
					loading: 'Loading Tabeau...',
				},
				epx: {
					failure: 'Login to EPX failed. Please refresh the page to try again and contact Customer Care if this problem persists.',
					popupBlocked: 'Could not open popup. Please disable your popup blocker for this site or click the link below.',
					loading: 'Loading EPX...',
					loginLink: 'Login to EPX',
					noMatchingAccount: 'No matching user account',
					createAccount1: 'You do not have a matching account within the DIARYpro/SITEpro EPX Portal. Please click',
					createAccountLinkText: 'here',
					createAccount2: 'to create an EPX account with your existing token number.',
					contactCustomerCare: 'If you do not have a token number or need additional assistance, please contact Customer Care.'
				},
				legacy: {
					loadErrorTitle: 'Error loading study',
					loadError: 'Loading the selected study failed.',
					browserCompatibilityWarningTitle: 'Browser Compatibility',
					browserCompatibilityWarning: 'You are now entering a study site that may not support the browser type or version you are using. Some pages may not display correctly. Please try to login using Internet Explorer 9, 10 or 11 if you are facing any issues.',
					browserCompatibilityWarningGrid: 'This feature is not functional in the browser version being used. Please update your browser or use Chrome or Firefox to access this feature.',
					logoutWarningTitle: 'Portal Redirection',
					logoutWarningSwitch: 'You will now be routed to another portal in a new tab/window to view the study you selected. To select a different study from this list, please use the current tab/window or in the new tab/window, logout and login to this portal again.',
					logoutWarning: 'You will now be routed to another portal to view the study you selected. To select a different study from this list, please logout from the portal displaying your selected study and login to this portal again.',
					accountLocked: {
						title: 'Account locked',
						unlock: 'Unlock',
						message: 'Your account is locked. Please press the unlock button to have an unlock email sent to your email address.',
						resetSuccessTitle: 'Password reset success',
						resetSuccessMessage: ''

					}
				}
			},

			"admin": {
				"admin": "Admin",
				"data-warning": "This administrative view can contain a large amount of data. Please always use an appropriate filter. We recommend you limit the data to the last 7 days only.",
				"module-tracking-title": "Module Tracking",
				"study-tracking-title": "Study Tracking",
				"user-login-tracking-title": "User Login Tracking",
				"user-tracking-title": "User Tracking",
				"study-configuration-title": "Study Configuration",
				"error-loading": "There was an error in getting the information. Please try again later.",
				"user-action-tracking": {
					open: "Create User Action Tracking Report",
					title: "User Action Tracking Report",
					user: "User Email Address",
					study: "Study",
					"all-studies": "All Studies",
					"need-study-or-user": "Please select either an user or a study. If you require a report across all studies and users, please contact an administrator."
				},
				"module-tracking": {
					"module": "Module",
					"action": "Action",
					"status": "Status",
					"sitepi": "{{Site}}/PI",
					"user": "User",
					"datetime": "Date/Time (UTC)",
					"additional-info": "Additional Info"
				},
				"study-tracking": {
					"study": " Study Name",
					"site": "Principal Investigator / {{Site}} ID",
					"action": "Action",
					"action-by": "Action by",
					"old-study": "Old Study / Study Role",
					"new-study": "New Study / Assignee E-Mail",
					"datetime": "Date/Time (UTC)"
				},
				"user-login": {
					"user": "User Name",
					"email": "E-Mail",
					"status": "Status",
					"login-date": "Login Date/Time (UTC)"
				},
				"user-tracking": {
					"user": "User Name",
					"email": "E-Mail",
					"action": "Action",
					"action-by": "Action by",
					"role": "Role Name",
					"datetime": "Date/Time (UTC)",
					"type": "Internal/External"
				},
				"study-config": {
					"app-header": "Application Information",
					"logging-header": "Logging Information",
					"app-version": "Application Version",
					"efdb-version": "EFDB Version",
					"user-session": "User Log Session",
					"study-data-type": "Study Data Type",
					"modality-type": "Modality Type",
					"epro-ptl-status": "ePRO Protocol Status",
					"flags": "Flags",
					"role-flags": "Role Flags",
					"roles": "Roles",
					"site-roles": "Roles For SQF/Study Permissions In Session",
					"module-name": "Module Name",
					"widget-name": "Widget Name",
					"button-name": "Button Name",
					"report-name": "Report Name",
					"roles-in-bold": "Roles (User Roles in bold)",
					"all-privileges": "Has All Privileges: ",
					"has-access-global": "Has Access Level Global: ",
					"has-access-study": "Has Access Level Study: ",
					"has-access-country": "Has Access Level Country: ",
					"has-access-site": "Has Access Level Site: ",
					"device-types": "Device Types",
					"study-header": "Study Information",
					"study-name": "Study Name",
					"acct-number": "Account Number",
					"protocol-id": "Protocol ID",
					"protocol-status": "Protocol Status",
					"start-date": "Protocol Start Date",
					"end-date": "Protocol End Date",
					"config-sync": "Last Configuration Sync",
					"expert-avail": "Availability of Expert",
					"sqf-pin": "SQF PIN",
					"pin-status": "SQF PIN Status in Session",
					"study-type": "Types and Status values",
					"study-setting": "Study Settings",
					"close-visit": "Allow Close Visits",
					"subject-rollover": "Allow Subject Rollover",
					"subject-range": "Enforce Subject Ranges",
					"show-alert": "Show Alerts",
					"show-scoring": "Show Scoring Results",
					"show-questionaire": "Show Questionaires",
					"subject-pin": "Allow Set Subject PIN",
					"lln-forced": "Show LLN for Forced Spirometry",
					"lln-slow": " Show LLN for Slow Spirometry",
					"config-flags": "Configuration Flags (defined by Clario Portal)",
					"flowscreen": "Has FlowScreen",
					"masterscope": "Has Masterscope",
					"hasCgm": "Has CGM",
					"hasEcoaHandheld": "Has eCOA Handheld",
					"hasEcoaTablet": "Has eCOA Tablet",
					"hasEasyOnePro": "Has NDD EasyOne Pro",
					"hasDiaryPro": "Has DIARYpro",
					"hasSitePro": "Has SITEpro",
					"hasVivalink": "Has Vivalink",
					"hasCough": "Has Cough",
					"isDiaryProOnlyStudy": "Is DIARYpro only",
					"legacy": "Has legacy Ecg",
					"paper": "Has Ecg Using Paper",
					"patient-device": "Has Subject Related Devices",
					"device-account": "Uses My Device Account",
					"subject-device": "Has Subject Device Info",
					"enrollment-graph": "Has Enrollment Graph",
					"user-roles": "User Roles in Session",
					"permission-sitemap": "Permission - SiteMap",
					"dashboard": "Dashboard",
					"clinical-data": "ClinicalData",
					"data-management": "DataManagement",
					"reports": "Reports",
					"study-permissions": "StudyPermissions",
					"announcements": "Announcements",
					"logistics": "Logistics",
					"admin": "Admin",
					"sqf": "Sqf",
					"permission-widget": "Permission - Widgets",
					"permission-button": "Permission - Buttons",
					"permission-reports": "Permission - Reports"
				}
			},
			"list": {
				"all-columns": "All Columns",
				"hint-use-filtering": "Too many items to display. Use filtering please."
			},
			"deviceManagement": {
				"devices": {
					"flowscreen": 'FlowScreen',
					"easyOnePro": 'EasyOne Pro',
					"masterscope": 'Masterscope',
					"sitepro": 'SITEpro',
					"spiroSphere": 'SpiroSphere',
					"iSpiro": 'iSpiro',
					"ecoaTablet": 'eCOA Tablet',
					"feno": 'FeNO',
					"cgm": 'CGM',
					"cgm-web-uploader": 'CGM Web Uploader',
					"ecoaHandheld": 'eCOA Handheld',
					"am3": 'AM3',
					"diarypro": 'DIARYpro',
					"clarioApp": 'Clario App',
					"vivalink": 'Vivalink',
					"cough": 'Cough'
				},
				"columns": {
					deviceId: 'Device Serial Number',
					site: '{{Site}} #',
					status: 'Status',
					'device-property': 'Device Property',
					'device-property-value': 'Value'
				},
				"labels": {
					reason: 'Reason for Change',
					selectReason: 'Select Reason',
					titleUnassignDevice: 'Unassign Device',
					active: 'Active',
					unassignDevice: 'Unassign Device',
					inactive: 'Inactive',
					noDevices: 'No Device Information available.',
					deviceInfo: 'Device Information',
					title: 'Device Management',
					noSubjects: 'No {{Subject}}s',
					noSites: 'No {{Site}}s',
					pleaseSelect: 'Please select',
					site: '{{Site}}',
					subject: '{{Subject}}',
					date: 'Date',
					loading: 'Loading...',
					mandatory: 'Mandatory fields',
					siteStartupCode: '{{Site}} Startup Code',
					accessCodeForAdminGateway: 'Admin Access Code',
					resetCode: 'Temporary Unlock Code',
					unlockCode: 'Temporary Unlock Code',
					ecoaTabletStartupCode: 'Startup Code',
					codeOverride: '{{Subject}} Override Code',
					unlockCodeTitle: 'Tablet Temporary Unlock Codes',
					adminAccessCodeTitle: 'Handheld Admin Access Code',
					temporaryAccessCodeTitle: 'Handheld Temporary Unlock Code',
					startUpCodeTitle: 'Tablet Start Up Code',
					notAvailable: '-'
				},
				"buttons": {
					submit: 'Submit',
					cancel: 'Cancel',
					siteUnlockCode: 'Temporary Unlock Code',
					startUpCode: 'Start Up Code',
					adminAccessCode: 'Admin Access Code',
					temporaryAccessCode: 'Temporary Unlock Code',
					myDeviceAccount: 'My Device Account'
				},
				"my-device-account": {
					title: 'My Device Account',
					"device-id": 'Device ID',
					"device-password": 'Device Password',
					"assign-btn": 'Assign My Device Account',
					"description-assign": 'Please press the button to assign a new device account to your user.',
					error: 'Error in getting My Device Account information. Please try again later or call Customer Care.'

				},
				"errors": {
					unassignDeviceFailed: 'There was an error unassigning the device. Please contact Customer Care.',
					noStartUpCode: 'There was an error retrieving the start up code. Please contact Customer Care.',
					noUnlockCode: 'There was an error retrieving the unlock codes. Please contact Customer Care.',
					noAdminCode: 'There was an error retrieving the admin access code. Please contact Customer Care.',
					subjectsFiltered: 'Some {{subject}}s do not exist as an ePRO handheld user and were removed from the dropdown selection.',
					noEproSubject: 'No override code available for this {{subject}}.',
					invalidDate: 'Please enter a valid date.',
					noDeviceConfig: 'No Device configuration available.',
					getDevicePropertiesFailed: 'There was an error retrieving the device information. Please try again or contact Customer Care.'
				},
				"descriptions": {
					unassignDeviceSuccessful: 'The selected Handheld device has been successfully unassigned from the {{subject}}. Please note that the device will no longer be included in this list.',
					unassignDevice: 'The selected Handheld device will be unassigned from the {{subject}}. Please note that the device will no longer be included in this list.',
					siteUnlockCode: 'The temporary unlock code is used for giving a 1-day period to a {{site}} user to unlock the device and reset his/her account password.',
					startUpCode: 'The start up code is specific to the site and is used to access the Tablet at initial setup.',
					adminAccessCode: 'This code provides admin specific access, which is used to access the Handheld admin gateway to set up Handheld users.',
					temporaryAccessCode: 'The temporary unlock code is used for giving a 1-day period to a {{subject}} to unlock the device to reset his/her account password.'
				}
			},
			"logistics": {
				"ship-to": "Ship To:",
				"item-name-header": "Name",
				"item-desc-header": "Description",
				"item-qty-header": "Quantity",

				"serial#": "Serial #",
				"order-details": {
					"order-information": "Order Information",
					"site-information": "{{Site}} Information",
					"order-id": "Order",
					"investigator": "Principal Investigator",
					"site#": "{{Site}}",
					"order-submitter": "Order Submitter",
					"ordered-from": "Ordered From",
					"order-datetime": "Order Date Time (UTC)",
					"order-status": "Order Status",
					"comments": "Comments",
					"close": "Close",
					"title": "ORDER",
					"order-items": "Order Items"
				},
				"shipment-details": {
					"shipment-info": "Shipment Information",
					"shipping-address": "Shipping Address",
					"study": "Study: ",
					"pi": "Principal Investigator: ",
					"ordered-from": "Ordered From: ",
					"shipment-datetime": "Shipment Date Time  (UTC): ",
					"country": "Country: ",
					"courier": "Courier: ",
					"tracking#": "Tracking #: ",
					"order#": "Order #",
					"close": "Close",
					"title": "Shipment Details",
					"ship-to": "Ship to: "
				},
				"return-requests": {
					"title": "Return Requests",
					"postingdate": "Posting Date Time (UTC)",
					"order#": "Order #",
					"status": "Status",
					"courier": "Courier",
					"country": "Country",
					"investigator": "Principal Investigator",
					"site#": "{{Site}} #"
				},
				"shipment-tracking": {
					"title": "Shipment Tracking",
					"shipmentdate": "Shipment Date Time (UTC)",
					"country": "Country",
					"investigator": "Principal Investigator",
					"site#": "{{Site}} #",
					"courier": "Courier",
					"tracking#": "Tracking #",
					"order#": "Order #",
					"ordered-from": "Ordered From"
				},
				"supply-ordering": {
					"title": "Supply Ordering",
					"orderdate": "Order Date Time (UTC)",
					"investigator": "Principal Investigator",
					"site#": "{{Site}} #",
					"order#": "Order #",
					"order-submitter": "Order Submitter",
					"ordered-from": "Ordered From",
					"order-status": "Order Status"
				},
				"new-order": {
					"title": "New Order",
					"step1-title": "{{Site}} Selection",
					"select-site-error": "Please select a {{site}}",
					"step2-title": "Order Items",
					"step2-shipping": "Shipping Address",
					"step2-shipto": "Ship to",
					"step2-info": "Information",
					"step2-pending-header": "Items in Pending Orders",
					"item-name-header": "Name",
					"item-desc-header": "Description",
					"max-qty-header": "Max Quantity",
					"item-qty-header": "Quantity",
					"no-item-error": "All the items are in order pending status. No order is possible now.",
					"step2-allowable-header": "Select Items to Order",
					"comments-column": "Comments / Special Instructions / Shipping Address Changes(Enter up to 1800 characters)",
					"invalid-qty-error": "Please enter a valid quantity",
					"invalid-comment-error": "The comment cannot exceed 1800 characters.",
					"qty-error": "Please provide the quantity of the items to be ordered",
					"step3-title": "Review & Confirm",
					"comments-label": "Comments / Instructions:",
					"create-order": "Create Order",
					"step3-items-to-order": "Items to Order",
					"next": "Next",
					"back": "Back",
					"cancel": "Cancel",
					"siteid": "{{Site}} #",
					"investigator": "Principal Investigator Name",
					"order-retrieval-error": "There was an error in retrieving your order data. If you have any questions, please call Clario Customer Care toll-free at 1-800-704-9698 option 4 in the US or Canada, or +1-908-595-2020 option 4 outside the US. Please consult your study guide for your country specific toll free number. A Customer Care representative is available to assist you 24 hours per day, 7 days per week. We can also be contacted through email at customercare@clario.com.",
					"signature-incomplete-error": "Please provide signature.",
					"signature-email-mismatch-error": "Only currently logged in user may sign!",
					"signature-validation-error": "Username/Password validation failed.",
					"save-success": "Your supply order has been successfully submitted.",
					"save-success-note": "After submitting the order a confirmation email will be sent to you. If you have any questions, please call Clario Customer Care toll-free at 1-800-704-9698 option 4 in the US or Canada, or +1-908-595-2020 option 4 outside the US. Please consult your study guide for your country specific toll free number. A Customer Care representative is available to assist you 24 hours per day, 7 days per week. We can also be contacted through email at customercare@clario.com.",
					"save-error": "There was an error in submitting your order. If you have any questions, please call Clario Customer Care toll-free at 1-800-704-9698 option 4 in the US or Canada, or +1-908-595-2020 option 4 outside the US. Please consult your study guide for your country specific toll free number. A Customer Care representative is available to assist you 24 hours per day, 7 days per week. We can also be contacted through email at customercare@clario.com.",
					"save-cancel": "You have decided to cancel the order. If you have any questions, please call Clario Customer Care toll-free at 1-800-704-9698 option 4 in the US or Canada, or +1-908-595-2020 option 4 outside the US. Please consult your study guide for your country specific toll free number. A Customer Care representative is available to assist you 24 hours per day, 7 days per week. We can also be contacted through email at customercare@clario.com.",
					"info-message": "Studies needing clinical trial supplies for respiratory devices must call Clario Customer Care to request those items. Please click on the Customer Care link at the top of the main page to find our contact information for your country.",
					"no-site-error": "You don't have access to any {{site}} where you are allowed to place orders.",
					"no-shipment-error": "Your initial {{site}} setup is still in progress within our system or the study has been completed. If your study is Active and Supply ordering continues to be unavailable, please contact Customer Care to confirm that supplies are available to order for your study and {{site}}.",
					"site-loading-error": "There was an error in retrieving the data. if the problem persists, please call Clario Customer Care.",

					"auth-failure": "Authentification failure! Please enter your correct portal password.",
					"less-site-error": "This action cannot be performed for all assigned {{site}}s due to your {{site}}-specific permissions.",
					"no-supplies-error": "The selected {{site}} has no supplies that are available for ordering. Please call Clario Customer Care at +1-908-595-2020."
				}
			},
			"signature": {
				button: {
					"sign": 'Sign'
				},
				info: {
					study: 'Study',
					site: '{{Site}}',
					subject: '{{Subject}}',
					visitName: 'Visit Name',
					visitPeriodName: 'Visit Name (Period)',
					eventName: 'Event Name',
					userMail: 'User Email',
					id: 'ID'
				},
				action: {
					newPaperPft: 'Create New Data Entry',
					newDcr: 'Create New DCR',
					answerDcr: 'Answer DCR',
					editSubject: 'Edit {{Subject}}',
					editUser: 'Edit User',
					deleteUser: 'Delete User',
					deleteSubject: 'Delete {{Subject}}',
					createSubject: 'Create {{Subject}}',
					createOrder: 'Create New Order',
					createSiteUser: 'Create User',
					createSponsorUser: 'Create Sponsor User',
				},
				"description": "After clicking on the Sign button you will be redirected to the signature page. On this page you need to enter your user name and password. After successful authentication you will be redirected back to this page.",
				"incomplete-error": "Please provide email and password and check the checkbox",
				"email-mismatch-error": "Only currently logged in user may sign!",
				"password-incorrect": "Given password is incorrect",
				"invalid": 'Given signature is invalid',
				"invalid-restart": 'Your given signature is no longer valid. Please restart the workflow again.',
				"added-successful-title": 'Signature added: ',
				"added-successful-text": 'You have finished the signature process.',
				"provide-title": 'Please provide Signature to: ',
				"provide-text": 'After clicking on Sign button you will be redirected to the signature page. And when your login and password will be validated we will return you back to data management screen.',
				"loading-title": 'Please wait...',
				"loading-text": '... while we are verifiying your signature.',
				"missing-signature-title": 'Error in Signature Workflow',
				"missing-signature-text": 'There was an error in the Signature Workflow and the current wizard state could not be restored. Please try it again or contact customer care.'
			},
			"reports": {
				ods : {
					title: 'Interactive Reports'
				},
				"abpm-reports": {
					"title": "ABPM Reports"
				},
				"holter-reports": {
					"title": "Holter Arrhythmia Reports",
					download: 'Download',
					downloadHSFFs: 'Download HSFFs',
					downloadHAReports: 'Download HA Reports',
					downloadBoth: 'Download Both'
				},
				"ecg-source-tracings": {
					"title": "Source ECG Tracing"
				},
				"data-status-forms": {
					"title": "Data Status Forms",
					download: 'Download',
					downloadAll: 'Download All',
					downloadSelected: 'Download Selected'
				},
				"create-report": {
					"title": "Create Report",
					"name": "Report Name",
					"description": "Short Description",
					"create": "Create",
					"send-by-email": "Send me a copy by email",
					"email-notification": "Email Notification",
					"format": "Format",
					"error-create": "There was an error creating the report. Please try again later.",
					"format-error": "Please select a format for the report",
					"country-error": "Please select a country",
					"site-error": "Please select a {{site}}",
					"subject-error": "Please select a {{subject}}",
					"list-error": "Please select a value",
					"date-error": "Please select a date in the format dd-MMM-yyyy",
					"text-error": "Please provide a value",
					"radio-error": "Please select an option",
					"report-format": "Report Format",
					"success-message": "The Report will be created soon. Please see My Report section for details.",
					'default-category-title': 'Others',
					'category_header_title': 'Category Name'
				},
				"ecg-export": {
					"title": "ECG Data Export - Select Export Columns",
					"main-title": "ECG Data Export",
					"investigator": "Principal Investigator",
					"site": "{{Site}} #",
					"dob": "DOB",
					"init": "Init",
					"gndr": "Gndr",
					"race": "Race",
					"visit": "Visit",
					"collection-date": "Collection Date/Time",
					"complete-date": "Complete Date/Time",
					"transmittal": "Tr. #",
					"overall-evaluation": "Overall Evaluation",
					"alerts": "Alerts",
					"qt": "QT Value",
					"qt-change": "QT Change from Base",
					"qtcb": "QTcB Value",
					"qtcb-change": "QTcB Change from Base",
					"qtcf": "QTcF Value",
					"qtcf-change": "QTcF Change from Base",
					"ectopy": "Ectopy Finding",
					"conduction": "Conduction Finding",
					"morphology": "Morphology Finding",
					"myocardial": "Myocardial Infarction Finding",
					"rhythm": "Rhythm Finding",
					"st": "ST Segment Finding",
					"twaves": "T Waves Finding",
					"uwaves": "U Waves Finding",
					"axis": "Axis Finding",
					"machine": "Machine Findings",
					"clinical": "Clinical Alert",
					"protocol": "Protocol Alert",
					"exclusion": "Exclusion/Withdrawal Alert"
				},
				"select-columns": {
					"lead": "One record for every lead",
					"export-currentfilter": "Export according to current filtering",
					"export-all": "Export all",
					"csv": "CSV",
					"xls": "XLSX",
					"restore-default": "Restore Default",
					"study": "STUDY/SUBJECT/VISIT",
					"ecg": "ECG INFORMATION AND ALERTS",
					"interval-all": "INTERVAL DURATION MEASUREMENTS",
					"findings": "FINDINGS",
					"export": "Export",
					"study-cols": {
						"col1": "Clario Account #",
						"col2": "Sponsor Name",
						"col3": "Protocol Name",
						"col4": "{{Site}} Country",
						"col5": "Investigator Name",
						"col6": "{{Site}} Number",
						"col7": "{{Subject}} ID",
						"col8": "{{Subject}} ID2",
						"col9": "Initials",
						"col10": "Date of Birth",
						"col11": "GNDR",
						"col12": "Ethnicity",
						"col13": "Period Name",
						"col14": "Visit Name",
						"col15": "Timepoint Name",
						"col16": "Repeated Visit Flag",
						"col17": "Baseline Visit (Y/N)"
					},
					"ecg-cols": {
						"col1": "TransmittalNumber",
						"col2": "CollectionDateTime",
						"col3": "CompleteDateTime",
						"col4": "Analysis Lead(s)",
						"col5": "Cardiologist Name",
						"col6": "Physician Comments",
						"col7": "ECG Remarks",
						"col8": "HR Alert? (Y/N)",
						"col9": "RR Alert? (Y/N)",
						"col10": "PR Alert? (Y/N)",
						"col11": "QRS Alert? (Y/N)",
						"col12": "QT Alert? (Y/N)",
						"col13": "QTcB Alert? (Y/N)",
						"col14": "QTcF Alert? (Y/N)",
						"col15": "QTcL Alert? (Y/N)",
						"col16": "Review Alerts",
						"col17": "Clinical Alerts"
					},
					"interval-cols": {
						"col1": "HR Value",
						"col2": "HR Baseline Mean",
						"col3": "HR Change from Base",
						"col4": "RR Value",
						"col5": "RR Baseline Mean",
						"col6": "RR Change from Base",
						"col7": "PR Value",
						"col8": "PR Baseline Mean",
						"col9": "PR Change from Base",
						"col10": "QRS Value",
						"col11": "QRS Baseline Mean",
						"col12": "QRS Change from Base",
						"col13": "QT Value",
						"col14": "QT Baseline Mean",
						"col15": "QT Change from Base",
						"col16": "QTcB Value",
						"col17": "QTcB Baseline Mean",
						"col18": "QTcB Change from Base",
						"col19": "QTcF Value",
						"col20": "QTcF Baseline Mean",
						"col21": "QTcF Change from Base",
						"col22": "QTcL Value",
						"col23": "QTcL Baseline Mean",
						"col24": "QTcL Change from Base"
					},
					"finding-cols": {
						"col1": "Rhythm",
						"col2": "Conduction",
						"col3": "Ectopy",
						"col4": "Morphology",
						"col5": "Myocardial Infarct",
						"col6": "ST Segment",
						"col7": "T Wave",
						"col8": "U Wave",
						"col9": "Overall Interpretation",
						"col10": "Comparison",
						"col11": "Clinical Relevance",
						"col12": "Machine Algorithm",
						"col13": "Axis"
					}
				},
				"my-reports": {
					"title": "My Reports",
					"selection-criteria": "Selection Criteria",
					"open-report": "Open Report",
					"close": "Close",
					"reports": "Reports",
					"status": "Status",
					"created": "Created (UTC)",
					"created-by": "Created By",
					"format": "Format",
					"open-error": "Error in opening the document",
					"criteria": "Criteria",
					"delete-title": "Delete",
					"criteria-error": "Error in loading criteria details",
					"no-criteria": "No selection criteria found for the report",
					"download": "Download",
					"select-report": "Please select  report(s) to download",
					"download-error": "Error in downloading reports",
					"delete": {
						"title": "Delete Report",
						"report": "Report",
						"status": "Status",
						"created-by": "Created by",
						"created-on": "Created On",
						"format": "Format",
						"message": "The report will be deleted, and cannot be recovered.",
						"delete-message": "Report deleted successfully",
						"delete-error-message": "There was an error in deleting the report. Please try again later."
					}
				},
				"selection-criteria": {
					"no-criteria": "No selection criteria found for the report"
				},

				"training-document": {
					"title": "Open Document",
					"main-title": "Training Documents",
					"select-document": "Please select  document(s) to download",
					"close": "Close",
					"site": "{{Site}} / PI Name",
					"operator": "Operator",
					"email": "Operator email address",
					"assessment": "Assessment Type",
					"date": "Date Created",
					"download": "Download"
				},
				"report-review": {
					"main-title": "Review Reports",
					"title": "Review Reports",
					columns: {
						'report-name': 'Report Subtype',
						'report-type': 'Report Type',
						'sponsor-site-id': '{{Site}} #',
						'visit-name': 'Visit Name',
						'event-name': 'Event Name',
						'visit-event-name': 'Visit / Event',
						'event-date': 'Event Date',
						'revised': 'Revised',
						'first-complete-date': 'First Report Generation Date',
						'last-complete-date': 'Last Report Generation Date',
						confirmed: 'Approved',
						dateReviewed: 'Date/Time Approved',
						reviewedBy: 'Approved By',
						requiresConfirmation: 'Requires Approval',
						reportVersion:'Report Version'
					},
					actions: {
						downloadConfirmation: {
							message: 'Your download request was submitted successfully.'
						},
						confirmationConfirmation: {
							message: 'Thank you for approving this report.',
							messagePlural: 'Thank you for approving these reports.'
						},
						downloadSelectedZip: 'Download Selected Reports as ZIP',
						downloadSelectedPdf: 'Download and Merge Selected Reports as PDF',
						downloadPopup: 'Download Request (Advanced Filter)',
						confirm: 'Approve',
						confirmSelected: 'Approve Selected'
					},
					info: {
						'download-error-title': 'Download Error',
						'download-error-description': 'The following files could not be included in your download:',
						column: 'File Name'
					},
					popup: {
						title: 'Download Request (Advanced Filter)',
						description: 'By clicking Submit, your download request will be prepared. Once it is complete, you will be notified by email and you can access your reports in the My Downloads section.',
						pdfOption: 'Merge into a single PDF',
						zipOption: 'Separate files in a ZIP',
						downloadType: 'Download Type',
						numberOfReports: 'Number of included reports with current selection: ',
						fileName: 'Download File Name',
						includeAllReportsOption: 'Include all reports',
						selectReportOption: 'Select specific report types',
						dateRange: 'Date Range',
						fromDate: 'From',
						toDate: 'To',
						reportTypes: 'Report Types',
						site: '{{Site}}',
						sitePlaceholder: 'Please select',
						subjects: '{{Subject}}s',
						includeAllSubjectsOption: 'Include all {{subject}}s',
						selectSubjectOption: 'Select specific {{subject}}s',
						selectSubjectError: 'At least one selected {{subject}} is required',
						'from-to-date-error': 'Please define a valid date range',
						selectReportTypeError: 'At least one selected report type is required',
						'max-length': 'The maximum number of characters allowed is 150',
						'no-subjects': 'The {{site}} contains no subjects, please change your selection.',
						'no-reports': 'Your selection contains no reports, please change your selection criteria.',
						'reportVersionAllRadioOption': 'Include all reports versions',
						'reportVersionLatestRadioOption': 'Include only latest report versions'
					},
					confirmationSingle: {
						popup: {
							title: 'Confirm & Approve Report',
							acknowledge: 'By clicking "Confirm & Approve" you acknowledge the receipt and approval of the selected report.',
							buttons: {
								confirm: 'Confirm & Approve'
							}
						},
					},
					confirmationMultiple: {
						popup: {
							title: 'Confirm & Approve Reports',
							acknowledge: 'By clicking "Confirm & Approve" you acknowledge the receipt and approval of the following selected reports.',
							notConfirmable: 'The following selected reports do not require your approval.',
							buttons: {
								confirm: 'Confirm & Approve'
							}
						},
					}
				}
			},
			"my-downloads": {
				title: 'My Downloads',
				uploadFile: 'Upload File',
				columns: {
					id: 'ID',
					name: 'File Name',
					requestDate: 'Request Date (UTC)',
					scheduleDate: 'Schedule Date',
					updateDate: 'Completion Date (UTC)',
					status: 'Status',
					message: 'Comments'
				},
				popup: {
					title: 'Download Info',
					description: 'The following reports were requested for download:',
					'description-download-error': 'The following files could not be included in your download:',
					errorHint: ' (The reports highlighted with red could not be included in your download)',
					column: 'Report Name'
				},
				fileReady: 'Your report file was generated successfully. Please navigate to the <a href="{0}" class="ert-notification-link">My Downloads</a> section to download your file.',
				fileReadyMyDownloads: 'Your report file was generated successfully.'

			},
			'data-management-files': {
				tabName: 'Data Management Files',
				columns: {
					uploadDate: 'Upload Date',
					fileSize: 'File Size',
					deleteFile: 'Delete File',
					downloadFile: 'Download File'
				},
				messages: {
					confirmDelete: 'Are you sure you want to delete this file?',
					successOnDelete: 'The file was deleted successfully',
					errorOnDelete: 'There was an error while deleting the file. Please, try again.',
					fileWarningMessage: "Files with size more than 75MB may take several minutes to upload. The maximum file size allowed is 3 GB.",
					successOnUpload: 'The file was uploaded successfully'
				}
			},
			"announcements": {
				"title": "Announcements",
				"preview": "Preview",
				"priority": "Priority",
				"grid-title": "Title",
				"active": "Active",
				"global": "Global",
				"study#": "Assigned Studies",
				"created": "Created",
				"created-by": "Created by",
				"modified": "Modified",
				"modified-by": "Modified by",
				"size": "Size",
				"content-type": "Content Type",
				"permission-error": "User does not have the permission",

				"new": {
					"title": "New Announcement"
				},
				"edit": {
					"title": "Edit Announcement",
					"name": "Edit",
					"save": "Save",
					"error-message": "There was an error in creating the announcment. Please try again later.",
					"sponsor": "Sponsor",
					"study-name": "Study Name",
					"study#": "Study #",
					"ann-title": "Title",
					"ann-priority": "Priority",
					"ann-visibility": "Visibility",
					"priority-message": "Enter priority from 0 (LOW) to 999(HIGH)",
					"file-upload": "Upload New File",
					"current-file": "Current File: ",
					"global": "Global",
					"active": "Active",
					"study-assignment": "Study Assignment",
					"title-error": "Provide the title for the announcement",
					"priority-error": "Provide the priority for the announcement",
					"file-error": "Provide the file for the announcement",
					"study-error": "Please mark the announcement as Global or select some studies for the announcement",
					"global-message": "Announcement is set to global and will be shown in all studies.",
					"file-type-message": "Only image files and html files can be uploaded.",
					"file-upload-error": "Please select a file to upload or you have selected an invalid file type.",
					"update": "Edit"
				},
				"delete": {
					"name": "Delete",
					"title": "Delete Announcement",
					"message": "The announcement will be deleted, and cannot be recovered.",
					"error-message": "There was an error in deleting the announcement. Please try again later."
				},
				"study-assignment": {
					"sponsor": "Sponsor",
					"study-name": "Study Name",
					"study-id": "Study #"
				}
			},
			"refmat": {
				'file-status': 'File Status',
				'folder-status': 'Folder Status',
				"title": "Reference Materials",
				"new-folder-title": "New Folder",
				"study-title": "Study Associations",
				"edit-title": "Edit",
				"delete-title": "Delete",
				"add-file-title": "Add File",
				"preview-title": "Download",
				"studies-title": "Studies",
				"error-loading": "Error loading data",
				'files': 'files',
				'file': 'file',
				"delete-folder": {
					"title": "Delete Folder",
					"message": "The folder will be deleted, and cannot be recovered."
				},
				"delete-file": {
					"title": "Delete File",
					"message": "The file will be deleted, and cannot be recovered."
				},
				"study-assignment": {
					"sponsor": "Sponsor",
					"study-name": "Study Name",
					"study-id": "Study #"
				},
				"edit": {
					"folder-name": "Folder Name",
					"active": "Active",
					"study-assignment": "Study Assignment",
					"folder-message": "Please provide the folder name",
					"number-files": "No: of Files",
					"created-on": "Created on",
					"created-by": "Created by",
					"modified-on": "Modified on",
					"modified-by": "Modified by",
					"save": "Save",
					"title": "Edit Folder",
					"update": "Edit",
					"error-loading": "There was an error loading the data",
					"error-save": "There was an error in saving the folder",
					"current-file": "Current File",
					"file-upload": "Upload File",
					"files-upload": "Upload Files",
					"choose-file": "Choose File",
					"file-message": "Provide the file to upload",
					"unpack": "Unpack",
					"file-title": "Edit File",
					"error-save-file": "There was an error saving the file",
					"study-select-error": "Select the studies for which the reference material is applicable.",
					"file-warning-message": "Files with size more than 75MB may take several minutes to upload. The maximum file size allowed is 2 GB.",
					"file-max-error": "File exceeds the maximum allowed limt of 2GB.",
					"file-75MB-warning": "The file you have uploaded is more than 75 MB and may take several minutes to upload."
				},
				"new": {
					"title": "New Folder",
					"file-title": "New File"
				}

			},

			'data-management': {
				'page-title': 'Data Management',
				'addAttachment': {
					title: 'Add Attachments',
					notifyInformation: 'Please add attachments relevant to the associated Study and selected {{Subject}} only.',
					confirmDeleteFileTitle: 'Confirm Delete File',
					confirmDeleteFileMessage: 'Are you sure you want to permanently delete this file?',
					chooseFilesButton: 'Choose Files',
					commentsPlaceHolder: 'Please add your comments here...',
					removeFileReasonTitle: 'Reason for deleting file:',
					removeFileReasonPlaceholder: 'Please enter a reason to delete this file',
					requirement: {
						title: 'Please attach the following documents:',
						slow: {
							line1: 'Volume Calibration from your equipment',
							line2: 'Slow tracing for each individual effort, including deselected efforts',
							line3: 'Data Table for each individual effort performed, including deselected efforts',
							line4: 'Spirogram (superimposed flow volume curves representing resting tidal breathing prior to maximum inspiration and forced vital capacity maneuvers), for combination tests'

						},
						force: {
							line1: 'Volume Calibration from your equipment',
							line2: 'Forced tracing for each individual effort, including deselected efforts',
							line3: 'Volume time tracing for Forced measurements',
							line4: 'Data Table for each individual effort performed, including deselected efforts'
						},
						prodlco: {
							calibration: 'Gas and volume calibration from the date of testing for your equipment',
							line2: 'DLCO tracing and gas analysis curve for each DLCO effort collected',
							line3: 'DLCO data table showing all the required parameters for each DLCO effort collected (see DLCO Checklist/Procedure Manual for list of required parameters)',
							line4: 'Start time of each DLCO effort',
							line5: 'Forced spirometry report showing the subject\'s vital capacity (should be collected within 2 weeks of the DLCO test)',
						},
						subdlco: {
							calibration: 'Gas and volume calibration from the date of testing for your equipment',
							line2: 'DLCO tracing and gas analysis curve for each DLCO effort collected',
							line3: 'DLCO data table showing all the required parameters for each DLCO effort collected (see DLCO Checklist/Procedure Manual for list of required parameters)',
							line4: 'Start time of each DLCO effort',
							line5: 'Subject\'s measured hemoglobin value (should be collected within 30 days of the DLCO test)',
							line6: 'Forced spirometry report showing the subject\'s vital capacity (should be collected within 2 weeks of the DLCO test',
						}
					},
					errors: {
						requireUploadFiles: 'Must have at least one file',
						requireRemoveFileReason: 'Please enter reason for deleting file.',
						saveInProgress: 'Please wait until the file has finished saving',
						saveErrorMessageVirus: 'File was rejected by virus scanner',
						saveErrorMessage: 'There was an error saving the file',
						invalidFileTypeMessage: 'You have selected an invalid file type.',
						invalidFileSizeMessage: 'File exceeds the maximum allowed limit of ',
						agreementRequired: 'Please confirm the agreement by selecting the checkbox above.',
					},
					'addNewAttachmentButton': 'Add new Attachment',
					fileUploadProgress: 'File upload progress:',
					agreementText: '*By selecting this checkbox, you confirm that there is no {{subject}} personal information in the attachment file names and that any {{subject}} personal information within the attachments is redacted.',
				},
				columns: {
					site: '{{Site}} #',
					subject: '{{Subject}} #'
				},
				wizard: {
					"select-step": "Data Object|Select {{Site}}, {{Subject}}",
					"query-type-subject-status": "{{Subject}} Status Change",
					headings: {
						dataObj: 'Data Object',
						dcrDescription: 'DCR Description'
					}
				},
				"site": "{{Site}}",
				"subject": "{{Subject}}",
				"subjectId": "{{Subject}} ID",
				"subject-demographics": "{{Subject}} Demographics",
				"subject-information": "{{Subject}} Information",
				"site-information": "{{Site}} Information",
				"site-name": "{{Site}} Name",
				"select-site": "Select {{Site}}",
				"select-subject": "Select {{Subject}}",
				"site-no-subjects": "{{Site}} has no {{Subject}}s",
				"no-sites": "No {{Site}}s",
				"no-subjects": "No {{Subject}}s",
				"missing-subjects": "[Missing {{Subject}}]",
				"title-answer-dcr": "Answer DCR",
				"title-create-dcr": "New DCR",
				genericErrorLoadingDetails: "An error occurred loading ticket details",
				fewerSites: "This action cannot be performed for all assigned {{site}}s due to your {{site}}-specific permissions.",
				noTicketId: 'Ticket creation failed because the event/transmittal is locked. Please deselect the event or contact <a href="https://clario.com/contact/customer-support/" target="_blank">Clario Customer Care</a> if the error persists.',
				messageTitle: 'Message',
				queryTextLabel: 'The following text was written by the Clario Data Management team to explain the query that requires clarification.',
				responseTitle: 'Response',
				answerTextLabel: 'Please enter a clear response to the above stated issue(s) that will help to resolve this query.',

				details: {
					information: 'Information',
					mscctId: 'MSCCT DCR ID',
					status: 'Status',
					created: 'Created (UTC)',
					createdBy: 'Created by',
					description: 'Description',
					message: 'Message',
					eventInfo: 'Event Information',
					transmittalNumber: 'Transmittal #',
					collectionDate: 'Collection Date',
					collectionTime: 'Collection Time',
					'site-name': '{{Site}} Name',
					attachments: 'Attachments',
					history: 'History',
					userName: 'User Name',
					actionTime: 'Action Time',
					actionType: 'Action Type',
					actionDescription: 'Action Description',
					fileName: 'File Name',
					comments: 'Comments',
					fileNotAvailable: 'File not available'
				},
				new: {
					queryInfo: 'Query Information',
					subject: '{{Subject}}',
					visit: 'Visit:',
					visitStart: 'Visit Start:',
					event: 'Event:',
					'save-success': 'Your query has been successfully submitted.'
				}
			},
		
		'cde-generic': {
				label: {
					fromLastName: 'From Last Name',
					fromFirstName: 'From First Name',
					fromEmail: 'From Email',
					study: 'Study',
					site: 'Site',
					subject: 'Subject',
					selectStudy: 'Select Study',
					selectSite: 'Select Site'
				},
				title: {
					attachments: 'Attachments',
					addAttachments: 'Add Attachments'
				}
			},
			'cde-ecoa': {
				titles: {
					selectSubject: 'Select {{Subject}}',
					submitterAndSubjectInfo: 'Submitter and {{Subject}} Information',
					subjectInfo: '{{Subject}} Information',
					deviceInfo: 'Device Information',
					submitterDetails: 'Submitter Information',
					subjectCardTitle: '{{Subject}}',
					deviceCardTitle: 'Device',
					submitterCardTitle: 'Submitter',
					commentsHistory: 'Review Comments',
					subjectDetails: '{{Subject}} Details',
					newEcoaEntry: 'Paper COA - Add New Entry',
					mainTitle: 'Paper COA',
					inReconciliationTitle: 'In Reconciliation',
					formViewTitle: `{formName} - Paper COA ID: {id},
						{{Site}}: {sponsorSiteId},
						{{Subject}}: {sponsorSubjectId}
					`,
					attachmentInstructionForHandheld: `For a <b>Handheld device</b>, please add files for no more than <b>20 assessments</b> per Paper COA.
											<br/>
											`,
					attachmentInstructionForTablet: 'For a <b>Tablet device</b>, please add files for assessments related to the <b>Visit selected</b> only.',
					editForm: 'Edit Form',
					viewForm: 'View Form',
					deleteForm: 'Delete Form',
					formLevelData: 'Form Level Data',
					formLevelPhase: 'Phase'
				},
				detailsLabels: {
					id: 'Paper COA ID',
					study: 'Study',
					site: 'Site',
					submitterName: 'Submitter Name',
					submitterEmail: 'Submitter Email'
				},
				label: {
					deviceType: 'Device Type',
					selectDevice: 'Select Device',
					sessionType: 'Session Type',
					selectSession: 'Select Session',
					visit: 'Visit',
					selectVisit: 'Select Visit',
					phase: 'Phase',
					selectPhase: 'Select Phase',
					selectTimezone: 'Select Timezone',
					timezone: 'Timezone',
					subjectTimezone: 'Subject Timezone',
					siteTimezone: 'Site Timezone',
					reportDate: 'Report Date',
					reportTime: 'Time at Report (24 HR)',
					reportStartDate: 'Report Start Date',
					reportStartTime: 'Time at Report Start (24 HR)',
					sessionStartDate: 'Visit Start Date',
					sessionStartTime: 'Time at Visit Start (24 HR)',
					sessionEndDate: 'Visit End Date',
					sessionEndTime: 'Time at Visit End (24 HR)',
					phaseStartDate: 'Phase Start Date',
					phaseStartTime: 'Phase Start Time (24 HR)',
					reviewComments: 'Review Comments',
					reviewCommentsToSite: 'Comments to Site',
					reviewCommentsToSitePlaceHolder: `Please start typing a comment to 'Request More Information' from the Site.`,
					reviewCommentsToErt: 'Comments to Clario',
					reviewCommentsToErtPlaceholder: `Please add 'Comments to Clario' to submit this Paper COA.`,
					resolutionNotes: 'Resolution Notes',
					resolutionNotesPlaceHolder: 'Please provide your notes here.',
					addFormPlaceholder: 'Select Form',
					addForm: 'Form',
					requestMoreInfo: 'Request More Information',
					initFirstEntry: 'Initiate First Entry',
					form: 'Form: ',
					module: 'Module: ',
					question: 'Q',
					visitReason: 'Visit Reason',
					visitReasonPlaceholder: 'Please enter visit reason',
					visitStatus: 'Visit Status',
					selectVisitStatus: 'Select Visit Status',
					isPhaseChange: 'Change Subject Phase',
					visitStartDateTime: 'Visit Start Date/Time',
					visitEndDateTime: ' Visit End Date/Time',
					notAvailable: 'Not Available',
					forms: 'Forms',
					addForms: 'Add Forms',
					completed: 'Completed',
					incomplete: 'Incomplete'
				},
				buttons: {
					submit: 'Submit',
					cancel: 'Cancel',
					submitFirstDataEntry: 'Complete First Entry',
					addForm: 'Add Form',
					submitSecondDataEntry: 'Complete Second Entry',
					reject: 'Reject Paper COA',
					save: 'Save Changes',
					deleteForm: 'Delete Form'
				},
				dialog: {
					rejectTitle: 'Paper COA Reject Reason',
					rejectReasonLabel: 'Please enter a reason for rejection.',
					removeFormDialogTitle: 'Remove Form Confirmation',
					removeFormDialogText: 'Are you sure you want to delete this from?'
				},
				grid: {
					labels: {
						paperTitle: 'Paper COA',
						createEntryLabel: 'New Paper COA',
						modalityName: 'Device',
						sessionName: 'Session',
						visitName: 'Visit',
						phaseName: 'Phase',
						actions: 'Actions',
						formName: 'Form Name',
						results: 'Comparison results',
						status: 'Status',
						reportDate: 'Report Date',
						reportStartDate: 'Report Start Date/Time',
						phase: 'Phase'
					}
				},
				errors: {
					dateFieldRequired: `The date field is required`,
					timeFieldRequired: `The time field is required`,
					requiredErrorComments: `Please provide your comments to be able to 'Submit' this Paper COA.'`,
					requiredErrorCommentsForMoreInfo: `Please provide your comments to be able to 'Request More Information' from the {{Site}}.`,
					invalidForm: 'Submit failed. Please resolve data validation errors.',
					genericErrorMessage: 'An error has occurred. Please try again later or contact Customer Care.',
					pengingReconciliation: 'Some questions still need reconciliation. Please, resolve all discrepancies.',
					validVisitStatus: `It's not allowed to submit an entry having visit status 'Skipped' or 'Future'.`,
					reportDateValidationMessage: 'Report Date should be between Visit Start Date and Visit End Date',
					reportStartDateValidationMessage: 'Report Start Date should be between Visit Start Date and Visit End Date',
					reportStartTimeValidationMessage: 'Report Start Time should be between Visit Start Time and Visit End Time',
					previousPhaseDateValidationMessage: 'Phase Start Date is earlier than Previous Phase Start Date {{value}}',
					nextPhaseDateValidationMessage: 'Phase Start Date is later than next Phase Start Date {{value}}'
				},
				table: {
					formMismatchFirstEntry: 'Exists in First Entry Only',
					formMismatchSecondEntry: 'Exists in Second Entry Only',
					responsesMismatch: 'Discrepancies found between First Entry & Second Entry',
					reconciled: 'Data discrepancies reconciled',
					noDiscrepancies: 'No discrepancies found between First Entry & Second Entry'
				},
				hints: {
					visitAlreadyExists: 'Please note that a diary has already been submitted either from the device or via Paper with this Visit information.'
				},
				popup: {
					confirmSubmitMessage: 'Please note that you can\'t make any further changes on this form after you submit this form. Are you sure you want to proceed?',
					confirmTitle: 'Confirmation',
					infoTitle: 'Information',
					infoEmptyQuestionaryMessage: 'Please answer at least one question that is not using a checkbox.',
					infoEmptyFormMessage: 'Some forms need second data entry. Please, fill them out to proceed.',
					warningTitle: 'Warning',
					warningFormDataValidation: 'The form with such Report Date and Report Start Date and Time already exists.'
				}
			},
			'cde-pft': {
				auditReport: {
					study: 'Study',
					site: 'Site',
					fullStudyOrSite: 'Include data from all sites?',
					studyOrSiteStudy:  'All sites, single report',
					studyOrSiteStudyZipped:  'All sites, individual reports',
					studyOrSiteSite: 'Single site only',
					individualFiles: 'One file per site?',
					successMessage: 'We are now generating your audit report and it will be available in the <a href="{0}" class="ert-notification-link">My Downloads</a> section soon.',
					errors: {
						required: {
							study: 'Study is required',
							site: 'Site is required'
						}
					}
				},
				errors: {
					required: 'is required',
					hasDiscrepancies: 'has discrepancies',
					hasDiscrepanciesVisit: 'Please re-select the Event based on Visit selected.',
					hasDiscrepanciesPeriod: 'Please re-select the Visit based on Period selected.',
					subjectAlreadyExists: '{{Subject}} number already exists.',
					dobAfterEvent: 'The given subjects date of birth is after the given Event Date. ',
					blockedSubjectCreationInSubjectMode: 'You can only create Proficiency Subjects in this study',
					numericAgeWithUnit: 'Enter a numeric value with up to 3 digits and without decimals.'
				},
				dialog: {
					successSubmitDataTitle: 'Success',
					successSubmitDataContent: 'Your Data Entry has been successfully submitted. If you have any questions, please contact <a href="https://clario.com/contact/customer-support/" target="_blank">Clario Customer Care</a>.',
					lockedTitle: 'Data entry has been locked',
					lockedContent: 'Data Entry has been locked.',
					lockedBy: ' Locked by: ',
					lockedOn: ' Locked on: ',
					requestSiteInputTitle: 'Request Additional {{Site}} Input',
					requestSiteInputCommentTitle: 'Review Comments',
					requestSiteInputCommentPlaceHolder: 'please add comments',
					requestSiteInputMissingTitle: 'Please select the documents that are missing:',
					requestSiteInputMissingTitleForSiteUser: 'Please provide the documents listed below:',
					requestSiteInputErrorMessage: 'Ensure there is either a comment provided or at least one document selected.',
					rejectTitle: 'Reject Data Entry',
					rejectReasonLabel: 'Please enter a reason for rejection.'
				},
				titles: {
					submissionInfoStep: 'Submission Details',
					measurementInfoStep: 'Measurement Details',
					addAttachmentsStep: 'Add Attachments',
					submitStep: 'Submit',
					viewEntry: 'View Data Entry',
					reviewEntry: 'Review Data Entry',
					rejectEntry: 'Reject',
					submitEntry: 'Submit',
					firstEntryByErtForSiteSubmission: 'First Data Entry by Clario',
					secondEntry: 'Second Data Entry',
					secondEntryByErtForSiteSubmission: 'Second Data Entry by Clario',
					editEntry: 'Edit Data Entry',
					subjectDetails: '{{Subject}} Details',
					subjectInfo: 'Info',
					submissionDetails: 'Submission Details',
					attachments: 'Attachments',
					siteComments: '{{Site}} Comments',
					visitEventTitle: 'Visit/Event Details',
					technicianTitle: 'Technician Details',
					signatureTitleApprove: 'Approve Data Entry',
					signatureTitleUpdate: 'Update Data Entry',
					signatureTitleFirstEntryByErtForSiteSubmission: 'Submit First Entry by Clario',
					signatureTitleSecondEntry: 'Submit Second Entry',
					signatureTitleSecondEntryByErtForSiteSubmission: 'Submit Second Entry',
					cancelButton: 'Cancel',
					rejectButton: 'Reject',
					editButton: 'Edit',
					requestInputButton: 'Request Additional {{Site}} Input',
					backButton: 'Back',
					discrepanciesMessage: 'Please resolve the discrepancies found between first and second data entry, in the highlighted fields.',
					noValue: '[blank]',
					createTechnician: '(Create New Technician...)',
					selectTechnician: 'Select Technician or Create New Technician',
					period: 'Period',
					selectPeriod: 'Select Period',
					selectVisitEvent: 'Select Visit Event',
					selectVisit: 'Select Visit',
					selectOrCreateSubject: 'Select {{Subject}} or Create New {{Subject}}',
					selectSubject: 'Select {{Subject}}',
					selectSite: 'Select {{Site}}',
					selectStudy: 'Select Study',
					rejectTitle: 'Reason of Rejection',
					queryGuideline: 'Query Guideline'
				},
				'moduleLabel': 'Clinical Data Entry',
				'grid': {
					'labels': {
						'studyName': 'Study',
						'siteName': '{{Site}} #',
						'status': 'Status',
						'periodName': 'Period',
						'visitName': 'Visit Name',
						'visitEvent': 'Visit Event',
						createdDate: 'Created (UTC)',
						'modifiedDate': 'Modified (UTC)',
						'createEntryLabel': 'New Data Entry',
						'createEcgEntryLabel': 'New ECG Entry',
						'createPftEntryLabel': 'New PFT Entry',
						'createAuditLabel': 'Audit Report',
						createdByName: 'Created By',
						modifiedByName: 'Modified By',
						dataEntryId: 'ID',
						tatLabel: 'TAT (EST)',
						OpenAllTitle: 'Open (All)',
						OpenMyTasksTitle: 'Open (My Tasks)',
						additionalInfo: 'Additional info',
						transmittalNumber: 'Transmittal #',
						btrGrade: 'Grade',
						siteCertification: 'Site Certification',
						noReports: 'No reports available.',
						additionalInfoSiteComments: 'Site Comments',
						additionalInfoRejectionReason: 'Rejection Reason',
						additionalInfoERTComments: 'Clario Comments',
						additionalInfoDocumentsRequested: 'Documents Requested',
						tatTitle: 'Turnaround Time (TAT) represents the time remaining in the overread workflow. This time will be paused when a site action is pending.'
					}
				},
				"messages": {
					"bothEcgAndPftError": "Study configuration error: Combination of Paper PFT and Paper ECG is not allowed."
				},
				"title-open-entry": "Pulmonary Function Test",
				"title-create-entry": "New Data Entry",
				"title-second-data-entry": "Second Data Entry",
				"title-review-data-entry": "Data Entry Review",
				"title-create-audit-report": "Data Entry Audit Report",
				"measurementsDetails": {
					efforts: {
						label: 'Parameters',
						trialLabel: 'Effort',
						selectUnitPlaceHolder: 'Select a unit',
						atLeastOneEffortMessage: 'Please specify parameters values or enter \'na\' or \'n/a\' for at least one effort.'
					},
					comments: {
						label: '{{Site}} Comments'
					}
				},
				'addAttachment': {
					title: 'Add Attachments',
					chooseFilesButton: 'Choose Files',
					commentsPlaceHolder: 'Please add your comments here...',
					uploadSpirosphereJson: 'Please attach the file generated from SpiroSphere',
					requirement: {
						title: 'Please attach the following documents:',
						slow: {
							calibration: 'Volume Calibration from your equipment',
							line2: 'Slow tracing for each individual effort, including deselected efforts',
							line3: 'Data Table for each individual effort performed, including deselected efforts',
							line4: 'Spirogram (superimposed flow volume curves representing resting tidal breathing prior to maximum inspiration and forced vital capacity maneuvers), for combination tests'

						},
						force: {
							calibration: 'Volume Calibration from your equipment',
							line2: 'Forced tracing for each individual effort, including deselected efforts',
							line3: 'Volume time tracing for Forced measurements',
							line4: 'Data Table for each individual effort performed, including deselected efforts'
						},
						prodlco: {
							calibration: 'Gas and volume calibration from the date of testing for your equipment',
							line2: 'DLCO tracing and gas analysis curve for each DLCO effort collected',
							line3: 'DLCO data table showing all the required parameters for each DLCO effort collected (see DLCO Checklist/Procedure Manual for list of required parameters)',
							line4: 'Start time of each DLCO effort',
							line5: 'Forced spirometry report showing the subject\'s vital capacity (should be collected within 2 weeks of the DLCO test)',
						},
						subdlco: {
							calibration: 'Gas and volume calibration from the date of testing for your equipment',
							line2: 'DLCO tracing and gas analysis curve for each DLCO effort collected',
							line3: 'DLCO data table showing all the required parameters for each DLCO effort collected (see DLCO Checklist/Procedure Manual for list of required parameters)',
							line4: 'Start time of each DLCO effort',
							line5: 'Subject\'s measured hemoglobin value (should be collected within 30 days of the DLCO test)',
							line6: 'Forced spirometry report showing the subject\'s vital capacity (should be collected within 2 weeks of the DLCO test',
						},
						mbw: {
							line1: 'N2 vs. time showing nitrogen concentration before and through the entire washout period including the measure of the IC/ERV/VC maneuver',
							line2: 'Screenshot showing data for each Lung Volumes parameters (Vt, RR, Switch-in Adjustment, Washout time, N2 concentration at the end of the washout, FRC, ERV, RV, VC, IC and TLC)',
							line3: 'Volume-time tracing demonstrating a stable end-expiratory lung volume (FRC), start of washout through end of washout and IC/ERV/VC maneuver',
							calibration: 'Screenshot of the calibration from your equipment'
						},
						bodyPlethysmography: {
							line1: 'Box pressure calibration, volume calibration and time constant results from the date of testing for your equipment.',
							line2: 'TGV loops for each lung volume effort collected',
							line3: 'Volume-time tracing (spirogram) for each lung volume effort collected <ul><li style="list-style: circle;">Each tracing should show all the phases of the lung volume maneuver</li></ul>',
							calibration: 'Data table showing all the required parameters for each lung volume effort collected (see Body Pleth Checklist/Procedure Manual for list of required parameters)'
						},
						ecg: {
							line1: 'A scan of the original ECG tracing',
							line2: 'Digital waveform data from Clario provisioned or Clario pre-approved device'
						}
					},
					errors: {
						requireUploadFiles: 'Must have at least one file',
						saveInProgress: 'Please wait until the file has finished saving',
						saveErrorMessageVirus: 'VIRUS DETECTED! Your data has been received and scanned for virus infection. As this data file is potentially virus infected, Clario will not process it any further. Please contact Customer Care immediately for further advice, preferably by phone',
						saveErrorMessage: 'There was an error saving the file',
						invalidFileTypeMessage: 'You have selected an invalid file type.',
						invalidFileSizeMessage: 'File exceeds the maximum allowed limit of ',
						agreementRequired: 'Please confirm the agreement by selecting the checkbox above.',
						calibrationConfirmationRequired: 'Please confirm that the calibration has been performed by selecting the checkbox above.',

					},
					'addNewAttachmentButton': 'Add new Attachment',
					fileUploadProgress: 'File upload progress:',
					agreementText: '* By selecting this checkbox, you confirm that there is no {{subject}} personal information in the attachment file names and that any {{subject}} personal information within the attachments is redacted.',
					confirmCalibrationText: '* By selecting this checkbox, you confirm that the calibration has been performed and the appropriate documentation is filed with the PFT report.',
				},
				hints: {
					eventAlreadyExists: 'Please be informed that a Data Entry exists for this Subject with the same Visit and Event.',
					visitAlreadyExists: 'Please be informed that a Data Entry exists for this Subject with the same Visit.',


				},
				titleCard: {
					from: 'From:',
					fromEmail: 'From Email:',
					study: 'Study:',
					site: '{{Site}}:',
					submitter: 'Submitter:',
					submissionId: 'Submission ID:',
					subject: '{{Subject}}:'
				}
			},

			"sqf": {
				"gridColumns": {
					"siteId": "{{Site}} #",
					"principalInvestigator": "Principal Investigator",
					"status": "Status",
					"created": "Created",
					"modified": "Modified",
					"submitted": "Submitted"
				},
				list: {
					'newSqf': 'New SQF',
					'savedSqf': 'Saved Site Qualification Forms',
					'submittedSqf': 'Submitted Site Qualification Forms',
					'sqfWizard': 'Site Qualification Wizard'
				},
				help: {
					'studyName': 'Registering {{Site}} for Study:',
					'sqfPrerequisites': 'Information Needed for the Site Qualification Form',
					'mainHeader': 'Before submitting the Site Qualification Form, please be sure to have the following information:',
					'siteId': "{{Site}} ID (Sponsor's identification number for your {{site}} for this study)",
					'piContact': 'Principal Investigator name, street address, phone number & email address',
					'shippingContact': 'Contact person to receive shipments of equipment and supplies (name, street address, phone number & email address)',
					'reportContact': 'Contact person or people to receive reports (name & email address)',
					'notificationContact': 'Contact person or people to receive query notifications (name & email address)',
					'alertContact': 'For COA/ePRO studies, please have the names & email addresses of the primary and secondary alert receivers to receive alert notifications',
				},
				buttons: {
					'saveContact': 'Save Contact',
					'submitSqf': 'Submit SQF',
					'addContact': 'Add Contact',
					'editContact': 'Edit Contact',
					'deleteContact': 'Delete Contact',
					'editRoleAssignment': 'Edit Role Assignment',
					'saveClose': 'Save & Close',
					'continueCreatingSite': 'Continue with {{Site}} Registration',
					'comeBackLater': 'Not ready to start yet'
				},
				messages: {
					'deleteContact': 'This will delete the contact, which cannot be recovered once deleted.',
					'deleteSqf': 'This will delete the SQF, which cannot be recovered once deleted.',
					'submitSqf': 'You are about to submit the Site Qualification Form, which cannot be modified once submitted!',
					'submittedSqf': 'The Site Qualification Form for {{Site}} ID {0} has been submitted successfully. New {{site}} users will be informed by email shortly.',
					'unsavedChanges': 'Unsaved changes if any will be lost, do you want to continue?',
					'singleContactRole': "Only one contact should be selected for the role '{name}'.",
					'atLeastOneContactRole': "At least one contact should be selected for the role '{name}'.",
					'noRoleContact': "Contact '{email}' does not have any role assigned.",
					'emailMatch': 'Confirm Email does not match with Email',
					'reportingEmailMatch': 'Confirm Reporting Email does not match with Reporting Email',
					'equipmentNeededDateInFuture': 'Equipment Needed Date should be in the future.',
					'reportingEmailSameAsEmail': 'Please only enter the reporting email address if it differs from the email address',
					'considerAddingYourself': 'Consider adding yourself as one of the contacts of the {{site}}.',
					'contactNumberField': "Only digits, '-', 'x', space and '+' prefix are allowed here",
					'contactNumberFieldMinLength': "Requires minimum 7 characters",
					'defaultSiteAddressInContact': '{{Site}} Address is used as default address for new contact which you can change as needed.',
					'siteAddressHint': 'To ensure you shipment is received, please include information like Institution name, Building #, Suite # in the Address fields',
				},
				"wizardTitles": {
					"help": 'Help',
					"siteInfo": '{{Site}} Information',
					"contacts": 'Contacts',
					"contact": 'Contact',
					"roles": 'Roles',
					"additionalSiteInfo": 'Additional {{Site}} Information',
					"summary": 'Summary & Confirm',
					"contactInfo": 'Contact Information',
					"contactAddress": 'Contact Address',
					"contactSummary": 'Contact Summary'
				},
				'siteInfo': {
					'siteIdQuestion': 'Do you already know the {{Site}} ID?',
					'siteIdDescription': 'If you know the {{Site}} ID please enter correct one. It has to be unique for this study. i.e. must not already exists (Hint: {siteIdFormatHint}).',
					'siteId': '{{Site}} ID',
					'tempSiteId': 'Temporary {{Site}} ID',
					'language': 'Language(s) spoken at {{site}}',
					'timezone': 'Timezone of {{site}}',
					'institution': 'Institution Name',
					'siteType': '{{Site}} Type'
				},
				'contact': {
					'contact': 'Contact',
					'piInfo': 'Principal Investigator Information',
					'piAddress': 'Principal Investigator Address',
					'firstContactPI': 'First contact will be added as Principal Investigator',
					'needPortalAccess': 'Does this user need portal access?',
					'hasPortalAccess': 'Has portal access',
					'email': 'Email',
					'repeatEmail': 'Confirm Email',
					'title': 'Title',
					'firstName': 'First Name',
					'lastName': 'Last Name',
					'communicationChannel': 'Preferred communication channel',
					'phone': 'Phone',
					'mobile': 'Mobile',
					'fax': 'Fax',
					'portalAccessGranted': 'Yes (Portal access granted)',
					'address1': 'Address 1',
					'address2': 'Address 2',
					'address3': 'Address 3',
					'country': 'Country',
					'city': 'City',
					'state': 'State',
					'postalCode': 'Postal Code',
					'province': 'Province',
					'copyMyContact': 'Copy my contact information',
					'copyMyContactTooltip': 'Copy here my contact information',
					'siteAddress': '{{Site}} Address',
					'copySiteAddress': 'Copy {{site}}\'s address',
					'copySiteAddressTooltip': 'Copy {{Site}} Address into Contact Address',
					'roles': 'Roles',
					'address': 'Address',
					'communication': 'Communication',
					'contactConfirmMessage': 'Are you sure you want to proceed? You have only added one contact for your site. Please ensure that you have added all site users here.'
				},
				'additionalSiteInfo': {
					siteDeviceInfo: '{{Site}} Device Information',
					siteDeviceQuestions: {
						vendor: 'Enter the Vendor of your {{site}} device',
						model: 'Enter the Model of your {{site}} device',
						serialNumber: 'Enter the Serial Number of your {{site}} device',
						softwareVersion: 'Enter the Software Version of your {{site}} device',
						fileType: 'Enter the Expected File Type that outputs from your {{site}} device'
					},
					'siteTransmissionInfo': '{{Site}} Transmission Information',
					'siteDialingInfoSummary': '12 LEAD ECG - For Transmission of ECGs the ECG machine will not transmit if the telephone system is digital. In order to let us determine whether the ECG machine will operate at your location please answer the following questions.',
					'hasAccessToAnalogLine': 'Do you have access to an analog line for ECG transmission? (for example, most fax lines are analog)',
					'hasOutsideAccessLineNumber': 'Does that line need to dial a number to access an outside line? (i.e. a "9" or "0")',
					'outsideAccessLineNumber': 'Outside line number',
					'hasDialTone': 'Does that line have a dial tone?',
					'dialType': 'Dial type?',
					'canDialInternationalTollFree': 'Can you dial international toll-free numbers?',
					'canDialInternationalNumber': 'Can you dial international long-distance numbers?',
					'hasDataTransferRequirements': 'Do you have any specific data transmission/transfer requirements based on any previous experience working with Clario? (If yes, please specify these requirements)',
					'dataTransferRequirements': 'Describe requirements:',
					'siteStartUpInformation': '{{Site}} Start Up Information',
					'equipmentNeededDate': 'What is the date this {{site}} needs ECG Equipment, Supplies or Materials on-site?',
					'equipmentNeededDateSummary': 'PLEASE NOTE: IT TAKES A MINIMUM OF 5 BUSINESS DAYS TO SHIP EQUIPMENT. INTERNATIONAL SHIPMENTS MAY TAKE LONGER AND SOME COUNTRIES WILL NOT SHIP UNTIL A COMMERCIAL INVOICE HAS BEEN AGREED.',
					'siteDetails': '{{Site}} Details',
					'siteDetailsSummary': '{{Site}} Details (A4 format ECG is a 12 lead ECG with a lead 2 rhythm strip on the bottom)',
					'canRecordA4ECG': 'Can the {{Site}} record an A4 format ECG? (Note: ECG must not be mounted on paper by {{Site}})'
				},
				'options': {
					'yes': 'Yes',
					'no': 'No',
					'individual': 'Individual',
					'institution': 'Institution',
					'pulse': 'Pulse',
					'tone': 'Tone',
					'email': 'Email', //preferred communication channel
					'fax': 'Fax'     //preferred communication channel
				},
				'roles': {
					'roleAssignment': 'Role Assignment',
					'roleSummary': 'Please select the {{site}} personnel under each role based on their study responsibilities. Each person can be assigned to as many roles as needed. It is mandatory for someone to be assigned to the roles marked with red asterisks.',
					'pleaseSelectContact': 'Please select contact to assign'
				}
			},
			'study-permissions': {
				gridColumns: {
					"userName": "User Name",
					"userEmail": "User Email",
					"accountCreated": "Account Created",
					"roles": "Roles",
					"role": "Role",
					"lastLogon": "Last Logon",
					"status": "Status",
					"country": "Country",
					"sitePI": "{{Site}} / PI (Country)",
					"constraint": "Constraint",
					"requestDate": "Request Date",
					"requester": "Requester",
					"requesterEmail": "Requester Email",
					"portalAccount": "Portal Account"
				},
				list: {
					'user-roles': 'User Roles',
					'removal-type': 'Removal Type',
					'studyUsers': 'Study Users',
					'countryUsers': 'Country Users',
					'siteUsers': '{{Site}} Users',
					'siteAdministrators': '{{Site}} Administrators',
					'active': 'Active',
					'userRemoval': ' Request User or Role Removal',
					'removeAllRoles': 'Remove user and all user roles',
					'removeSpecificRoles': 'Remove specific roles of the user only',
					'requestRemovalHeader': "Please select all or specific roles for user {0} ({1}) to remove access to the {2}:",
					'removalAlreadyRequested': 'Removal is already requested for the following roles:',
					'confirmApproveTitle': 'Confirm Approving Removal',
					'confirmRejectTitle': 'Confirm Rejecting Removal'
				},
				buttons: {
					'createUser': 'Create {{Site}} User',
					'removeUser': 'Remove User',
					'requestRemoval': 'Request removal',
					'editUser': 'Edit User',
					'performRemoval': 'Perform Removal',
					'rejectRemoval': 'Reject Removal',
					"createSponsorUserWizard": 'Create Sponsor User',
					"sponsorUserInfo": 'Sponsor User Information',
					"sponsorUserRoles": 'Roles'
				},
				messages: {
					approveRemoval: 'It will approve user role removal request, do you want to continue?',
					rejectRemoval: 'It will reject user role removal request, do you want to continue?',
					noUserRoleSelected: 'No user roles selected to request removal',
					selectSite: 'Please select {{site}}',
					contactNumberField: "Only digits, '-', 'x', space and '+' prefix are allowed here",
					createdSiteUser: 'Your request has been submitted successfully. Please note that it may take a few minutes until the changes take effect.',
					createdSponsorUser: 'Your request has been submitted successfully. Please note that it may take a few minutes until the changes take effect.',
					existingShippingContact: 'There is already a user with the role Shipping Contact created for your {{site}}, creating a new one will deactivate the existing Shipping Contact.',
					hasSiteSpecificPermissions: 'This action cannot be performed for all assigned {{site}}s due to your {{site}}-specific permissions.',
					hasSiteSpecificRoles: 'This action cannot be performed for all assigned user roles due to your {{site}}-specific permissions.',
					'noSiteRoles': "No {{Site}} roles available, please call Customer Care.",
					piRemovalWarning: 'The Principal Investigator cannot be removed. Please contact Customer Care.',
					piRoleRemovalWarning: 'The Principal Investigator role cannot be removed. Please contact Customer Care.',
					shippingContactRemovalWarning: 'The Shipping Contact cannot be removed unless it is replaced with another user. Please contact Customer Care.',
					shippingContactRoleRemovalWarning: 'The Shipping Contact role cannot be removed unless it is replaced with another user. Please contact Customer Care.',
					shippingContactAddingWarning: 'The Shipping Contact role can only be assigned to one user. By adding this role, the current user will replace the previous user as the Shipping Contact.',
					singleRoleRemovalWarning: 'The user should have at least one role assigned.',
					contactNumberFieldMinLength: "Requires minimum 7 characters",
					'emailMatch': 'Confirm Email does not match with Email',
					'reportingEmailMatch': 'Confirm Reporting Email does not match with Reporting Email',
					'reportingEmailSameAsEmail': 'Please only enter the reporting email address if it differs from the email address',
					changeRolesDelayMessage: 'The roles have been updated successfully, but it can take a few minutes until the change will be visible in Clario Portal.',
					deleteSiteUserDelayMessage: 'The user has been deleted successfully, but it can take a few minutes until the change will be visible in Clario Portal.',
					'userDeleteConfirmation': 'Are you sure you want to remove this user?',
					editUserLegendMsg: 'At least one role is required',
					userAlreadyExists: 'User already exists. Please use the "Edit Site User Roles" feature to update permissions, or create a new user with a different email address.',
					sponsorUserAlreadyExists: 'User already exists. Please create a new user with a different email address.',
					noRolesMessage: 'There are no roles available in this study. Please check with your Project Manager for adding new Sponsor users. Click OK to close the dialog.',
					noRolesAvailableMessage: 'There are no available receiver roles in the current study',
					sponsorConfirmationMessage: 'I confirm that this user should have the above access, role and permissions indicated above.',
					sponsorDisclaimerMessage: 'Please review the information below to ensure accuracy.'					
				},
				wizardTitles: {
					"createUserWizard": 'Create {{Site}} User',
					"userInfo": '{{Site}} & User Information',
					"userAddressRoles": 'User Address & Roles',
					"confirm": 'Confirm',
					"userAddress": 'User Address',
					"siteRoleAssignment": 'Role Assignment',
					"signature": 'Signature',
					"createSponsorUserWizard": 'Create Sponsor User',
					"sponsorUserInfo": 'Sponsor User Information',
					"sponsorUserConfirmation": 'Confirmation',
					"noRoles": 'No roles available in the current study',
				},
				contact: {
					'needPortalAccess': 'Does this user need portal access?',
					'email': 'Email',
					'repeatEmail': 'Confirm Email',
					'title': 'Title',
					'firstName': 'First Name',
					'lastName': 'Last Name',
					'communicationChannel': 'Preferred communication channel',
					'phone': 'Phone',
					'mobile': 'Mobile',
					'fax': 'Fax',
					'hasReportingEmail': 'I wish my reports go to a different address',
					'reportingEmail': 'Reporting Email',
					'repeatReportingEmail': 'Confirm Reporting Email',
					'address1': 'Address 1',
					'address2': 'Address 2',
					'address3': 'Address 3',
					'country': 'Country',
					'city': 'City',
					'state': 'State',
					'postalCode': 'Postal Code',
					'province': 'Province',
					'siteId': '{{Site}}',
					'roles': 'Roles',
					'accessTypes': 'Access Level',
					'accessLevel': 'Access level',
					'sponsorRoles': 'Sponsor Roles',
					'receiverRoles': 'Receiver Roles',
					'reportingNeeds': 'Additional Reporting Needs',
					'confirmation': 'I confirm that this user should have the access, role and permissions indicating above'
				},
				options: {
					'email': 'Email', //preferred communication channel
					'fax': 'Fax',     //preferred communication channel
					'yes': 'Yes',
					'no': 'No'
				}
			},
			'enrollment-graph': {
				'randomized': 'Randomized',
				'enrolled': 'Enrolled',
				'included': 'Included',
				'created': 'Created',
				'allsites': 'All {{Site}}s',
				'assigned-sites': 'Assigned {{Site}}s',
				'totals': 'Totals',
				'treatment': 'TreatmentPeriodCompleted',
				'terminated': 'EarlyTerminated',
				'finished': 'Finished',
				'excluded': 'Excluded',
				'activated': 'Activated',
				'subjects': '{{Subject}}s',
				'time': 'Time'
			},
			'spiro-viewer': {
				errorCodes: 'Error Codes',
				errorCode: 'Error Code',
				description: 'Description',
				dataCorrection: 'Data Correction',
				notYetAssessed: 'Not yet assessed',
				btrStatements: 'BTR Statements',
				qcStatements: 'QC Statements',
				operatorComments: 'Operator Comments',
				overreadNotes: 'Overread Notes',
				showErrorCodes: 'Show info about Error Codes',
				legend: 'Legend',
				bestValue: 'Best value',
				patientInfo: '{{Subject}} Information',
				gradeInfo: 'Grade Information',
				btrGrade: 'BTR Grade',
				qcGrade: 'QC Grade',
				proficiencyGrade: 'Proficiency Test',
				largeView: 'Large view',
				'graph': {
					'slow': {
						'x-axis-title': 'Time [s]',
						'y-axis-title': 'Vol [L]'
					},
					'forced': {
						'x-axis-title': 'Volume [L]',
						'y-axis-title': 'Flow [L/s]'
					},
					'forcedTiff': {
						'x-axis-title': 'Time [s]',
						'y-axis-title': 'Volume [L]'
					},
					'errors': {
						'noCurves': 'No curve data available'
					}
				}
			},
			ecgViewer: {
				idm: 'IDM',
				lead: 'LEAD',
				value: 'VALUE',
				studyInfo: 'Study Information',
				studyInfoCardTitle: 'Study Information',
				annotations: 'Annotations',
				studyName: 'Protocol Name',
				siteName: 'Site ID',
				piName: 'PI Name',
				visitName: 'Visit Name',
				eventName: 'Event Name',
				collectionDate: 'Col Date/Time',
				eventDate: 'Event Date/Time',
				transmittal: 'Transmittal #',
				errors: {
					ie10notSupported: 'The ECG Waveform Viewer is not supported in Internet Explorer 10. \
Please use Internet Explorer 11, Microsoft Edge, Chrome or Firefox to see the waveform.'
				}
			},
			'page-title': {
				'site': 'Clario Portal',
				app: {
					home: {
						'dashboard': 'My Portal',
						'iClinicaDashboard': 'iClinica Dashboard',
						'studyRoot': '',
						'home.planned': 'My Portal',
						'home.recent': 'My Portal',
						'clinicalData': 'Clinical Data',
						'clinicalData.countries': 'Clinical Data - Countries',
						'clinicalData.sites': 'Clinical Data - {{Site}}s',
						'clinicalData.subjects': 'Clinical Data - {{Subject}}s',
						'clinicalData.statusForms': 'Clinical Data - Data Status Forms',
						'clinicalData.sourceTracings': 'Clinical Data - Source ECG Tracing',
						'clinicalData.holterReports': 'Clinical Data - Holter Reports',
						'clinicalData.abpmReports': 'Clinical Data - ABPM Reports',
						'clinicalData.visits': 'Clinical Data - Visits',
						'clinicalData.ecoa': 'Clinical Data - COA (Subject)',
						'clinicalData.ecg': 'Clinical Data - ECG',
						'clinicalData.spirometry': 'Clinical Data - Spirometry Viewer',
						'clinicalData.spirometry.btrAfter': 'Clinical Data - Spirometry After BTR',
						'clinicalData.spirometry.btrBefore': 'Clinical Data - Spirometry Before BTR',
						'tickets': 'Data Management',
						'ticketdetails': 'Data Management',
						'test': '',
						'admin': 'Admin',
						'admin.studytracking': 'Admin - Study Tracking',
						'admin.moduletracking': 'Admin - Module Tracking',
						'admin.usertracking': 'Admin - User Tracking',
						'admin.userlogintracking': 'Admin - User Login Tracking',
						'admin.studyconfiguration': 'Admin - Study Configuration',
						'logistics': 'Devices & Supplies',
						'logistics.supplyorder': 'Devices & Supplies - Supply Order',
						'logistics.shipmenttracking': 'Devices & Supplies - Shipment Tracking',
						'logistics.returnrequests': 'Devices & Supplies - Return Requests',
						'announcements': 'Announcements',
						'refMaterials': 'Reference Materials',
						'sqfList': 'Site Qualification',
						'sqfList.saved': 'Site Qualification - Saved',
						'sqfList.submitted': 'Site Qualification - Submitted',
						'studyPermissions': 'Study Permissions',
						'studyPermissions.studyUsers': 'Study Permissions - Study Users',
						'studyPermissions.siteUsers': 'Study Permissions - {{Site}} Users',
						'studyPermissions.countryUsers': 'Study Permissions - Country Users',
						'epx': 'EPX',
						'eci': 'ECI',
						'loginHelp': 'Login Help',
						'resend': 'Login',
						'resetPassword': 'Reset Password',
						'register': 'Register',
						'confirm': 'Confirm',
						'confirmTokenPath': 'Confirm Token Path',
						'confirmLegacy': 'Confirm Legacy',
						'reports': 'Reports',
						'reports.createreport': 'Reports - Create Report',
						'reports.myreports': 'Reports - My Reports',
						'reports.training': 'Reports - Training',
						'reports.ecgdataexport': 'Reports - ECG Data Export'
					},
					help: {
						title: 'Help'
					}
				},
				auth: {
					'login': 'Login',
					'confirm':'Confirm Email',
					'confirmTokenPath':'Confirm Email',
				},
				help: {
					'contact': 'Contact Customer Care'
				}
			}

		});

		$translateProvider.translations('de', {});
		if (appConfig.preferredLanguage) {
			// Tell the module what language to use by default
			$translateProvider.preferredLanguage(appConfig.preferredLanguage);
		} else {
			// detects browser language, window.navigator.userLanguage is needed for IE<11 and window.navigator.language for all other browsers
			var language = window.navigator.userLanguage || window.navigator.language;
			$translateProvider.preferredLanguage(language);
		}

		// fallbackLanguage means that if in concrete language some translations are missing, then fallbackLanguage translations will be used
		$translateProvider.fallbackLanguage('en');
	}

})();
