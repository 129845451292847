/*
 * uploadWidgetModule
 * */

import uploadWidgetComponent from './uploadWidget.component';

const uploadWidgetModule = angular.module('app.components.dashboard.uploadWidget', [])
	.component('uploadWidget', uploadWidgetComponent)
	.name;

export default uploadWidgetModule;
