/*
 * SideNavigationService
 * */

'use strict';
export default class sideNavigationService {
	constructor($log, appI18n, configurationService, appConfig, appSession, $state, $window) {
		'ngInject';
		const routes = appConfig.routes;
		const trackId = appConfig.trackId;

		this.$log = $log.getInstance('SideNavigation.service', 'color:green');
		this.appI18n = appI18n;
		this.configurationService = configurationService;
		this.appSession = appSession;
		this.appConfig = appConfig;
		this.$state = $state;
		this.$window = $window;

		this.navConfig = {
			Dashboard: {
				trackId: trackId.dashboard,
				route: routes.dashboard,
				routeRoot: routes.dashboard,
				iconClass: 'es-icon-home'
			},
			ClinicalData: {
				route: routes.clData,
				routeRoot: routes.clData,
				iconClass: 'es-icon-clinical-data',
				createSubNav: (data) => {
					if (!data.showOnlyClinicalDataRoot) {
						return [
							{
								trackId: trackId.clinicalDataCountry,
								trackLabel: this.appConfig.trackLabel.levelStudy,
								label: 'clinicaldata.countries-title',
								route: `${routes.clDataCountries}({country: '',site: '', subject: '', eproSubject: '',
									visit: '', event: '', tdt: '', mdt: ''})`
							}, {
								trackId: trackId.clinicalDataSite,
								trackLabel: this.appConfig.trackLabel.levelStudy,
								label: 'clinicaldata.sites-title',
								route: `${routes.clDataSites}({country: '',site: '', subject: '', eproSubject: '',
								visit: '', event: '', tdt: '', mdt: ''})`
							}, {
								trackId: trackId.clinicalDataSubject,
								trackLabel: this.appConfig.trackLabel.levelStudy,
								label: 'clinicaldata.subjects-title',
								route: `${routes.clDataSubjects}({country: '',site: '', subject: '', eproSubject: '',
									visit: '', event: '', tdt: '', mdt: ''})`
							}
						];
					}
					return [];
				},
				onMenuGenerationCompleted: (data, item) => {
					item.trackId = data.showOnlyClinicalDataRoot
						? trackId.clinicalDataSingle
						: trackId.clinicalData;
					item.route = `${routes.clData}({country: ${self.clinicalDataCountryId},
					site: ${self.clinicalDataSiteId}, subject: '', eproSubject: '', visit: '', event: '', tdt: '',
					mdt: ''})`;
				}
			},
			iCardiacDashboard: {
				trackId: trackId.iClinicaDashboard,
				route: routes.iClinicaDashboard,
				routeRoot: routes.iClinicaDashboard,
				iconClass: 'es-icon-clinical-data'
			},
			DataManagement: {
				trackId: trackId.dataManagement,
				route: routes.tickets,
				routeRoot: routes.tickets,
				iconClass: 'es-icon-data-management'
			},
			ReportsOds: {
				trackId: trackId.reportsOds,
				route: null,
				iconClass: 'es-icon-bar-chart',
				onClick: () => {
					let redirectUri = this.appConfig.ods.landingPage;
					const study = this.appSession.getStudy();

					if (!redirectUri.endsWith('/')) {
						redirectUri += '/';
					}
					const newUrl = `${redirectUri}?studyId=${study.id}&acctId=${study.number}&studyName=${study.name}`;

					this.$window.open(newUrl, '_blank');

				}
			},
			Reports: {
				trackId: trackId.reports,
				route: routes.createReport,
				routeRoot: routes.reports.root,
				iconClass: 'es-icon-reports',
				createSubNav: (data) => {
					const subItems = [
						{
							trackId: trackId.reportsCreateReport,
							label: 'reports.create-report.title',
							route: routes.createReport
						}, {
							trackId: trackId.reportsMyReports,
							label: 'reports.my-reports.title',
							route: routes.myReports
						}
					];

					if (data.showReportReview) {
						subItems.push({
							trackId: trackId.reportsReportReview,
							label: 'reports.report-review.title',
							route: routes.reportReview
						});
					}

					if (data.showLogiReports) {
						subItems.push({
							trackId: trackId.reportsLogi,
							label: 'reports.logi.title',
							route: routes.logiReports
						});
					}

					if (data.showTrainingDocuments) {
						subItems.push({
							trackId: trackId.reportsTraining,
							label: 'reports.training-document.main-title',
							route: routes.reportsTraining
						});
					}

					if (data.showEcgDataExport) {
						subItems.push({
							trackId: trackId.reportsEcgDataExport,
							label: 'reports.ecg-export.main-title',
							route: routes.ecgDataExport
						});
					}

					return subItems;
				}
			},
			Data_Analytics: { // eslint-disable-line camelcase
				trackId: trackId.dataAnalytics,
				route: null,
				iconClass: 'es-icon-cloud-analytics',
				onClick: () => {
					const url = this.$state.href(routes.eci);

					this.$window.open(url, '_blank');
				}
			},
			StudyPermissions: {
				trackId: trackId.studyPermissions,
				route: routes.studyPermissions,
				routeRoot: routes.studyPermissions,
				iconClass: 'es-icon-user-management-study-permission'
			},
			DeviceManagement: {
				trackId: trackId.deviceManagement,
				route: routes.deviceManagement.root,
				routeRoot: routes.deviceManagement.root,
				iconClass: 'es-icon-mobile-phone',
				createSubNav: (data) => {
					const subItems = [];

					if (data.hasDevices) {
						const conf = data.studyConfig;

						if (conf.hasFlowScreen) {
							subItems.push({
								trackId: trackId.deviceManagementFlowscreen,
								label: 'deviceManagement.devices.flowscreen',
								route: routes.deviceManagement.flowscreen
							});
						}

						if (conf.hasEasyOnePro) {
							subItems.push({
								trackId: trackId.deviceManagementEasyOnePro,
								label: 'deviceManagement.devices.easyOnePro',
								route: routes.deviceManagement.easyOnePro
							});
						}

						if (conf.hasMasterscope) {
							subItems.push({
								trackId: trackId.deviceManagementMasterscope,
								label: 'deviceManagement.devices.masterscope',
								route: routes.deviceManagement.masterscope
							});
						}

						if (conf.hasSitePro) {
							subItems.push({
								trackId: trackId.deviceManagementSitePro,
								label: 'deviceManagement.devices.sitepro',
								route: routes.deviceManagement.sitepro
							});
						}

						if (conf.hasSpiroSphere) {
							subItems.push({
								trackId: trackId.deviceManagementSpiroSphere,
								label: 'deviceManagement.devices.spiroSphere',
								route: routes.deviceManagement.spiroSphere
							});
						}

						if (conf.hasFeNo) {
							subItems.push({
								trackId: trackId.deviceManagementFeno,
								label: 'deviceManagement.devices.feno',
								route: routes.deviceManagement.feno
							});
						}

						if (conf.hasCgm) {
							subItems.push({
								trackId: trackId.deviceManagementCgm,
								label: 'deviceManagement.devices.cgm',
								route: routes.deviceManagement.cgm
							});
						}

						if (conf.hasCoaAm) {
							subItems.push({
								trackId: trackId.deviceManagementAM3,
								label: 'deviceManagement.devices.am3',
								route: routes.deviceManagement.am3
							});
						}

						if (conf.hasDiaryPro) {
							subItems.push({
								trackId: trackId.deviceManagementDiaryPro,
								label: 'deviceManagement.devices.diarypro',
								route: routes.deviceManagement.diarypro
							});
						}

						if (conf.isiSpiroStudy) {
							subItems.push({
								trackId: trackId.deviceManagementISpiro,
								label: 'deviceManagement.devices.iSpiro',
								route: routes.deviceManagement.iSpiro
							});
						}
						
						if (conf.hasClarioApp) {
							subItems.push({
								trackId: trackId.deviceManagementClarioApp,
								label: 'deviceManagement.devices.clarioApp',
								route: routes.deviceManagement.clarioApp
							});
						}

						if (conf.hasVivalink) {
							subItems.push({
								trackId: trackId.deviceManagementVivalink,
								label: 'deviceManagement.devices.vivalink',
								route: routes.deviceManagement.vivalink
							});
						}

						if (conf.hasCough) {
							subItems.push({
								trackId: trackId.deviceManagementCough,
								label: 'deviceManagement.devices.cough',
								route: routes.deviceManagement.cough
							});
						}
					}

					return subItems;
				}
			},
			Logistics: {
				trackId: trackId.logistics,
				route: routes.supplyOrder,
				routeRoot: routes.logistics,
				iconClass: 'es-icon-supply-ordering',
				createSubNav: () => {
					return [
						{
							trackId: trackId.logisticsSupplyOrder,
							label: 'logistics.supply-ordering.title',
							route: routes.supplyOrder
						}, {
							trackId: trackId.logisticsShipmentTracking,
							label: 'logistics.shipment-tracking.title',
							route: routes.shipmentTracking
						}, {
							trackId: trackId.logisticsReturnRequests,
							label: 'logistics.return-requests.title',
							route: routes.returnRequests
						}

					];
				}
			},
			LogisticsMicroportal: {
				trackId: trackId.logistics,
				routeOptions: { reload: true },
				route: routes.logisticsMicroportal.supplyOrder,
				routeRoot: routes.logisticsMicroportal.root,
				iconClass: 'es-icon-supply-ordering',
				createSubNav: () => {
					return [
						{
							trackId: trackId.logisticsSupplyOrder,
							label: 'logistics.supply-ordering.title',
							routeOptions: { reload: true },
							route: routes.logisticsMicroportal.supplyOrder
						}, {
							trackId: trackId.logisticsShipmentTracking,
							label: 'logistics.shipment-tracking.title',
							routeOptions: { reload: true },
							route: routes.logisticsMicroportal.shipmentTracking
						}, {
							trackId: trackId.logisticsReturnRequests,
							label: 'logistics.return-requests.title',
							routeOptions: { reload: true },
							route: routes.logisticsMicroportal.returnRequests
						}

					];
				}
			},
			Announcements: {
				trackId: trackId.announcements,
				route: routes.announcements,
				routeRoot: routes.announcements,
				iconClass: 'es-icon-bell'
			},
			Admin: {
				trackId: trackId.admin,
				route: routes.studyTracking,
				routeRoot: routes.admin,
				iconClass: 'es-icon-portal-administration',
				createSubNav: (data) => {
					const subItems = [
						{
							trackId: trackId.adminStudyTracking,
							label: 'admin.study-tracking-title',
							route: routes.studyTracking
						}, {
							trackId: trackId.adminModuleTracking,
							label: 'admin.module-tracking-title',
							route: routes.moduleTracking
						}, {
							trackId: trackId.adminUserTracking,
							label: 'admin.user-tracking-title',
							route: routes.userTracking
						}, {
							trackId: trackId.adminUserLoginTracking,
							label: 'admin.user-login-tracking-title',
							route: routes.userLoginTracking
						}

					];

					if (data.isInternal) {
						subItems.push({
							trackId: trackId.adminStudyConfiguration,
							label: 'admin.study-configuration-title',
							route: routes.studyConfiguration
						});
					}

					return subItems;
				}
			},
			StudyConfig: {
				trackId: trackId.adminStudyConfigurationSingle,
				route: routes.studyConfiguration,
				routeRoot: routes.studyConfiguration,
				iconClass: 'es-icon-portal-administration'
			},
			Sqf: {
				trackId: trackId.sqf,
				route: routes.sqfListSaved,
				routeRoot: routes.sqfList,
				iconClass: 'es-icon-site-qualification-form'
			},
			EPX_Portal: { // eslint-disable-line camelcase
				trackId: trackId.epxPortal,
				iconClass: 'es-icon-epx',
				onClick: () => {
					const study = this.appSession.getStudy();
					const newUrl = this.$state.href(routes.epx, { study: study.id, mode: study.studyModeId },
						{ absolute: true });

					this.$window.open(newUrl, '_blank');
				}
			},
			VCS_Portal: { // eslint-disable-line camelcase
				trackId: trackId.vcsPortal,
				disabled: true
			},
			ReferenceMaterials: {
				trackId: trackId.referenceMaterials,
				route: routes.refMaterials,
				routeRoot: routes.refMaterials,
				iconClass: 'es-icon-reference-material'
			},
			My_Downloads: { // eslint-disable-line camelcase
				trackId: trackId.myDownloads,
				route: routes.myDownloads,
				routeRoot: routes.myDownloads,
				iconClass: 'es-icon-download'
			},
			CardiacUpload: {
				trackId: trackId.cardiacUpload,
				route: routes.cardiacUpload,
				routeRoot: routes.cardiacUpload,
				iconClass: 'es-icon-download flipped',
				routeOptions: { reload: true }
			},
			ClinicalDataEntry: {
				trackId: trackId.dataEntry,
				route: routes.cdePftList,
				routeRoot: routes.cdePftList,
				iconClass: 'es-icon-function-scoring-results',
				createSubNav: (data) => {
					return this.generateCDENavSubItems(data);
				},
				onMenuGenerationCompleted: (data, item) => {
					this.onCDEMenuItemGenerationCompleted(data, item);
				}
			}
		};
	}

	updateReportReviewBadge(diff) {
		const newNumber = this.reportReviewNavItem.badgeNumber - diff;

		this.setReportConfirmationBadgeNumber(newNumber);
		this.configurationService.clearUserStudySettings();
	}

	getNavigationItems() {
		this.contactusTitle = this.appI18n.translateImmediate('help.contactus.title');

		return this.configurationService.getUserStudySettings().then((response) => {
			const data = response.data, sitemaps = data.sitemaps, cdeEntries = this.getMultipleCDEntries(sitemaps);

			this.clinicalDataCountryId = data.countryId;
			this.clinicalDataSiteId = data.siteId;

			if (cdeEntries.length > 1) {
				data.multiplePaperEntries = cdeEntries;
			}

			return this.generateNavItems(sitemaps, data);

		}, (data, status) => {
			// TODO: pass through the error to show notification when the notification component is ready.
			this.$log.error('Error getting navigation items, data: "{0}", status: "{1}"', data, status);
		});
	}

	generateNavItems(sitemaps, data) {
		this.navItems = [];

		angular.forEach(this.navConfig, (item, key) => {
			if (!item.disabled) {
				const objs = sitemaps.filter(s => s.name === key);

				if (objs) {
					const obj = objs[0];

					if (obj) {
						const menu = {
							name: obj.name,
							label: obj.label,
							requiresExpert: obj.requiresExpert,
							routeOptions: item.routeOptions,
							route: item.route || '',
							routeRoot: item.routeRoot,
							trackId: item.trackId,
							iconClass: item.iconClass || '',
							submenus: item.createSubNav && item.createSubNav(data),
							onClick: item.onClick
						};

						this.navItems.push(menu);

						if (item.onMenuGenerationCompleted) {
							item.onMenuGenerationCompleted(data, menu);
						}

						if (+obj.id === +this.appConfig.siteMapModuleIds.reports) {
							this.setReportConfirmationBadge(menu);
						}
					}
				}
			}
		});

		return this.navItems;
	}

	setReportConfirmationBadge(navItem) {
		this.reportReviewNavItem = navItem;
		this.configurationService.getUserStudySettings().then((response) => {
			if (response.data.studyConfig.hasReportConfirmation) {
				this.setReportConfirmationBadgeNumber(
					response.data.numberOfReportsToConfirm,
				);
			}
		});
	}

	setReportConfirmationBadgeNumber(number) {
		const navItem = this.reportReviewNavItem;

		navItem.badgeNumber = number;
		for (let i = 0; i < navItem.submenus.length; ++i) {
			if (navItem.submenus[i].trackId === this.appConfig.trackId.reportsReportReview) {
				navItem.submenus[i].badgeNumber = number;
				break;
			}
		}
		if (number > 0) {
			navItem.clickRoute = this.appConfig.routes.reportReview;
			navItem.trackId = this.appConfig.routes.reportReview;
		} else {
			navItem.clickRoute = this.appConfig.routes.createReport;
			navItem.trackId = this.appConfig.routes.createReport;
		}
		// ui-sref does not support dynamic update, instead go to state in onClick
		navItem.route = null;
		navItem.onClick = () => {
			if (!navItem.suppressClick) {
				this.$state.go(navItem.clickRoute);
			}
		};
	}

	generateCDENavSubItems(configData) {
		const self = this;
		let result = [];
		const entries = configData.multiplePaperEntries;

		if (entries && entries.length > 0) {
			result = entries.map((subm) => {
				const item = this.navConfig[subm.name];
				const trackId = self.trackId.dataEntryPaperPft;

				return {
					trackId,
					label: subm.label,
					route: item.route
				};
			});

		}
		result = this.sortPaperItems(result);
		return result;
	}

	sortPaperItems(items) {
		let result = [];

		if (items && items.length > 0) {
			result = items.reverse();
		}

		return result;
	}

	onCDEMenuItemGenerationCompleted(configData, navItem) {
		let entries = configData.multiplePaperEntries;

		if (entries && entries.length > 0) {
			navItem.label = this.appI18n.translateImmediate('app.menu.paper-entry-title');
			entries.shift();
			entries.forEach((paperEntry) => {
				this.navConfig[paperEntry.name].disabled = true;
			});
			entries = [];
		}
	}

	getMultipleCDEntries(sitemaps) {
		return sitemaps.filter((item) => {
			return item.name === 'ClinicalDataEntry';
		});
	}
}
