const { data } = require("jquery");

(function () {

	'use strict';

	angular
		.module('portalApp')
		.service('clinicalDataEntryDataService', ClinicalDataEntryDataService);

	/**
	 * @ngInject
	 */
	function ClinicalDataEntryDataService(appConfig, $http, $log, appSession, appI18n) {
		$log = $log.getInstance("ClinicalDataEntryDataService", "color:blue");
		$log.debug("loaded");

		var dataModel = {};
		var requestDocument = { isSet: false };
		var forceList = [];
		var dlcoList = [];

		var service = {
			getUpdateEntryData: getUpdateEntryData,
			getNewEntryData: getNewEntryData,
			getDataModel: getDataModel,
			setDataModel: setDataModel,
			clearDataModel: clearDataModel,
			setDataField: setDataField,
			getDataField: getDataField,
			getDocumentList,
			resetDataModel,
			restoreDataModel
		};

		return service;

		function log(name, url) {
			$log.debug(`clinicalDataEntryService.${name} with url: ${url}`);
		}

		function getNewEntryData(model) {
			var data = getNewEntryDataJson(model);
			var dataEntry = JSON.stringify(data);
			var result = {
				studyId: data.context.studyId,
				studyModeId: data.context.studyModeId,
				siteId: data.context.internalSiteId,
				sponsorSiteId: data.context.sponsorSiteId,
				SubjectId: data.subject.id,
				sponsorSubjectId: data.subject.sponsorSubjectId,
				visitId: data.eventData.visitId,
				periodId: data.eventData.periodId,
				periodName: data.eventData.periodName,
				visitName: data.eventData.visitName,
				visitScheduleTaskId: data.eventData.visitScheduleTaskId,
				eventName: data.eventData.eventName,
				dataType: data.eventData.tdtId,
				data: dataEntry
			};

			return result;
		}

		function resetDataModel(data) {
			dataModel = data;
			dataModel.isStateReset = true;
		}

		function setDataModel(data) {
			dataModel.context = data.context;
			dataModel.subject = data.subject;
			dataModel.visitEvent = data.visitEvent;
			dataModel.efforts = data.efforts;
			dataModel.comments = !!data.eventData ? data.eventData.comments : '';
			dataModel.attachments = data.attachments;
			return dataModel;
		}

		function getDataModel() {
			return dataModel;
		}

		function restoreDataModel(model) {
			dataModel = model;
		}

		function clearDataModel() {
			dataModel = {};
		}
		function setDataField(fieldName, fieldValue) {
			dataModel[fieldName] = fieldValue;
		}

		function getDataField(fieldName) {
			return dataModel[fieldName];
		}

		function getNewEntryDataJson(model) {
			var result = {
				'version': '1.0'
			};
			result.context = getContext(model);
			result.subject = getSubject(model);
			result.subjectCreateDate = getsubjectCreateDate(model);
			setEventData(result, model);

			return result;
		}
		function setEventData(result, model) {
			result.visitEvent = getVisitEvent(model);
			result.efforts = getEfforts(model);
			result.attachments = getAttachments(model);

			result.eventData = getEventData(model);
			result.eventData.attachments = result.attachments;
			result.eventData.efforts = result.efforts;
			result.eventData.comments = getComments(model);
		}

		function getVisitEvent(model) {
			return model.visitEvent || {};
		}

		function getEfforts(model) {
			return model.efforts || {};
		}

		function getContext(model) {
			return model.context || {};
		}

		function getComments(model) {
			return model.comments || '';
		}

		function getSubject(model) {
			return model.subject || {};
		}

		function getEventData(model) {
			var event = model.visitEvent || {};
			return {
				tdtId: event.testDataTypeId,
				eventId: event.eventId,
				visitId: event.visitId,
				periodId: event.periodId,
				periodName: event.periodName,
				periodAbbreviation: event.periodAbbreviation,
				type: event.testDataName,
				visitName: event.visitName,
				visitAbbreviation: event.visitAbbreviation,
				eventName: event.eventName,
				taskName: event.visitTaskOid,
				eventDate: getFormattedDate(event.eventDate),
				eventTime: getFormattedEventTime(event.eventTime),
				technician: event.technician,
				visitScheduleTaskId: event.visitScheduleTaskId,
			};
		}

		function getFormattedDate(input) {
			return moment(input, 'DD-MMM-YYYY').format('YYYY-MM-DD');
		}

		function getFormattedEventTime(time) {
			// provide time in HH:mm format when we have it in HHmm format
			if (time.length === 4 && time.indexOf(':') < 0) {
				return time.substring(0, 2) + ':' + time.substring(2);
			}
			return time;
		}

		function getAttachments(model) {
			return model.attachments;
		}

		function getsubjectCreateDate(model) {
			return new Date().toISOString();
		}

		function getUpdateEntryData(saved, model) {

			var savedData = JSON.parse(saved.data);
			var data = getUpdateEntryDataJson(savedData, model);
			var dataEntry = JSON.stringify(data);

			var result = {
				id: saved.id,
				studyId: data.context.studyId,
				studyModeId: data.context.studyModeId,
				visitId: data.eventData.visitId,
				periodId: data.eventData.periodId,
				periodName: data.eventData.periodName,
				visitName: data.eventData.visitName,
				eventName: data.eventData.eventName,
				dataType: data.eventData.tdtId,
				visitScheduleTaskId: data.eventData.visitScheduleTaskId,
				data: dataEntry
			};
			return result;
		}
		function getUpdateEntryDataJson(saved, model) {
			setEventData(saved, model);
			return saved;
		}

		function getDocumentList(testDataTypeId, cdeMode, hasJsonFileUpload, hideCalibrationReport, hasConfiguredParameters, hasHb) {
			// if the JSON file upload feature is enabled, no other documents are required.
			if (hasJsonFileUpload) {
				return [];
			}
			let isProficiency = dataModel.subject.isProficiency;
			let documents = getDocumentListByTestType(testDataTypeId, hideCalibrationReport, hasHb, isProficiency);

			if (!hasConfiguredParameters && cdeMode === appConfig.cdeMode.Ecg) {
				documents.pop();
			}
			return documents;
		}

		function getDocumentListByTestType(testDataType, hideCalibrationReport, hasHb = false, isProficiency = false) {
			var result = [];
			if (!requestDocument.isSet) {
				setRequestDocument(hasHb);
			}
			if (testDataType == appConfig.testDataType.slowSpirometry) {
				result = requestDocument.slow;
			} else if (testDataType == appConfig.testDataType.forcedSpirometry) {
				result = requestDocument.force;
			}
			else if (testDataType == appConfig.testDataType.Dlco && isProficiency) {
				result = requestDocument.prodlco;
			}
			else if (testDataType == appConfig.testDataType.Dlco) {
				result = requestDocument.subdlco;
			}
			else if (testDataType == appConfig.testDataType.bodyPlethysmography) {
				result = requestDocument.bodyPlethysmography;
			}
			else if (testDataType == appConfig.testDataType.Mbw) {
				result = requestDocument.mbw;
			}
			else if (testDataType == appConfig.testDataType.ecg ||
				testDataType == appConfig.testDataType.extractionEcg ||
				testDataType == appConfig.testDataType.holter
			) {

				result = requestDocument.ecg;
			}

			if (hideCalibrationReport) {
				var filtered = [];
				for (var i = 0; i < result.length; ++i) {
					if (!result[i].isCalibration) {
						filtered.push(result[i]);
					}
				}
				return filtered;
			}
			return result;

		}
		function setRequestDocument(hasHb) {
			requestDocument.slow = [];
			requestDocument.slow.push({ id: 1, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.slow.calibration'), isCalibration: true });
			requestDocument.slow.push({ id: 2, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.slow.line2') });
			requestDocument.slow.push({ id: 3, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.slow.line3') });
			requestDocument.slow.push({ id: 4, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.slow.line4') });

			requestDocument.force = [];
			requestDocument.force.push({ id: 1, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.force.calibration'), isCalibration: true });
			requestDocument.force.push({ id: 2, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.force.line2') });
			requestDocument.force.push({ id: 3, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.force.line3') });
			requestDocument.force.push({ id: 4, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.force.line4') });

			requestDocument.prodlco = [];
			requestDocument.prodlco.push({ id: 1, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.prodlco.calibration'), isCalibration: true });
			requestDocument.prodlco.push({ id: 2, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.prodlco.line2') });
			requestDocument.prodlco.push({ id: 3, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.prodlco.line3') });
			requestDocument.prodlco.push({ id: 4, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.prodlco.line4') });
			requestDocument.prodlco.push({ id: 5, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.prodlco.line5') });

			requestDocument.subdlco = [];
			requestDocument.subdlco.push({ id: 1, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.subdlco.calibration'), isCalibration: true });
			requestDocument.subdlco.push({ id: 2, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.subdlco.line2') });
			requestDocument.subdlco.push({ id: 3, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.subdlco.line3') });
			requestDocument.subdlco.push({ id: 4, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.subdlco.line4') });
			if (hasHb) {
				requestDocument.subdlco.push({ id: 5, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.subdlco.line5') });
				requestDocument.subdlco.push({ id: 6, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.subdlco.line6') });
			}
			else {
				requestDocument.subdlco.push({ id: 5, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.subdlco.line6') });
			}

			requestDocument.bodyPlethysmography = [];
			requestDocument.bodyPlethysmography.push({ id: 1, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.bodyPlethysmography.line1') });
			requestDocument.bodyPlethysmography.push({ id: 2, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.bodyPlethysmography.line2') });
			requestDocument.bodyPlethysmography.push({ id: 3, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.bodyPlethysmography.line3') });
			requestDocument.bodyPlethysmography.push({ id: 4, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.bodyPlethysmography.calibration'), isCalibration: true });

			requestDocument.mbw = [];
			requestDocument.mbw.push({ id: 1, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.mbw.line1') });
			requestDocument.mbw.push({ id: 2, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.mbw.line2') });
			requestDocument.mbw.push({ id: 3, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.mbw.line3') });
			requestDocument.mbw.push({ id: 4, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.mbw.calibration'), isCalibration: true });

			requestDocument.ecg = [];
			requestDocument.ecg.push({ id: 1, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.ecg.line1') });
			requestDocument.ecg.push({ id: 2, value: appI18n.translateImmediate('cde-pft.addAttachment.requirement.ecg.line2') });
		}
	}
})();
