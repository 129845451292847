(function () {

	'use strict';

	angular.module('portalApp').controller('pftFileUploadController', PftFileUploadController);

	/* @ngInject */
	function PftFileUploadController($scope, $log, FileUploader, remoteFileService,
									 clinicalDataEntryApiService, clinicalDataEntryDataService,
									 $stateParams, configurationService,
									 appI18n, appConfig, clinicalDataEntryService) {
		var vm = this;
		$log = $log.getInstance('PftFileUploadController', 'color:blue');
		$log.debug(' loaded');


		vm.isValid = isValid;
		vm.setData = setData;
		vm.remove = remove;
		vm.openFile = openFile;
		vm.isPreviewSupported = isPreviewSupported;
		vm.isAddMode = isAddMode;
		vm.isEditMode = isEditMode;
		vm.isAddOrEditMode = isAddOrEditMode;
		vm.isViewOnlyMode = isViewOnlyMode;
		vm.isShowComments = isShowComments;
		vm.canDeleteFile = canDeleteFile;
		vm.getAddBy = getAddBy;
		vm.clearUploadErrorMessage = clearUploadErrorMessage;
		vm.resetAttachments = resetAttachments;

		vm.errorMessage = '';
		vm.uploadErrorMessage = '';
		vm.queryGuideline = null;

		const data = clinicalDataEntryDataService.getDataModel();

		vm.fileItems = [];
		vm.updatingFile = null;
		setData(data);
		activate();

		function setData(data) {
			vm.data = data;
			vm.data.attachments = vm.data.attachments || [];
		}

		async function activate() {
			initFileUploader(vm);
			clinicalDataEntryService.registerUploadHandler(vm);
			setMessages();

			if ($stateParams.signKey) {
				vm.agreement = vm.data.attachments.length > 0;
				vm.confirmCalibration = true;

				await updateConfigAndSetDocuments();
			} else {
				vm.agreement = false;
			}

			if ($scope.wizardStep) {
				$scope.wizardStep.isValid = vm.isValid;
			}

			$scope.$on('navigateToStep2', function (event, args) {
				initialize();
			});

			$scope.$watch(
				'vm.data.visitEvent.testDataTypeId',
				async function (newValue, oldValue) {
					if (newValue !== oldValue) {

						// update of studyConfig is required if the event data type has changed.
						// (only possible during new data entry)
						await updateConfigAndSetDocuments();

						// we need to trigger a digest cycle here because otherwise
						// the update of the study config and document list is not detected correctly.
						$scope.$apply();
					}
				});
		}

		function setMessages() {
			vm.commentsPlaceholder = appI18n.translateImmediate('app.common.commentsPlaceHolder');
			vm.saveErrorMessage = appI18n.translateImmediate('cde-pft.addAttachment.errors.saveErrorMessage');
			vm.saveErrorMessageVirus = appI18n.translateImmediate('cde-pft.addAttachment.errors.saveErrorMessageVirus');
			vm.invalidFileTypeMessage = appI18n.translateImmediate('cde-pft.addAttachment.errors.invalidFileTypeMessage');
			vm.invalidFileSizeMessage = appI18n.translateImmediate('cde-pft.addAttachment.errors.invalidFileSizeMessage');
			vm.addByERTMessage = appI18n.translateImmediate('app.addAttachment.addByERTMessage');
			vm.addBySiteMessage = appI18n.translateImmediate('app.addAttachment.addBySiteMessage');
			vm.errorMessageRequireUploadFiles = appI18n.translateImmediate('cde-pft.addAttachment.errors.requireUploadFiles');
			vm.errorMessageSavingFile = appI18n.translateImmediate('cde-pft.addAttachment.errors.saveInProgress');
			vm.agreementErrorMessage = null;
		}

		async function updateConfigAndSetDocuments() {
			vm.isLoading = true;

			const testDataTypeId = vm.data.visitEvent && vm.data.visitEvent.testDataTypeId;

			if (testDataTypeId && testDataTypeId > 0) {

				try {
					var studyId;
					if (vm.data.selectedStudy) {
						studyId = vm.data.selectedStudy.protocolId;
					} else if (vm.data.context) {
						studyId = vm.data.context.studyId;
					}

					await updateStudyConfig(studyId, testDataTypeId);
				} catch (e) {
					$log.error(e);
				}
				vm.documentList = getDocumentList(testDataTypeId);
			} else {
				// if no testDataTypeId is available clear the documentList
				vm.documentList = [];
			}

			vm.isLoading = false;
		}

		async function updateStudyConfig(studyId, testDataTypeId) {
			var studyConfig = await clinicalDataEntryApiService.getStudyConfig(studyId, testDataTypeId);

			vm.hideCalibrationReport = studyConfig.data.hideCalibrationReport;
			vm.hasJsonFileUpload = studyConfig.data.hasJsonFileUpload;
			vm.hasConfiguredParameters = studyConfig.data.hasConfiguredParameters;
			vm.queryGuideline = studyConfig.data.queryGuideline;
			vm.hasHb = studyConfig.data.hasHb;
		}

		function initialize() {
			vm.clearUploadErrorMessage();
			$log.debug('initialize');
		}

		function clearUploadErrorMessage() {
			vm.errorMessage = '';
			vm.uploadErrorMessage = '';
		}

		function resetAttachments() {
			vm.data.attachments = [];
			vm.agreement = false;
		}

		function getDocumentList(testDataTypeId) {
			return clinicalDataEntryDataService.getDocumentList(
				testDataTypeId,
				clinicalDataEntryService.getCdeMode(),
				vm.hasJsonFileUpload,
				vm.hideCalibrationReport,
				vm.hasConfiguredParameters,
				vm.hasHb);
		}

		function isValid() {
			if (vm.savingFile) {
				vm.errorMessage = vm.errorMessageSavingFile;
				return false;
			}

			var attachments = vm.data.attachments && vm.data.attachments.filter(function (attachment) {
				return !attachment.errorMessage;
			});

			if (!vm.data.attachments || attachments.length === 0 || vm.uploader.queue.length) {
				vm.errorMessage = vm.errorMessageRequireUploadFiles;
				return false;
			}

			var valid = true;

			if (vm.isAddMode() && !vm.hasJsonFileUpload) {
				if (!vm.agreement) {
					vm.agreementErrorMessage = appI18n.translateImmediate('cde-pft.addAttachment.errors.agreementRequired');
					valid = false;
				}
				if (vm.hideCalibrationReport && !vm.confirmCalibration) {
					vm.confirmCalibrationErrorMessage =
						appI18n.translateImmediate('cde-pft.addAttachment.errors.calibrationConfirmationRequired');
					valid = false;
				}
			}

			if (!valid) {
				return false;
			}

			vm.data.attachments = attachments;

			return true;
		}

		function remove(index) {
			var file = vm.data.attachments[index];
			var item = _.find(vm.fileItems, { index: file.index });
			if (item) {
				try {
					item.fileItem.remove();
				} catch (ex) {
					$log.error('try to remove item:' + ex);
				}
			}
			_.remove(vm.fileItems, { index: file.index });
			vm.data.attachments.splice(index, 1);
		}

		function isValidFile(fileItem) {
			var file = fileItem.file;

			return isValidFileType(file) && isValidFileSize(file);
		}

		function isValidFileSize(item) {
			var maxSize = getFileConfig().fileMaxSize;
			var maxSizeMb = maxSize / 1048576;

			var result = item.size <= maxSize;
			if (!result) {
				vm.errorMessage = `${vm.invalidFileSizeMessage} of ${maxSizeMb.toFixed(0)} MB.`;
			}
			return result;
		}

		function getFileConfig() {
			if (vm.hasJsonFileUpload) {
				return appConfig.fileUpload.spiroSphere;
			} else if (+vm.data.visitEvent.testDataTypeId === appConfig.testDataType.ecg) {
				return appConfig.fileUpload.paperECG;
			} else {
				return appConfig.fileUpload.paperPFT;
			}
		}

		function isValidFileType(item) {
			var fileConfig = getFileConfig();
			if (fileConfig.validFileType.length === 1 && fileConfig.validFileType[0] === '*') {
				return true;
			}
			var type = item.name.slice(item.name.lastIndexOf('.') + 1);
			type = (type || '').toLowerCase();
			var result = fileConfig.validFileType.indexOf(type) !== -1;
			if (!result) {
				vm.errorMessage = vm.invalidFileTypeMessage;
			}
			return result;
		}

		function initFileUploader(vm) {
			vm.uploader = new FileUploader({
				url: remoteFileService.getPostFileUrl(),
				autoUpload: true
			});

			vm.uploader.onProgressItem = function (fileItem, progress) {
				$log.log(`onProgressItem file: ${fileItem.file.name} progress: ${progress}`);
			};

			vm.uploader.onAfterAddingFile = function (fileItem) {
				vm.errorMessage = '';
				var index = -100;
				var errorMessage = '';
				var itemSaving = false;

				if (!isValidFile(fileItem)) {
					vm.uploader.removeFromQueue(fileItem);
					errorMessage = vm.errorMessage;
					vm.errorMessage = '';
				} else {
					itemSaving = true;
					vm.savingFile = true;
					index = vm.uploader.getIndexOfItem(fileItem);
				}

				const comments = vm.updatingFile != null ? vm.updatingFile.comments : '';
				const redactedInPortal = vm.updatingFile != null ? vm.updatingFile.redactedInPortal : false;

				vm.data.attachments.unshift({
					url: '',
					originalFilename: fileItem.file.name,
					comments: comments,
					index: index,
					userIsInternal: vm.data.userIsInternal,
					saving: itemSaving,
					boxes: [],
					redactedInPortal: redactedInPortal,
					errorMessage: errorMessage
				});
				vm.fileItems.unshift({ fileItem: fileItem, index: index });
				$log.debug('onAfterAddingFile' + index, fileItem);
			};

			vm.uploader.onSuccessItem = function (fileItem, response, status, headers) {
				var index = vm.uploader.getIndexOfItem(fileItem);
				var item = _.find(vm.data.attachments, { index: index });
				let hasAnyErrorDuringUpload = false;
				if (item) {
					item.url = response.url;
					$log.debug(item.url);
					item.index = -1;
					item.saving = false;
					/*
						* If response.url is undefined or null, it means that the attachment was not uploaded on server.
						 So we need to show error message to user and mark the attachment as invalid.
					*/
					if(response && !response.url) {
						item.errorMessage = vm.saveErrorMessage;						
						$log.error(`Unable to uplaod file on server.
						FileName:: ${fileItem.file.name}. 
						DataEntryId:: ${vm.data.id}.
						Response:: ${JSON.stringify(response)}`);
						
						hasAnyErrorDuringUpload = true;
					}

					if (vm.updatingFile != null) {
						item.comments = vm.updatingFile.comments;
						item.boxes = vm.updatingFile.boxes;
						item.redactedInPortal = item.redactedInPortal || vm.updatingFile.redactedInPortal;
						vm.updatingFile = null;
					}
				}


				if(!hasAnyErrorDuringUpload) {
					$log.log(`Success ${fileItem.file.name} uploaded. Response: ${JSON.stringify(response)}`);
					$log.debug('onSuccessItem', fileItem, response, status, headers);
				}
			};

			vm.uploader.onErrorItem = function (fileItem, response, status, headers) {
				var index = vm.uploader.getIndexOfItem(fileItem);
				var item = _.find(vm.data.attachments, { index: index });
				if (item) {
					if (response.message && response.message.indexOf('Virus') >= 0) {
						item.errorMessage = vm.saveErrorMessageVirus;
					} else {
						item.errorMessage = vm.saveErrorMessage;
					}
					item.saving = false;
				}
				$log.log('Error status:', response);
				$log.debug('onErrorItem', fileItem, response, status, headers);
			};

			vm.uploader.onCompleteAll = function () {
				vm.savingFile = false;
				vm.errorMessage = '';
				vm.uploader.clearQueue();
				vm.fileItems = [];
				$log.debug('onCompleteAll');
			};
		}

		function openFile(index, isEditing) {
			const file = vm.data.attachments[index];

			const changeCallBack = (newFile, boxes, redactedInPortal) => {
				vm.updatingFile = {...file, boxes};
				vm.updatingFile.redactedInPortal = file.redactedInPortal || redactedInPortal;
				vm.remove(index);
				vm.uploader.addToQueue([newFile]);
			};
			const openNewWindow = isEditing
				&& vm.data.userIsInternal
				&& isAddOrEditMode()
				&& isPreviewSupported(file.originalFilename);

			remoteFileService.openFile(
				file.url,
				file.originalFilename,
				true,
				file.boxes,
				openNewWindow ? changeCallBack : undefined
			);

			$log.debug(`open file ${file.url}`);
		}

		function isPreviewSupported(filename) {
			const fileExtension = filename.split('.').pop().toLowerCase();
			return ["pdf", "png", "jpg", "jpeg", "gif"].indexOf(fileExtension) >= 0;
		}

		function isAddMode() {
			const mode = clinicalDataEntryDataService.getDataField('displayMode');

			return (!mode || mode === appConfig.dataEntryDisplayModes.add);
		}

		function isEditMode() {
			const mode = clinicalDataEntryDataService.getDataField('displayMode');

			return mode &&
				((mode === appConfig.dataEntryDisplayModes.edit) ||
					(mode === appConfig.dataEntryDisplayModes.secondEntry) ||
					(mode === appConfig.dataEntryDisplayModes.firstEntryByErtForSiteSubmission));
		}

		function isAddOrEditMode() {
			return isAddMode() || isEditMode();
		}

		function isViewOnlyMode() {
			const mode = clinicalDataEntryDataService.getDataField('displayMode');

			return mode && (mode === appConfig.dataEntryDisplayModes.review
				|| mode === appConfig.dataEntryDisplayModes.viewOnly);
		}

		function isShowComments(comments) {
			if (!isViewOnlyMode()) {
				return true;
			}
			if (comments) {
				return true;
			}

			return false;
		}

		function canDeleteFile(item) {
			if (item.errorMessage) {
				return false;
			}

			if (isAddMode()) {
				return true;
			}

			if (isEditMode()) {
				return true;
			}

			return false;
		}

		function getAddBy(item) {
			var result = '';
			if (isEditMode()) {
				if (item.userIsInternal !== null && item.userIsInternal !== vm.data.userIsInternal) {
					result = item.userIsInternal ? vm.addByERTMessage : vm.addBySiteMessage;
				}
			}
			return result;

		}

	}
})();
