(function () {
	'use strict';

	angular.module('portalApp').controller('PftEntryCtrl', PftEntryController);


	/*
	 * @ngInject
	 */
	function PftEntryController($log, $filter, clinicalDataEntryApiService, $q,
								configurationService, $stateParams, signatureService, $location,
								clinicalDataEntryService, clinicalDataEntryDataService,
								appConfig, $scope, appI18n, dialogService, appSession,
								notificationService, loadingIndicatorService) {

		$log = $log.getInstance("pftEntryCtrl");
		$log.debug("pftEntryCtrl:: loaded");

		var vm = this;

		vm.signature = {};
		vm.active = false;
		vm.queryGuideline = null;
		vm.savedData = {};
		vm.data = getInitDataModel();
		vm.user = appSession.get();

		vm.showSignature = false;
		vm.isShowReviewComments = false;
		vm.isShowRejectReason = false;
		$scope.current = true;
		vm.discrepancies = false;
		vm.closeOnEscape = false;


		vm.showEntry = showEntry;
		vm.updateAction = updateAction;
		vm.submitAction = submitAction;
		vm.cancelAction = cancelAction;
		vm.requestSiteInputAction = requestSiteInputAction;
		vm.approveAction = approveAction;
		vm.editAction = editAction;
		vm.secondEntrySubmitAction = secondEntrySubmitAction;
		vm.rejectAction = rejectAction;
		vm.isValid = isValid;
		vm.approveEntry = approveEntry;
		vm.updateEntry = updateEntry;
		vm.toggleSelection = toggleSelection;
		vm.checked = checked;
		vm.resetState = resetState;

		active();

		function active() {
			let state;

			if (isSigning()) {
				state = signatureService.getState($stateParams.signKey);
				if (state) {
					vm.data = state;
					clinicalDataEntryDataService.restoreDataModel(vm.data);
				}
			}


			clinicalDataEntryService.registerShowEntry(vm.showEntry);
			$scope.$on('$destroy', () => {
				clinicalDataEntryService.unregisterShowEntry(vm.showEntry);
			});

			getTitles();
			var defers = $q.all([
				configurationService.getUserStudySettings()
			]);
			defers.then((resolves) => {
				var userSettings = resolves[0].data;
				vm.userIsInternal = userSettings.isInternal;

				if (state) {
					vm.savedData = vm.data.savedData;
					vm.allowSecondEntrySubmit = state.allowSecondEntrySubmit;
					vm.isShowReviewComments = state.isShowReviewComments;
				}
			});
		}

		function isSigning() {
			return !!$stateParams.signKey;
		}

		function getTitles() {
			vm.titles = {};
			vm.titles.signatureTitleApprove = appI18n.translateImmediate('cde-pft.titles.signatureTitleApprove');
			vm.titles.signatureTitleUpdate = appI18n.translateImmediate('cde-pft.titles.signatureTitleUpdate');
			vm.titles.signatureTitleSecondEntry = appI18n.translateImmediate('cde-pft.titles.signatureTitleSecondEntry');
			vm.titles.signatureTitleSecondEntryByErtForSiteSubmission =
				appI18n.translateImmediate('cde-pft.titles.signatureTitleSecondEntryByErtForSiteSubmission');
			vm.titles.signatureTitleFirstEntryByErtForSiteSubmission =
				appI18n.translateImmediate('cde-pft.titles.signatureTitleFirstEntryByErtForSiteSubmission');
			vm.titles.signatureTitleReject = appI18n.translateImmediate('cde-pft.titles.rejectEntry');
			vm.titles.signatureTitleSubmit = appI18n.translateImmediate('cde-pft.titles.submitEntry');
			vm.titles.submitButton = appI18n.translateImmediate('app.buttons.sign-submit');
			vm.titles.cancelButton = appI18n.translateImmediate('cde-pft.titles.cancelButton');
			vm.titles.approveButton = appI18n.translateImmediate('app.buttons.sign-approve');
			vm.titles.editButton = appI18n.translateImmediate('cde-pft.titles.editButton');
			vm.titles.requestInputButton = appI18n.translateImmediate('cde-pft.titles.requestInputButton');
			vm.titles.backButton = appI18n.translateImmediate('cde-pft.titles.backButton');
			vm.titles.rejectButton = appI18n.translateImmediate('cde-pft.titles.rejectButton');

		}

		function initState() {
			// don't set these values after signature redirect
			if (!isSigning()) {
				vm.data = getInitDataModel();
				vm.discrepancies = false;
				vm.savedData = {};
				vm.isSiteUserEdit = false;
				vm.isRejectAction = false;
			}
			vm.active = false;
			vm.showSignature = false;
			vm.errorMsg = '';
			vm.reviewCommentsHandler = null;
			vm.submissionDetailsData = {};
			vm.subjectDetailsData = {};
			vm.isCloseable = true;
			vm.isLoading = true;
		}

		function getInitDataModel(){
			clinicalDataEntryDataService.clearDataModel();
			return clinicalDataEntryDataService.getDataModel();
		}

		function showEntry(entry) {
			loadingIndicatorService.show();
			initState();
			if (!entry || !entry.id) {
				loadingIndicatorService.hide();
				return;
			}
			clinicalDataEntryApiService.getLockStatus(entry.id).then((response) => {

				if (isSigning()) {
					//unlock entry
					unLockEntry().then(() => {
						getEntryDataAndInit(entry.id, entry.studyId, entry.dataType);
					});

					return;
				}
				if (response.data.isLocked) {
					loadingIndicatorService.hide();
					showLocked(response.data);
				} else {
					getEntryDataAndInit(entry.id, entry.studyId, entry.dataType);
				}
			}, (error) => {
				loadingIndicatorService.hide();
				vm.isLoading = false;
				$log.error(`Error: ${error}`);
			});
		}

		function getEntryDataAndInit(entryId, studyId, dataTypeId) {
			vm.active = true;

			// preload StudyConfig 
			let defers = $q.all([
					clinicalDataEntryApiService.getOne(entryId),
					clinicalDataEntryApiService.getStudyConfig(studyId, dataTypeId)
				]);

			defers.then((resolves) => {
				const loadPromise = setData(resolves[0].data);
				if (loadPromise) {
					loadPromise.then(() => {
						if (!vm.isAutoSubmit) {
							loadingIndicatorService.hide();
						}
						vm.isLoading = false;
					});
				} else {
					if (!vm.isAutoSubmit) {
						loadingIndicatorService.hide();
					}
					vm.isLoading = false;
				}
			}, (error) => {
				vm.isLoading = false;
				$log.error(`Error: ${error}`);
				loadingIndicatorService.hide();
			}).finally(() => {
			});
		}

		function setTitle() {
			vm.title = 'cde-pft.titles.viewEntry';
			if (vm.data.displayMode === appConfig.dataEntryDisplayModes.review) {
				vm.title = vm.isRejectEntry ? 'cde-pft.titles.rejectEntry' : 'cde-pft.titles.reviewEntry';
			} else if (vm.data.displayMode === appConfig.dataEntryDisplayModes.edit) {
				vm.title = 'cde-pft.titles.editEntry';
			} else if (isSecondEntryByErtForSiteSubmissionMode()) {
				vm.title = vm.isRejectEntry ? 'cde-pft.titles.rejectEntry' : 'cde-pft.titles.secondEntryByErtForSiteSubmission';
			} else if (vm.data.displayMode === appConfig.dataEntryDisplayModes.secondEntry) {
				vm.title = vm.isRejectEntry ? 'cde-pft.titles.rejectEntry' : 'cde-pft.titles.secondEntry';
			} else if (vm.data.displayMode === appConfig.dataEntryDisplayModes.firstEntryByErtForSiteSubmission) {
				vm.title = 'cde-pft.titles.firstEntryByErtForSiteSubmission';
			}
		}

		function isSiteUserEdit() {
			return !vm.userIsInternal && vm.savedData.state === appConfig.paperDataEntryState.pendingSiteInput && vm.savedData.isSiteUserEditMode;
		}

		function isReview() {
			return vm.userIsInternal && vm.user.userId != vm.savedData.modifiedBy &&
				(vm.savedData.state === appConfig.paperDataEntryState.pendingReview ||
				 vm.savedData.state === appConfig.paperDataEntryState.pendingAdditionalReview ||
				 vm.savedData.state === appConfig.paperDataEntryState.pendingSiteInputReview);
		}

		function isFirstEntryByErtForSiteSubmissionState() {
			return vm.userIsInternal && vm.user.userId != vm.savedData.modifiedBy &&
				vm.savedData.state === appConfig.paperDataEntryState.pendingFirstEntryByErtForSiteSubmission;
		}

		function isSecondEntryByErtForSiteSubmissionState() {
			return vm.userIsInternal && vm.user.userId != vm.savedData.modifiedBy &&
				vm.savedData.state === appConfig.paperDataEntryState.pendingSecondEntryByErtForSiteSubmission;
		}

		function isPendingSiteInputState() {
		   return vm.savedData.state === appConfig.paperDataEntryState.pendingSiteInput;
		}
		function isSecondEntry() {
			return vm.userIsInternal && vm.user.userId != vm.savedData.modifiedBy &&
				vm.savedData.state === appConfig.paperDataEntryState.pendingSecondEntry;
		}

		function setDisplayModeForEntry() {
			var mode = appConfig.dataEntryDisplayModes.viewOnly;
			if (isSiteUserEdit()) {
				vm.isSiteUserEdit = true;
				mode = appConfig.dataEntryDisplayModes.edit;
			} else if (isReview()) {
				mode = appConfig.dataEntryDisplayModes.review;
			} else if (isSecondEntry()) {
				mode = appConfig.dataEntryDisplayModes.secondEntry;
			} else if (isFirstEntryByErtForSiteSubmissionState()) {
				mode = appConfig.dataEntryDisplayModes.firstEntryByErtForSiteSubmission;
			} else if (isSecondEntryByErtForSiteSubmissionState()) {
				mode = appConfig.dataEntryDisplayModes.secondEntryByErtForSiteSubmission;
			}

			setDisplayMode(mode);
		}

		function startSecondEntryByErtForSiteSubmission() {
			showVisitEvent();
		}

		function startFirstEntryByErtForSiteSubmission() {
			showVisitEvent();
		}

		function setDisplayMode(mode) {
			vm.data.displayMode = mode;
			clinicalDataEntryDataService.setDataField('displayMode', mode);
		}

		function getCdeMode() {
			if (vm.savedData.paperType) {
				return vm.savedData.paperTypeDescription;
			}
			return clinicalDataEntryService.getCdeMode();
		}

		function setData(data) {
			vm.closeOnEscape = false;
			let loadPromise = null;

			if (!isSigning()) {
				vm.savedData = data;
				var jsonData = JSON.parse(vm.savedData.data);
				vm.data = clinicalDataEntryDataService.setDataModel(jsonData);
				setDisplayModeForEntry();
				vm.data.id = data.id;
				vm.data.context.studyName = data.studyName;
				vm.data.context.siteName = data.sponsorSiteId;

				vm.submissionDetailsData = submissionDetailsDataFormatter(vm.data.context, data);
				vm.subjectDetailsData = subjectDetailsDataFormatter(vm.data.subject);
				initVmData();
				setDocumentList();
				setReviewComments();

				setTitle();
				showActions();
				lockEntry();

				if (isFirstEntryByErtForSiteSubmissionMode()) {
					startFirstEntryByErtForSiteSubmission();
				} else if (isSecondEntryByErtForSiteSubmissionMode()) {
					startSecondEntryByErtForSiteSubmission();
				}
				vm.isShowRejectReason = vm.savedData.state === appConfig.paperDataEntryState.rejected;
			}
			if ($stateParams.signKey) {
				const state = signatureService.getState($stateParams.signKey);

				if (state) {
					handleAutoSubmit(state, data);
				}
			} else {
				loadPromise = showVisitEvent();
			}

			//controls modal escape gunctionality
			if (vm.data.displayMode === appConfig.dataEntryDisplayModes.viewOnly ||
				vm.data.displayMode === appConfig.dataEntryDisplayModes.review) {
				vm.closeOnEscape = true;
			}

			vm.isShowReviewComments = canShowReviewComments();
			return loadPromise;
		}

		function handleAutoSubmit(state, data) {
			vm.isAutoSubmit = true;
			// reset state
			vm.data = state;
			clinicalDataEntryDataService.restoreDataModel(vm.data);

			setDisplayMode(state.displayMode);
			setVmData(state);
			setTitle();
			showActions();
			setDocumentList();
			vm.alreadyCompared = true;
			vm.showSignature = true;
			const cdeMode = getCdeMode();

			vm.visitEventHandler.setData(vm.data, cdeMode);
			vm.effortsHandler.setData(vm.data, cdeMode);
			vm.uploadHandler.setData(vm.data);
			vm.submissionDetailsData = submissionDetailsDataFormatter(vm.data.context, data);
			vm.subjectDetailsData = subjectDetailsDataFormatter(vm.data.subject);

			if (signatureService.validateSignature($stateParams.tokenId, $stateParams.signKey)) {
				approveAction();
			} else {
				vm.isAutoSubmit = false;
			}
		}

		function submissionDetailsDataFormatter(submissionData, historyData){
			var res = {
				titleI18n: 'cde-pft.titles.submissionDetails',
				header: appI18n.translateImmediate('cde-pft.titleCard.submitter') + ' ' + historyData.createdByName,
				items: [
					{
						nameI18n: 'cde-pft.titleCard.from',
						value: submissionData.user.firstName + ' ' + submissionData.user.lastName
					},
					{
						nameI18n: 'cde-pft.titleCard.fromEmail',
						value: submissionData.user.email
					},
					{
						nameI18n: 'cde-pft.titleCard.study',
						value: submissionData.studyName
					},
					{
						nameI18n: 'cde-pft.titleCard.site',
						value: submissionData.siteName
					},
					{
						nameI18n: 'cde-pft.titleCard.submissionId',
						value: historyData.id
					},
					{
						name: appI18n.translateImmediate('cde-pft.grid.labels.createdDate') + ': ',
						value: $filter('date')(historyData.createdOn, 'dd-MMM-yyyy HH:mm:ss')
					},
					{
						name: appI18n.translateImmediate('cde-pft.grid.labels.modifiedByName') + ': ',
						value: historyData.modifiedByName
					},
					{
						name: appI18n.translateImmediate('cde-pft.grid.labels.modifiedDate') + ': ',
						value: $filter('date')(historyData.modifiedOn, 'dd-MMM-yyyy HH:mm:ss')
					}
				]
			};
			return res;
		}

		function subjectDetailsDataFormatter(subject) {

			var res = {
				titleI18n: 'cde-pft.titles.subjectDetails',
				header: appI18n.translateImmediate('cde-pft.titleCard.subject') + ' ' + subject.sponsorSubjectId,
				items: (function (fields) {
					var r = [];
					if(!fields || !fields.length) { return r; }
					r = fields.map(function (field, index, arr) {
						return {
							name: field.label || field.name,
							value: field.value
						};
					});
					return r;
				}(subject.fields))

			};
			return res;
		}

		function initVmData() {
			vm.visitEventHandler = clinicalDataEntryService.getVisitEventHandler();
			vm.effortsHandler = clinicalDataEntryService.getEffortsHandler();
			vm.uploadHandler = clinicalDataEntryService.getUploadHandler();
			vm.alreadyCompared = false;
			vm.allowSecondEntrySubmit = false;
			vm.data.userIsInternal = vm.userIsInternal;
		}

		function setVmData(state) {
			vm.visitEventHandler = clinicalDataEntryService.getVisitEventHandler();
			vm.effortsHandler = clinicalDataEntryService.getEffortsHandler();
			vm.uploadHandler = clinicalDataEntryService.getUploadHandler();
			vm.alreadyCompared = true;
			vm.isRejectAction = state.isRejectAction;
			vm.isSiteUserEdit = state.isSiteUserEdit;
			vm.allowSecondEntrySubmit = state.allowSecondEntrySubmit;
			vm.data.userIsInternal = vm.userIsInternal;
		}

		function showVisitEvent() {
			const cdeMode = getCdeMode();

			return vm.visitEventHandler.show(
				vm.data.context.studyId, $stateParams.mode,
				vm.data.context.internalSiteId,
				vm.data.subject.isProficiency, vm.data.displayMode, vm.data, cdeMode);
		}

		function setDocumentList() {
			// reset guery guideline property
			vm.queryGuideline = null;
			if (vm.data.visitEvent && vm.data.visitEvent.testDataTypeId > 0) {
				clinicalDataEntryApiService.getStudyConfig(
					vm.data.context.studyId, vm.data.visitEvent.testDataTypeId
				).then(function onSuccess(result) {
					vm.hasJsonFileUpload = result.data.hasJsonFileUpload;
					vm.hideCalibrationReportForSiteUser = result.data.hideCalibrationReportForSiteUser;
					vm.hasConfiguredParameters = result.data.hasConfiguredParameters;
					vm.queryGuideline = result.data.queryGuideline;
					vm.hasHb = result.data.hasHb;
					
					vm.documentList = clinicalDataEntryDataService.getDocumentList(
						vm.data.visitEvent.testDataTypeId,
						clinicalDataEntryService.getCdeMode(),
						vm.hasJsonFileUpload,
						vm.hideCalibrationReportForSiteUser,
						vm.hasConfiguredParameters,
						vm.hasHb);
				});
			} else {
				vm.documentList = [];
			}
		}

		function setReviewComments() {
			if (vm.savedData.reviewComments) {
				try {
					vm.data.reviewComments = JSON.parse(vm.savedData.reviewComments);
				} catch(e) {
					vm.data.reviewComments = {
						comments: '',
						selectedDocumentList: []
					};
					$log.error(e);
				}
			}
			else {
				vm.data.reviewComments = {
					comments: '',
					selectedDocumentList: []
				};
			}
		}

		function toggleSelection(document) {
			var item = _.find(vm.data.reviewComments.selectedDocumentList, {id: document.id});
			if (item) {
				_.remove(vm.data.reviewComments.selectedDocumentList, {id: item.id});
			} else {
				if (!vm.data.reviewComments.selectedDocumentList)
				{
					vm.data.reviewComments.selectedDocumentList = [];
				}
				vm.data.reviewComments.selectedDocumentList.push(document);
			}
		}

		function checked(item) {
			var result = _.find(vm.data.reviewComments.selectedDocumentList, {id: item.id});
			return result === undefined ? false : true;
		}

		function showActions() {
			vm.actions = [];
			if (vm.data.displayMode === appConfig.dataEntryDisplayModes.edit) {
				vm.actions = [
					{ action: vm.updateAction, displayName: vm.titles.submitButton, isPrimary: true },
					{ action: vm.cancelAction, displayName: vm.titles.cancelButton },
				];

			} else if (vm.data.displayMode === appConfig.dataEntryDisplayModes.review) {
				vm.actions = [
					{ action: vm.submitAction, displayName: vm.titles.approveButton, isPrimary: true },
					{ action: vm.rejectAction, displayName: vm.titles.rejectButton },
					{ action: vm.requestSiteInputAction, displayName: vm.titles.requestInputButton },
					{ action: vm.editAction, displayName: vm.titles.editButton },
					{ action: vm.cancelAction, displayName: vm.titles.cancelButton },
				];
			} else if (vm.data.displayMode === appConfig.dataEntryDisplayModes.firstEntryByErtForSiteSubmission) {
			vm.actions = [
				{ action: vm.updateAction, displayName: vm.titles.submitButton, isPrimary: true },
				{ action: vm.rejectAction, displayName: vm.titles.rejectButton },
				{ action: vm.requestSiteInputAction, displayName: vm.titles.requestInputButton },
				{ action: vm.cancelAction, displayName: vm.titles.cancelButton },
			];
			} else if (vm.data.displayMode === appConfig.dataEntryDisplayModes.secondEntry ||
								 isSecondEntryByErtForSiteSubmissionState()) {
				vm.actions = [
					{ action: vm.secondEntrySubmitAction, displayName: vm.titles.submitButton, isPrimary: true },
					{ action: vm.rejectAction, displayName: vm.titles.rejectButton },
					{ action: vm.cancelAction, displayName: vm.titles.cancelButton }
				];
			} else if (canRejectInViewMode()) {
				vm.actions = [
					  { action: vm.rejectAction, displayName: vm.titles.rejectButton },
				];
      }
		}

		function showSignatureActions() {
			vm.signature = {};
			vm.signatureTitle = '';
			if (vm.data.displayMode === appConfig.dataEntryDisplayModes.review) {
				if (vm.isRejectAction) {
					vm.signatureTitle = vm.titles.signatureTitleReject;
					vm.sigType = appConfig.sigType.rejectPaperPft;
				} else {
					vm.signatureTitle = vm.titles.signatureTitleApprove;
					vm.sigType = appConfig.sigType.approvePaperPft;
				}
			} else if (vm.data.displayMode === appConfig.dataEntryDisplayModes.edit) {
				vm.signatureTitle = vm.titles.signatureTitleUpdate;
				vm.sigType = appConfig.sigType.updatePaperPft;
			} else if (vm.data.displayMode === appConfig.dataEntryDisplayModes.secondEntry) {
				if (vm.isRejectAction) {
					vm.signatureTitle = vm.titles.signatureTitleReject;
					vm.sigType = appConfig.sigType.rejectPaperPft;
				} else {
					vm.signatureTitle = vm.titles.signatureTitleSecondEntry;
					vm.sigType = appConfig.sigType.secondPaperPft;
				}
			} else if (vm.data.displayMode === appConfig.dataEntryDisplayModes.secondEntryByErtForSiteSubmission) {
				if (vm.isRejectAction) {
					vm.signatureTitle = vm.titles.signatureTitleReject;
					vm.sigType = appConfig.sigType.rejectPaperPft;
				} else {
					vm.signatureTitle = vm.titles.signatureTitleSecondEntryByErtForSiteSubmission;
					vm.sigType = appConfig.sigType.secondPaperPft;
				}
			} else if (vm.data.displayMode === appConfig.dataEntryDisplayModes.firstEntryByErtForSiteSubmission) {
				if (vm.isRejectAction) {
					vm.signatureTitle = vm.titles.signatureTitleReject;
					vm.sigType = appConfig.sigType.rejectPaperPft;
				} else {
					vm.signatureTitle = vm.titles.signatureTitleFirstEntryByErtForSiteSubmission;
					vm.sigType = appConfig.sigType.secondPaperPft;
				}
			} else if (vm.data.displayMode === appConfig.dataEntryDisplayModes.secondEntry) {
				if (vm.isRejectAction) {
					vm.signatureTitle = vm.titles.signatureTitleReject;
					vm.sigType = appConfig.sigType.rejectPaperPft;
				} else {
					vm.signatureTitle = vm.titles.signatureTitleSecondEntry;
					vm.sigType = appConfig.sigType.secondPaperPft;
				}
			} else if (vm.isRejectAction) {
					vm.signatureTitle = vm.titles.signatureTitleReject;
					vm.sigType = appConfig.sigType.rejectPaperPft;
      }

			vm.state = vm.data;
			vm.state.savedData = vm.savedData;
			vm.state.isRejectAction = vm.isRejectAction;
			vm.state.isSiteUserEdit = vm.isSiteUserEdit;
			vm.state.allowSecondEntrySubmit = vm.allowSecondEntrySubmit;
			vm.state.isShowReviewComments = vm.isShowReviewComments;
			vm.state.visitEventOptions = vm.visitEventHandler.getAvailableVisitEventOptions();
			vm.state.technicians = vm.visitEventHandler.getTechniciansList();
			vm.state.documentList = vm.documentList;
			vm.state.hasJsonFileUpload = vm.hasJsonFileUpload;

			vm.additionalInfo = {
				action: vm.signatureTitle,
				infoKey1: appI18n.translateImmediate('signature.info.site'),
				infoValue1: vm.data.context.sponsorSiteId,
				infoKey2: appI18n.translateImmediate('signature.info.subject'),
				infoValue2: vm.data.subject.sponsorSubjectId,
				infoKey3: appI18n.translateImmediate('signature.info.visitName'),
				infoValue3: vm.data.visitEvent.visitName,
				infoKey4: appI18n.translateImmediate('signature.info.eventName'),
				infoValue4: vm.data.visitEvent.eventName
			};

			if (vm.data.visitEvent.periodName) {
				vm.additionalInfo.infoKey3 = appI18n.translateImmediate('signature.info.visitPeriodName');
				vm.additionalInfo.infoValue3 = `${vm.additionalInfo.infoValue3} (${vm.data.visitEvent.periodName})`;
			}
			signatureService.sign(vm.sigType, vm.additionalInfo, vm.state);
		}

		function rejectAction() {
			showRejectComments();
		}

		function submitAction() {
			vm.showSignature = true;
			vm.uploadHandler.clearUploadErrorMessage();
			disableWizardButtons();
			showSignatureActions();
			$log.debug('submitAction');
		}

		function updateAction() {
			if (vm.isValid()) {
				getUpdateData();
				submitAction();
			}
		}

		function editAction() {
			setDisplayMode(appConfig.dataEntryDisplayModes.edit);
			setTitle();
			showActions();
			showVisitEvent();
			$log.debug('editAction');
		}

		function requestSiteInputAction() {
			showReviewComments();
			$log.debug('requestSiteInputAction');
		}

		function secondEntrySubmitAction() {
			if (vm.isValid()) {
				if (!vm.alreadyCompared) {
					var saved = JSON.parse(vm.savedData.data);

					var visitEventOk = vm.visitEventHandler.compareData(saved.visitEvent);
					var effortsOk = vm.effortsHandler.compareData();
					if (visitEventOk && effortsOk) {
						vm.allowSecondEntrySubmit = true;
					} else {
						vm.discrepancies = true;
					}
					vm.alreadyCompared = true;
				} else {
					vm.allowSecondEntrySubmit = true;
				}

				if (vm.allowSecondEntrySubmit) {
					// reset discrepancies message
					vm.discrepancies = false;
					getUpdateData();

					vm.showSignature = true;
					showSignatureActions();
					$log.debug('submitAction');
				}
			}
		}

		function cancelAction() {
			vm.active = false;
			unLockEntry();
			$location.search('signKey', null);
			$location.search('tokenId', null);
		}

		function closeSuccess() {
			vm.active = false;
			unLockEntry();
			$location.search('signKey', null);
			$location.search('tokenId', null);
			clinicalDataEntryService.refreshGrid();
		}

		function lockEntry() {
			if (vm.data.displayMode === appConfig.dataEntryDisplayModes.viewOnly) {
				return;
			}

			var data = vm.savedData;

			clinicalDataEntryApiService.lockEntry(data.studyId, 0, data.id);
		}

		function unLockEntry() {
			if (vm.data.displayMode === appConfig.dataEntryDisplayModes.viewOnly) {
				return Promise.resolve();
			}

			var data = vm.savedData;
			return clinicalDataEntryApiService.unLockEntry(data.studyId, 0, data.id);
		}

		function isValid() {
			return (isSpirometryFormValid() & isVisitEventValid() & isUploadValid());
		}

		function isUploadValid() {
			return vm.uploadHandler.isValid();
		}

		function isSpirometryFormValid() {
			return vm.effortsHandler.isValid();
		}

		function isVisitEventValid() {
			return vm.visitEventHandler.isValid();
		}

		function approveAction() {
			$log.debug('approveAction');

			vm.errorMsg = '';
			disableWizardButtons();
			if (vm.data.displayMode === appConfig.dataEntryDisplayModes.review) {
				if (vm.isRejectAction) {
					rejectEntry();
				} else {
					approveEntry();
				}
			} else if (vm.data.displayMode === appConfig.dataEntryDisplayModes.edit) {
				updateEntry();
			} else if (vm.data.displayMode === appConfig.dataEntryDisplayModes.firstEntryByErtForSiteSubmission) {
				  if (vm.isRejectAction) {
					    rejectEntry();
				  } else {
				      updateEntry();
				  }
			} else if (vm.data.displayMode === appConfig.dataEntryDisplayModes.secondEntry ||
								 isSecondEntryByErtForSiteSubmissionMode()) {
				if (vm.isRejectAction) {
					rejectEntry();
				} else if (vm.allowSecondEntrySubmit) {
					updateEntry();
				}
			} else if (vm.isRejectAction) {
					  rejectEntry();
				}
		}

		function rejectEntry() {
			updateEntryStatus(vm.isRejectAction);
		}

		function approveEntry() {
			updateEntryStatus(vm.isRejectAction);
		}

		function updateEntryStatus(isRejected) {
			var updateModel = {
				id: vm.savedData.id,
				signKey: $stateParams.signKey,
				signToken: $stateParams.tokenId,
				isRejected: isRejected,
				reviewComments: JSON.stringify(vm.data.reviewComments)
			};
			clinicalDataEntryApiService.updateEntryStatus(vm.savedData.id, updateModel).then(
				response => handleSuccess(response.data),
				error => handleError(error.data)
			);
		}

		function handleSuccess(data) {
			if (data && data.isSuccessful) {
				showSuccess();
				vm.resetState(true);
			} else {
				var error = data.messages ? data.messages[0] : data.translationKey ? data.translationKey : data;
				vm.errorMsg = appI18n.translateImmediate(error);
				notificationService.showError(vm.errorMsg);
				$log.error(vm.errorMsg);
				vm.resetState();
			}

		}
		function getUpdateData() {
			vm.data.visitEvent = vm.visitEventHandler.getData();
			vm.data.efforts = vm.effortsHandler.getData();
		}

		function handleError(error) {
			notificationService.showError(vm.errorMsg);
			$log.error(vm.errorMsg);
			vm.resetState();
		}

		function resetState(isSuccess) {
			loadingIndicatorService.hide();
			if (!isSuccess) {
				showVisitEvent();
				enableWizardButtons();
			}
			vm.isAutoSubmit = false;

		}

		function updateEntry() {
			var model = clinicalDataEntryDataService.getUpdateEntryData(vm.savedData, vm.data);

			model.signKey = $stateParams.signKey;
			model.signToken = $stateParams.tokenId;

			clinicalDataEntryApiService.updateEntry(vm.savedData.id, model).then(
				response => handleSuccess(response.data),
				error => handleError(error.data)
			);
		}

		function showSuccess() {
			notificationService.showSuccess(appI18n.translateImmediate('cde-pft.dialog.successSubmitDataContent'));
			closeSuccess();
		}

		function validRequestInput() {
			var input = vm.data.reviewComments;
			if (input) {
				if (input.comments && input.comments.length > 0) {
					return true;
				}
				if (input.selectedDocumentList && input.selectedDocumentList.length > 0) {
					return true;
				}
			}

			vm.isValidRequestInput = false;
			return false;
		}

		function showReviewComments() {
			vm.isValidRequestInput = true;
			disableWizardButtons();
			vm.reviewCommentsHandler = dialogService.createConfirmHandler();
			vm.reviewCommentsHandler.setValidFunc(validRequestInput);
			vm.reviewCommentsHandler.show().then((isConfirm) => {
				if (isConfirm) {
					vm.reviewCommentsHandler.hide();
					loadingIndicatorService.show();
					clinicalDataEntryApiService.requireMoreInfo(vm.savedData.id, vm.data.reviewComments).then(
						response => {
							loadingIndicatorService.hide();
							closeSuccess();
						},
						error => {
							enableWizardButtons();
							loadingIndicatorService.hide();
							vm.errorMsg = error.data;
							$log.debug(vm.errorMsg);
						});
				}
				enableWizardButtons();
			});
		}

		function validRejectInput() {
			if (vm.data.reviewComments.rejectReason) {
				return true;
			}
			vm.isValidRejectInput = false;
			return false;
		}

		function showRejectComments() {
			disableWizardButtons();
			vm.isValidRejectInput = true;
			vm.rejectHandler = dialogService.createConfirmHandler();
			vm.rejectHandler.setValidFunc(validRejectInput);

			vm.rejectHandler.show().then((isConfirm) => {
				if (isConfirm) {
					vm.isRejectAction = true;
					submitAction();
					vm.rejectHandler.hide();
				}
				enableWizardButtons();
			});
		}

		function isSecondEntryByErtForSiteSubmissionMode() {
			return vm.data.displayMode === appConfig.dataEntryDisplayModes.secondEntryByErtForSiteSubmission;
		}

		function isFirstEntryByErtForSiteSubmissionMode() {
			return vm.data.displayMode === appConfig.dataEntryDisplayModes.firstEntryByErtForSiteSubmission;
		}

		function isEditOrFirstEntryByErt() {
			return vm.data.displayMode === appConfig.dataEntryDisplayModes.edit  ||
				vm.data.displayMode === appConfig.dataEntryDisplayModes.firstEntryByErtForSiteSubmission;
		}

		function canRejectInViewMode() {
			  return vm.userIsInternal && vm.data.displayMode === appConfig.dataEntryDisplayModes.viewOnly &&
            isPendingSiteInputState();
		  }

		  function canShowReviewComments() {

			var result = false;
			if ((vm.data.displayMode === appConfig.dataEntryDisplayModes.edit || 
				vm.data.displayMode === appConfig.dataEntryDisplayModes.viewOnly) &&
				(vm.data && vm.data.reviewComments.comments || vm.data.reviewComments.selectedDocumentList.length > 0)) {
				result = true;
			}
			return result;
		}

		function disableWizardButtons() {
			for (var i = 0; i < vm.actions.length; i++) {
				vm.actions[i].isDisabled = true;
			}
			vm.isCloseable = false;
		}

		function enableWizardButtons() {
			for (var i = 0; i < vm.actions.length; i++) {
				vm.actions[i].isDisabled = false;
			}
			vm.isCloseable = true;
		}

		function showLocked(data) {
			vm.lockedBy = data.lockedByName || '';
			vm.lockedOn = moment(data.lockedOn).format('DD-MMM-YYYY hh:mm:ss');
			vm.lockedHandler = dialogService.createConfirmHandler();
			vm.lockedHandler.show().then(function () {
				vm.lockedHandler.hide();
			});
		}

		vm.commentsField = [{
			type: 'esTextArea',
			key: 'comments',
			templateOptions: {
				type: 'text',
				className: '-es-width-xlarge',
				disabled: false,
				id: 'commentsField'
			},
			ngModelElAttrs: {
				maxlength: '4000'
			}
		}];

		vm.commentsOptions = {
			formState: {
				comments: {
					disabled: false
				}
			}
		};

	}

})();
