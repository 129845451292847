/*
 * uploadWidgetController
 * */

class uploadWidgetController {
	
    constructor($log, appI18n, $filter, appSession, $state, appConfig) {
		'ngInject';
		
		this.$log = $log;
		this.appI18n = appI18n;
		this.$filter = $filter;
		this.appSession = appSession;
		this.$state = $state;
		this.appConfig = appConfig;
	}

	$onInit() {
		this.$log = this.$log.getInstance('uploadWidgetController');
		this.$log.debug('loaded');
		this.isLoading = false;
		this.displayContent = true;
	}

	openDataEntryModule() {
		this.$state.go(this.appConfig.routes.cdePftList);
	}

	openCardiacUploadModule() {
		this.$state.go(this.appConfig.routes.cardiacUpload);
	}
}

export default uploadWidgetController;
