module.exports = (function (UmsConfig) {
	var baseUrl = UmsConfig.api.baseUrl;

	var configurationPrefix = "configurations";
	var clinicalDataPrefix = "clinicaldata";
	var deviceManagementPrefix = 'devicemanagement';
	var downloadPrefix = "downloads";
	var eproPrefix = "epro";
	var ssoPrefix = "sso";
	var logiPrefix = "logi";
	var odsPrefix = "ods";
	var ecgDataPrefix = "ecgdata";
	var adminPrefix = "admin";
	var logisticsPrefix = 'logistics';
	var dataManagementPrefix = 'datamanagement';
	var documentPrefix = "documents";
	var announcementPrefix = "announcements";
	var refMatPrefix = "refmat";
	var emailConfirmationPrefix = "registration";
	var reportPrefix = "reporting";
	var studiesPrefix = "studies";
	var accountPrefix = "authentication";
	var dashboardPrefix = "dashboard";
	var userContactPrefix = "user-contacts";
	var spirometryPrefix = "spirometry";
	var clinicalDataEntryPrefix = "clinical-data-entries";
	var downloadFiles = 'download-files';
	var helpPrefix = "help";
	var remoteFilePrefix = "files";
	var notificationPrefix = "notifications";
	var gridExportPrefix = "grid-export";
	var iSpiroPrefix = 'ispiro';
	var kardiaPrefix = 'kardia';

	// Use the variables in your constants
	return {
		version: UmsConfig.versionInfo.version,
		environment: UmsConfig.versionInfo.environment,
		buildnumber: UmsConfig.versionInfo.buildnumber,
		buildtime: UmsConfig.versionInfo.buildtime,
		// used to overwrite prefered language
		preferredLanguage: '',

		external: {
			customerCare: 'https://clario.com/contact/customer-support/',
			imprint: 'https://clario.com/about/legal-and-privacy/imprint/',
			privacyPolicyUrl: 'https://clario.com/about/legal-and-privacy/privacy-and-integrity-policy/',
			dataPrivacyStatementUrl: 'https://clario.com/about/legal-and-privacy/#program-infrastructure/',
			iClinica: UmsConfig.iClinica.iClinicaUrl,
			privacyPolicyUrl: UmsConfig.external.privacyPolicyUrl,
			dataPrivacyStatementUrl: UmsConfig.external.dataPrivacyStatementUrl,
			spiroViewerUrl: UmsConfig.external.spiroViewerUrl,
			spiroViewer2019Url: UmsConfig.external.spiroViewer2019Url,
			ecgViewerUrl: UmsConfig.external.ecgViewerUrl,
			logisticsUrl: UmsConfig.external.logisticsUrl,
			deviceMicroPortalViewerUrl: UmsConfig.external.deviceMicroPortalViewerUrl,
			cardiacUploadUrl: UmsConfig.external.cardiacUploadUrl
		},

		// URLs for API calls
		apiUrls: {
			base: baseUrl,
			contactus: {
				siteInfo: baseUrl + helpPrefix + "/contact?studyId={studyId}&studyModeId={studyModeId}",
				submit: baseUrl + helpPrefix + "/contact?studyId={studyId}&studyModeId={studyModeId}",
			},
			dashboard: {
				studyMetrics: baseUrl + dashboardPrefix + "/study-metrics?studyId={studyId}&studyModeId={studyModeId}",
				studyMetricsDetails: baseUrl + dashboardPrefix + "/study-metrics/details?studyId={studyId}&studyModeId={studyModeId}",
				enrollment: baseUrl + dashboardPrefix + "/enrollment-data?studyId={studyId}&studyModeId={studyModeId}",
				openDcrs: baseUrl + dashboardPrefix + "/open-dcrs?studyId={studyId}&studyModeId={studyModeId}",
				visitOverview: baseUrl + dashboardPrefix + "/visit-overview?studyId={studyId}&studyModeId={studyModeId}&showRecent={showRecent}"
			},
			configuration: {
				siteMap: baseUrl + configurationPrefix + "/navigationitems?studyId={studyId}",
				userPermissionOnSite: baseUrl + configurationPrefix + "/user-permission-site?studyId={studyId}&studyModeId={studyModeId}&permission={permissionName}&siteId={siteId}",
				userStudySettings: baseUrl + configurationPrefix + "/userstudysettings?studyId={studyId}&studyModeId={studyModeId}",
				dashboard: baseUrl + configurationPrefix + "/dashboard-items?studyId={studyId}&studyModeId={studyModeId}",
				log: baseUrl + "/logging/add?studyId={studyId}",
				subjectsRange: baseUrl + configurationPrefix + "/subjectranges?studyId={studyId}",
				permissions: baseUrl + configurationPrefix + "/permissions?studyId={studyId}&studyModeId={studyModeId}",
				eproPermissions: baseUrl + configurationPrefix + "/epro-permissions?studyId={studyId}&studyModeId={studyModeId}",
				spirometry: baseUrl + configurationPrefix + "/spirometrysettings?studyId={studyId}",
				epro: baseUrl + configurationPrefix + "/eprosettings?studyId={studyId}",
				study: baseUrl + configurationPrefix + "/studysettings?studyId={studyId}&studyModeId={studyModeId}",

				prsVersion: baseUrl + configurationPrefix + "/prsversions?studyId={studyId}",
				clinicalDataColumns: baseUrl + configurationPrefix + "/clinicaldatacolumns?studyId={studyId}&studyModeId={studyModeId}&moduleId={moduleId}",
				accessLevels: baseUrl + configurationPrefix + "/accessLevels?studyId={studyId}&studyModeId={studyModeId}",
				studyModes: baseUrl + configurationPrefix + "/study-modes?studyId={studyId}",
				myDeviceAccountInfo: baseUrl + configurationPrefix + "/my-device-account-info?studyId={studyId}&studyModeId={studyModeId}",
				assignDevice: baseUrl + configurationPrefix + "/assign-device?studyId={studyId}&studyModeId={studyModeId}",

				studyVocabulary: baseUrl + configurationPrefix + "/study-vocabulary?studyId={studyId}",
				subjectDemographicsSettings: baseUrl + configurationPrefix + "/demographics-settings?studyId={studyId}&studyModeId={studyModeId}",
			},
			userContacts: {
				completeIncompleteCount: baseUrl + userContactPrefix + "/complete-incomplete/count?studyId={studyId}",
				incompletesCount: baseUrl + userContactPrefix + "/incompletes/count?studyId={studyId}",
				fieldConfig: baseUrl + userContactPrefix + "/field-config?studyId={studyId}",
				incompleteContacts: baseUrl + userContactPrefix + "/incompletes?studyId={studyId}",
				completeContacts: baseUrl + userContactPrefix + "/completes?studyId={studyId}",
				updateContact: baseUrl + userContactPrefix + "/update?studyId={studyId}&contactId={contactId}",
				linkContact: baseUrl + userContactPrefix + "/link?studyId={studyId}&sourceContactId={sourceContactId}&targetContactId={targetContactId}&telephone={telephone}&fax={fax}&mobile={mobile}&institutionName={institutionName}"

			},
			dataManagement: {
				ticketsViewConfig: baseUrl + dataManagementPrefix + "/tickets/view-config?studyId={studyId}&studyModeId={studyModeId}",
				tickets: baseUrl + dataManagementPrefix + "/tickets?studyId={studyId}&studyModeId={studyModeId}&forExport={forExport}&{oDataQuery}",
				ticketdata: baseUrl + dataManagementPrefix + "/tickets/{ticketId}?studyId={studyId}&studyModeId={studyModeId}",
				createTicket: baseUrl + dataManagementPrefix + "/tickets/create?studyId={studyId}&studyModeId={studyModeId}",
				answerTicket: baseUrl + dataManagementPrefix + "/tickets/{ticketId}/answer?studyId={studyId}&studyModeId={studyModeId}",
				sitesForNewDcr: baseUrl + dataManagementPrefix + "/sites-for-permission?studyId={studyId}&studyModeId={studyModeId}",
				subjects: baseUrl + dataManagementPrefix + "/subjects?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}",
				subjectVisitEvents: baseUrl + dataManagementPrefix + "/subjects/{subjectId}/visit-events-for-dcr/?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&visitName={visitName}",
				demographics: baseUrl + dataManagementPrefix + "/subjects/{subjectId}/demographics?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&transmittalId={transmittalId}",
				subjectDemographics: baseUrl + dataManagementPrefix + "/subjects/{subjectId}/demographics?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}",
				visitDemographics: baseUrl + dataManagementPrefix + "/subjects/{subjectId}/visit-demographics?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&visitParentId={visitParentId}",
				subjectVisitDetails: baseUrl + dataManagementPrefix + "/subjects/{subjectId}/visit-details-for-dcr?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&visitName={visitName}",
				subjectEventDetails: baseUrl + dataManagementPrefix + "/subjects/{subjectId}/visits-for-dcr/events/{eventId}/?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&visitName={visitName}",
				subjectVisits: baseUrl + dataManagementPrefix + "/subjects/{subjectId}/visits-for-dcr?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}",
				createDcrPermissionForSite: baseUrl + dataManagementPrefix +
				"/tickets/permissions/create-for-site/{siteId}?studyId={studyId}&studyModeId={studyModeId}"
			},
			deviceManagement: {
				unassignDevice: baseUrl + deviceManagementPrefix + "/unassign-device?studyId={studyId}&studyModeId={studyModeId}",
				getDeactiveDeviceReasons: baseUrl + deviceManagementPrefix + "/deactive-device-reasons?studyId={studyId}&studyModeId={studyModeId}",
				subjectDevices: baseUrl + deviceManagementPrefix + "/subject-devices?studyId={studyId}&studyModeId={studyModeId}&deviceIds={deviceIds}&tdtId={tdtId}&mdtId={mdtId}&{oDataQuery}",
				siteDevices: baseUrl + deviceManagementPrefix + "/site-devices?studyId={studyId}&studyModeId={studyModeId}&deviceId={deviceId}&{oDataQuery}",
				getUnlockCode: baseUrl + deviceManagementPrefix + '/unlock-code?studyId={studyId}&studyModeId={studyModeId}&date={date}',
				getEcoaTabletStartupCode: baseUrl + deviceManagementPrefix + '/ecoa-tablet-startup-code?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}',
				getEcoaHandheldAdminAccessCode: baseUrl + deviceManagementPrefix + '/ecoa-handheld-admin-access-code?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}',
				getSiteDeviceInfo: baseUrl + deviceManagementPrefix + '/site-device-properties?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&deviceId={deviceId}&deviceSerialNumber={deviceSerialNumber}',
				getSubjectDeviceInfo: baseUrl + deviceManagementPrefix + '/subject-device-properties?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&subjectId={subjectId}&eproSubjectId={eproSubjectId}&deviceId={deviceId}&deviceSerialNumber={deviceSerialNumber}'
			},
			clinicalDataEntry: {
				hasOpenTasks: baseUrl + clinicalDataEntryPrefix + '/has-open-tasks?studyId={studyId}&studyModeId={studyModeId}',
				getAll: baseUrl + clinicalDataEntryPrefix + '?studyId={studyId}&studyModeId={studyModeId}',
				getOne: baseUrl + clinicalDataEntryPrefix + '/{id}?studyId={studyId}&studyModeId={studyModeId}',
				getStudiesForUser: baseUrl + clinicalDataEntryPrefix + '/studies?studyId={studyId}&studyModeId={studyModeId}&cdeMode={cdeMode}',
				getSites: baseUrl + clinicalDataEntryPrefix + "/sites?studyId={studyId}&studyModeId={studyModeId}",
				getStudyConfig: baseUrl + clinicalDataEntryPrefix + '/study-config?studyId={studyId}&testDataType={testDataType}',
				getSubjectsList: baseUrl + clinicalDataEntryPrefix + '/subjects?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}',
				getTechnicians: baseUrl + clinicalDataEntryPrefix + '/technicians?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}',
				getPeriods: baseUrl + clinicalDataEntryPrefix + '/periods?studyId={studyId}&studyModeId={studyModeId}',
				getVisitSchedules: baseUrl + clinicalDataEntryPrefix + '/visits?studyId={studyId}&studyModeId={studyModeId}&testDataTypeId={testDataTypeId}&proficiency={proficiency}&periodId={period}&cdeMode={cdeMode}',
				getVisitTasks: baseUrl + clinicalDataEntryPrefix + '/visit-tasks?studyId={studyId}&studyModeId={studyModeId}&visitId={visitId}&cdeMode={cdeMode}',
				getSpirometryParameters: baseUrl + clinicalDataEntryPrefix + '/spirometry-parameters?studyId={studyId}&studyModeId={studyModeId}&testDataType={testDataType}',
				getEcgParameters: baseUrl + clinicalDataEntryPrefix + '/ecg-parameters?studyId={studyId}&testDataType={testDataType}',
				getSubjectDemographics: baseUrl + clinicalDataEntryPrefix + '/subject-demographics?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&subjectId={subjectId}&cdeMode={cdeMode}',
				getSubjectDemographicsConfig: baseUrl + clinicalDataEntryPrefix + '/subject-demographics-config?studyId={studyId}&studyModeId={studyModeId}&cdeMode={cdeMode}',

				createEntry: baseUrl + clinicalDataEntryPrefix + '?studyId={studyId}&studyModeId={studyModeId}',
				updateEntry: baseUrl + clinicalDataEntryPrefix + '/{id}?studyId={studyId}&studyModeId={studyModeId}',
				updateEntryStatus: baseUrl + clinicalDataEntryPrefix + '/{id}/update-status?studyId={studyId}&studyModeId={studyModeId}',
				requireMoreInfo: baseUrl + clinicalDataEntryPrefix + '/{id}/more-info?studyId={studyId}&studyModeId={studyModeId}',
				lockEntry: baseUrl + clinicalDataEntryPrefix + '/{id}/lock?studyId={studyId}&studyModeId={studyModeId}',
				unLockEntry: baseUrl + clinicalDataEntryPrefix + '/{id}/unlock?studyId={studyId}&studyModeId={studyModeId}',
				getLockStatus: baseUrl + clinicalDataEntryPrefix + '/{id}/lock-status?studyId={studyId}&studyModeId={studyModeId}',
				checkIfEventAlreadyExists: baseUrl + clinicalDataEntryPrefix + '/event-exists?studyId={studyId}&studyModeId={studyModeId}',
				checkIfVisitAlreadyExists: baseUrl + clinicalDataEntryPrefix + '/visit-exists?studyId={studyId}&studyModeId={studyModeId}',
				generateAuditReport: baseUrl + clinicalDataEntryPrefix + '/audit-report?studyId={studyId}&studyModeId={studyModeId}'
			},
			remoteFile: {
				getFile: baseUrl + remoteFilePrefix + '?studyId={studyId}&studyModeId={studyModeId}&url={url}&reportName={reportName}&isInline={isInline}',
				getPostFileUrl: baseUrl + remoteFilePrefix + '?token={token}&studyId={studyId}&studyModeId={studyModeId}',
				getDcrFile: baseUrl + remoteFilePrefix + '/dcr?studyId={studyId}&studyModeId={studyModeId}&url={url}&reportName={reportName}&isInline={isInline}',
				getDcrPostFileUrl: baseUrl + remoteFilePrefix + '/dcr?token={token}&studyId={studyId}&studyModeId={studyModeId}',
				removeDcrFileUrl: baseUrl + dataManagementPrefix + "/tickets/remove-attachment?studyId={studyId}&studyModeId={studyModeId}",
				getPostFileUrlCommon: baseUrl + remoteFilePrefix + '/common?token={token}&studyId={studyId}&studyModeId={studyModeId}&type={type}',
				removeFileCommon: baseUrl + remoteFilePrefix + "/common?studyId={studyId}&studyModeId={studyModeId}&type={type}&url={url}",
				removeFilesCommon: baseUrl + remoteFilePrefix + "/common/remove-files?studyId={studyId}&studyModeId={studyModeId}&type={type}"
			},
			clinicalDataEntryPft: {
				ticketsViewConfig: baseUrl + clinicalDataEntryPrefix + "/tickets/view-config?studyId={studyId}&studyModeId={studyModeId}",
				tickets: baseUrl + clinicalDataEntryPrefix + "/?studyId={studyId}&studyModeId={studyModeId}"
			},
			downloadFiles: {
				postFileUrl: baseUrl + downloadFiles + '/file?studyId={studyId}&studyModeId={studyModeId}',
				deleteFileUrl: baseUrl + downloadFiles + '/file/{id}?studyId={studyId}&studyModeId={studyModeId}',
				getAllFilesUrl: baseUrl + downloadFiles + '?studyId={studyId}&studyModeId={studyModeId}&{oDataQuery}',
				getSingleFileUrl: baseUrl + downloadFiles + '/file/{id}?studyId={studyId}&studyModeId={studyModeId}&token={token}'
			},
			admin: {
				studyConfig: baseUrl + adminPrefix + "/study-configuration?studyId={studyId}&studyModeId={studyModeId}",
				studyTracking: baseUrl + adminPrefix + "/study-tracking?studyId={studyId}&studyModeId={studyModeId}",
				userTracking: baseUrl + adminPrefix + "/user-tracking?studyId={studyId}&studyModeId={studyModeId}",
				userLoginTracking: baseUrl + adminPrefix + "/user-login-tracking?studyId={studyId}&studyModeId={studyModeId}",
				moduleTracking: baseUrl + adminPrefix + "/module-tracking?studyId={studyId}&studyModeId={studyModeId}",
				studies: baseUrl + adminPrefix + "/studies?studyId={studyId}&studyModeId={studyModeId}",
				trackingReport: baseUrl + adminPrefix + "/user-action-tracking-report?studyId={studyId}&studyModeId={studyModeId}&trackingUserLogin={trackingUserLogin}"
			},
			logistics: {
				config: baseUrl + logisticsPrefix + '/config?studyId={studyId}&studyModeId={studyModeId}',
				supplyorderdata: baseUrl + logisticsPrefix + "/supply-order-data?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}",
				createorder: baseUrl + logisticsPrefix + "/create-order?studyId={studyId}&studyModeId={studyModeId}",
				supplyorderlist: baseUrl + logisticsPrefix + "/supply-order?studyId={studyId}&studyModeId={studyModeId}",
				supplyorderdetails: baseUrl + logisticsPrefix + "/supply-order-details?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&orderId={orderId}",
				shipmenttracking: baseUrl + logisticsPrefix + "/shipment-tracking?studyId={studyId}&studyModeId={studyModeId}",
				shipmenttrackingdetails: baseUrl + logisticsPrefix + "/shipment-tracking-details?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&orderId={orderId}",
				returnrequests: baseUrl + logisticsPrefix + "/return-requests?studyId={studyId}&studyModeId={studyModeId}",
				permissions: baseUrl + logisticsPrefix + "/permission?studyId={studyId}&studyModeId={studyModeId}",
				sitesForPermission: baseUrl + logisticsPrefix + "/sites-for-permission?studyId={studyId}&studyModeId={studyModeId}"
			},
			announcements: {
				announcements: baseUrl + announcementPrefix + "?studyId={studyId}&studyModeId={studyModeId}",
				all: baseUrl + announcementPrefix + "/all?studyId={studyId}&studyModeId={studyModeId}",
				announcementfile: baseUrl + announcementPrefix + "/{announcementId}?studyId={studyId}&studyModeId={studyModeId}",
				details: baseUrl + announcementPrefix + "/{announcementId}/details?studyId={studyId}&studyModeId={studyModeId}&isAdmin={isAdmin}",
				updateannouncement: baseUrl + announcementPrefix + "/{announcementId}/update?studyId={studyId}&studyModeId={studyModeId}",
				permissions: baseUrl + announcementPrefix + "/permission?studyId={studyId}&studyModeId={studyModeId}",
				dashboardAnnouncement: baseUrl + announcementPrefix + '/dashboard-default?studyId={studyId}&studyModeId={studyModeId}',
				dashboardAnnouncementDetails: baseUrl + announcementPrefix + '/dashboard-details?studyId={studyId}&studyModeId={studyModeId}'
			},
			refMat: {
				folders: baseUrl + refMatPrefix + "/folders?studyId={studyId}&studyModeId={studyModeId}",
				files: baseUrl + refMatPrefix + "/folders/{folderId}/files?studyId={studyId}&studyModeId={studyModeId}",
				assignedstudies: baseUrl + refMatPrefix + "/folders/{folderId}/assigned-studies?studyId={studyId}&studyModeId={studyModeId}",
				availablestudies: baseUrl + refMatPrefix + "/folders/{folderId}/all-studies?studyId={studyId}&studyModeId={studyModeId}",
				permissions: baseUrl + refMatPrefix + "/permission?studyId={studyId}&studyModeId={studyModeId}",
				folder: baseUrl + refMatPrefix + "/folders/{folderId}?studyId={studyId}&studyModeId={studyModeId}",
				file: baseUrl + refMatPrefix + "/folders/{folderId}/files/{fileId}?studyId={studyId}&studyModeId={studyModeId}",
				addfile: baseUrl + refMatPrefix + "/files?studyId={studyId}&studyModeId={studyModeId}&folderId={folderId}&unzipIfZipped={unzipIfZipped}&isActive={isActive}",
				updatefile: baseUrl + refMatPrefix + "/files/{fileId}?studyId={studyId}&studyModeId={studyModeId}&isActive={isActive}&folderId={folderId}",
				updatefilestatus: baseUrl + refMatPrefix + "/files/{fileId}/update?studyId={studyId}&studyModeId={studyModeId}&isActive={isActive}&folderId={folderId}"
			},
			sqf: {
				submittedSqf: baseUrl + "sqf/submitted?studyId={studyId}&studyModeId={studyModeId}",
				savedSqf: baseUrl + "sqf/saved?studyId={studyId}&studyModeId={studyModeId}",
				getSitePI: baseUrl + "sqf/{sqfId}/site-pi?studyId={studyId}&studyModeId={studyModeId}",
				saveSitePI: baseUrl + "sqf/site-pi?studyId={studyId}&studyModeId={studyModeId}",
				getSqfSummary: baseUrl + "sqf/{sqfId}?studyId={studyId}&studyModeId={studyModeId}",
				getContact: baseUrl + "sqf/{sqfId}/contacts/{contactId}?studyId={studyId}&studyModeId={studyModeId}",
				getContacts: baseUrl + "sqf/{sqfId}/contacts?studyId={studyId}&studyModeId={studyModeId}",
				saveContact: baseUrl + "sqf/{sqfId}/contacts/{contactId}?studyId={studyId}&studyModeId={studyModeId}",
				getSiteInfo: baseUrl + "sqf/{sqfId}/site-info/?studyId={studyId}&studyModeId={studyModeId}",
				saveSiteInfo: baseUrl + "sqf/{sqfId}/site-info?studyId={studyId}&studyModeId={studyModeId}",
				getRoleContacts: baseUrl + "sqf/{sqfId}/roles?studyId={studyId}&studyModeId={studyModeId}",
				saveRoleContacts: baseUrl + "sqf/{sqfId}/roles?studyId={studyId}&studyModeId={studyModeId}",
				getTimezones: baseUrl + "sqf/timezones?studyId={studyId}&studyModeId={studyModeId}",
				getCountries: baseUrl + "sqf/countries?studyId={studyId}&studyModeId={studyModeId}",
				getCountriesWithId: baseUrl + "sqf/countries/withid?studyId={studyId}&studyModeId={studyModeId}",
				getUsStates: baseUrl + "sqf/countries/us/states?studyId={studyId}&studyModeId={studyModeId}",
				deleteContact: baseUrl + "sqf/{sqfId}/contacts/{contactId}?studyId={studyId}&studyModeId={studyModeId}",
				submitSqf: baseUrl + "sqf/{sqfId}/submit?studyId={studyId}&studyModeId={studyModeId}",
				deleteSqf: baseUrl + "sqf/{sqfId}?studyId={studyId}&studyModeId={studyModeId}"
			},
			studyPermissions: {
				getStudyUsers: baseUrl + "study-permissions/study-users?studyId={studyId}&studyModeId={studyModeId}",
				getUser: baseUrl + "study-permissions/{userId}?studyId={studyId}&studyModeId={studyModeId}&isSiteAdmin={isSiteAdmin}&siteId={siteId}&countryId={countryId}",
				getSiteAdmins: baseUrl + "study-permissions/site-administrators?studyId={studyId}&studyModeId={studyModeId}",
				getCountryUsers: baseUrl + "study-permissions/country-users?studyId={studyId}&studyModeId={studyModeId}",
				getSiteUsers: baseUrl + "study-permissions/site-users?studyId={studyId}&studyModeId={studyModeId}",
				getStudyPermissionsConfig: baseUrl + "study-permissions/config?studyId={studyId}&studyModeId={studyModeId}",
				approveUserRolesRemoval: baseUrl + "study-permissions/request-removal/{requestId}/approve?studyId={studyId}&studyModeId={studyModeId}",
				getContactConfig: baseUrl + "study-permissions/contact/config?studyId={studyId}&studyModeId={studyModeId}",
				getSponsorConfig: baseUrl + "study-permissions/sponsor/config?studyId={studyId}&studyModeId={studyModeId}",
				createContact: baseUrl + "study-permissions/contact/create?studyId={studyId}&studyModeId={studyModeId}",
				createSponsorUser: baseUrl + "study-permissions/sponsor/create?studyId={studyId}&studyModeId={studyModeId}",
				getSiteRoles: baseUrl + "study-permissions/{siteId}/roles?studyId={studyId}&studyModeId={studyModeId}",
				editUserRoles: baseUrl + "study-permissions/edit-user-roles?studyId={studyId}&studyModeId={studyModeId}",
				deleteAllUserRoles: baseUrl + "study-permissions/removal-all-user-roles?studyId={studyId}&studyModeId={studyModeId}",
				checkSiteUserAlreadyExists: baseUrl + "study-permissions/sites/{siteId}/users/{email}/exists?studyId={studyId}&studyModeId={studyModeId}",
				checkSponsorUserAlreadyExists: baseUrl + "study-permissions/sponsor-users/{email}/exists?studyId={studyId}&studyModeId={studyModeId}"
			},
			spirometry: {
				getSubjectInfo: baseUrl + spirometryPrefix + "/subject-info?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&subjectId={subjectId}&eventId={eventId}",
			},
			// syntax here "moduleName" : { "submodule" : "value", "property": value}
			clinicalData: {
				countries: baseUrl + clinicalDataPrefix +
				"/countries?studyId={studyId}&studyModeId={studyModeId}&{oDataQuery}",
				countriesMinimal: baseUrl + clinicalDataPrefix +
				"/countries/minimal?studyId={studyId}&studyModeId={studyModeId}",
				subjects: baseUrl + clinicalDataPrefix +
				"/subjects?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}&{oDataQuery}",
				subjectsMinimal: baseUrl + clinicalDataPrefix +
				"/subjects/minimal?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}",
				eventsMinimal: baseUrl + clinicalDataPrefix + "/subjects/{subjectId}/events/minimal?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}",
				visits: baseUrl + clinicalDataPrefix + "/subjects/{subjectId}/visits?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}&withScheduledEvents={withScheduledEvents}&{oDataQuery}",
				eventReports: baseUrl + clinicalDataPrefix + "/subjects/{subjectId}/event-reports?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&eventId={eventId}&documentTypeId={documentTypeId}",
				eventReportsExpert: baseUrl + clinicalDataPrefix + "/subjects/{subjectId}/event-reports/expert?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}",
				eventAttachments: baseUrl + clinicalDataPrefix + "/subjects/{subjectId}/attachments?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&eventId={eventId}",
				sites: baseUrl + clinicalDataPrefix + "/sites?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&{oDataQuery}",
				site: baseUrl + clinicalDataPrefix + "/sites/{siteId}?studyId={studyId}&studyModeId={studyModeId}",
				sitesMinimal: baseUrl + clinicalDataPrefix + "/sites/minimal?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}",
				countryInfo: baseUrl + clinicalDataPrefix + "/country-info?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}",
				siteInfo: baseUrl + clinicalDataPrefix + "/site-info?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}",
				siteTimezone: baseUrl + clinicalDataPrefix + "/site-timezone?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}",
				subjectInfo: baseUrl + clinicalDataPrefix + "/subject-device-info?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&subjectId={subjectId}",
				subjectDeviceInfo: baseUrl + clinicalDataPrefix + "/subject-device-info?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&subjectId={subjectId}",
				siteDeviceInfo: baseUrl + clinicalDataPrefix + "/site-device-info?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}",
				subjectCalendar: baseUrl + clinicalDataPrefix + "/sites/{siteId}/calendars/subjects/{subjectId}?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&subjectId={subjectId}&isVisitCalendar={isVisitCalendar}&from={from}&to={to}&{oDataQuery}",
				siteCalendar: baseUrl + clinicalDataPrefix + "/sites/{siteId}/calendars/subjects?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&isVisitCalendar={isVisitCalendar}&from={from}&to={to}&{oDataQuery}",
				epro: {
					audioData: baseUrl + eproPrefix + "/subjects/{eproSubjectId}/responses/{responseId}/audio?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&token={token}",
					qrCode: baseUrl + eproPrefix + "/subjects/{eproSubjectId}/qr-code?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}",
					ecoa: {
						dailySessions: baseUrl + eproPrefix + "/am3-diarypro/subjects/{eproSubjectId}/dailysessions?studyId={studyId}&studyModeId={studyModeId}&tdtId={tdtId}&siteId={siteId}&eventId={eventId}&mdtId={mdtId}&{oDataQuery}",
						sessionData: baseUrl + eproPrefix + "/am3-diarypro/subjects/{eproSubjectId}/sessionData?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&sessionDate={sessionDate}&mdtId={mdtId}",
						sessionResponses: baseUrl + eproPrefix + "/am3-diarypro/subjects/{eproSubjectId}/session-responses?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&eventId={eventId}&sessionDate={sessionDate}&sessionTime={sessionTime}&tdtId={tdtId}&mdtId={mdtId}&sessionTypeId={sessionTypeId}&sessionId={sessionId}",
						parameters: baseUrl + eproPrefix + "/am3-diarypro/subjects/{eproSubjectId}/parameters?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&eventId={eventId}&sessionDate={sessionDate}&sessionTime={sessionTime}&mdtId={mdtId}&sessionTypeId={sessionTypeId}",
						parameterDefs: baseUrl + eproPrefix + "/am3-diarypro/parameter-definitions?studyId={studyId}&mdtId={mdtId}",
						clinicalPrompts: baseUrl + eproPrefix + "/clinical-prompts?studyId={studyId}&mdtId={mdtId}&moduleId={moduleId}&languageId={languageId}"
					},
					ecoaphoneQuestionnaire: baseUrl + eproPrefix + "/subjects/{eproSubjectId}/questionnaires?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&eventId={eventId}&tdtId={tdtId}",
					scoringResults: baseUrl + eproPrefix + "/subjects/{eproSubjectId}/scoring-results?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&eventId={eventId}&tdtId={tdtId}",
					alertsTriggered: baseUrl + eproPrefix + "/subjects/{eproSubjectId}/alerts-triggered?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&tdtId={tdtId}&alertType={alertType}",
					closedVisit: baseUrl + eproPrefix + "/subjects/{eproSubjectId}/closevisits?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&eventId={eventId}&tdtId={tdtId}",
					createSubject: {
						data: baseUrl + eproPrefix + "/subjects/create-data?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}",
						create: baseUrl + eproPrefix + "/subjects/create?studyId={studyId}&studyModeId={studyModeId}"
					},
					editSubject: {
						data: baseUrl + eproPrefix + "/subjects/{eproSubjectId}/edit-data?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}&subjectId={subjectId}",
						update: baseUrl + eproPrefix + "/subjects/{eproSubjectId}/update?studyId={studyId}&studyModeId={studyModeId}&subjectId={subjectId}",
						activate: baseUrl + eproPrefix + "/subjects/{eproSubjectId}/activate?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}",
						deactivate: baseUrl + eproPrefix + "/subjects/{eproSubjectId}/deactivate?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}"
					},
					data: baseUrl + eproPrefix + "/subjects/{eproSubjectId}/edit-data?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}",
					pin: {
						data: baseUrl + eproPrefix +
						"/subjects/{eproSubjectId}/pin-data?studyId={studyId}&studyModeId={studyModeId}",
						send: baseUrl + eproPrefix +
						"/subjects/{eproSubjectId}/send-set-pin?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}"
					},
					contact: {
						data: baseUrl + eproPrefix +
						"/subjects/{eproSubjectId}/contact-data?studyId={studyId}&studyModeId={studyModeId}&eproSubjectId={eproSubjectId}",
						update: baseUrl + eproPrefix +
						"/subjects/{eproSubjectId}/contact-data/update?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}"
					},
					closeVisit: {
						canClose: baseUrl + eproPrefix + "/subjects/{eproSubjectId}/visits/can-close?studyId={studyId}&studyModeId={studyModeId}",
						data: baseUrl + eproPrefix + "/subjects/{eproSubjectId}/visits/close-data?studyId={studyId}&studyModeId={studyModeId}",
						close: baseUrl + eproPrefix + "/subjects/{eproSubjectId}/visits/close?studyId={studyId}&studyModeId={studyModeId}&subjectId={subjectId}"
					}
				},
				iSpiro: {
					actions: baseUrl + iSpiroPrefix + "/subjects/{subjectId}/ispiro-actions?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}",
					studyActivities: baseUrl + iSpiroPrefix + "/study-activities?studyId={studyId}&studyModeId={studyModeId}",
					lostDevice: baseUrl + iSpiroPrefix + "/subjects/{subjectId}/lost-device?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}&actionId={actionId}",
					resetPin: baseUrl + iSpiroPrefix + "/subjects/{subjectId}/reset-pin?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}",
					remoteClose: baseUrl + iSpiroPrefix + "/subjects/{subjectId}/remote-close?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}",
					subjects: baseUrl + iSpiroPrefix + "/subjects?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}&actionId={actionId}",
					subject: baseUrl + iSpiroPrefix + "/subjects/{technicalSubjectId}?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}",
					qrCode: baseUrl + iSpiroPrefix + "/qr-code?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&subjectId={subjectId}&actionId={actionId}",
					createSubject: {
						data: baseUrl + iSpiroPrefix + "/subjects/create-data?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}",
						create: baseUrl + iSpiroPrefix + "/subjects/create?studyId={studyId}&studyModeId={studyModeId}"
					},
					cancelScheduledEvent: baseUrl + iSpiroPrefix + "/scheduled-events/{scheduledEventId}/?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&subjectKey={subjectKey}&visitId={visitId}&visitScheduleTaskId={visitScheduleTaskId}&scheduleDateTime={scheduleDateTime}",
					scheduledEvents: baseUrl + iSpiroPrefix + "/scheduled-events/sites/{siteId}/?studyId={studyId}&studyModeId={studyModeId}&subjectKeyFilter={subjectKeyFilter}&from={from}&to={to}",
					scheduledEventsForSubject: baseUrl + iSpiroPrefix + "/scheduled-events/sites/{siteId}/subjects/{subjectId}/?studyId={studyId}&studyModeId={studyModeId}&from={from}&to={to}",
					sitesMinimal: baseUrl + iSpiroPrefix + "/sites/?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}",
					siteDetails: baseUrl + iSpiroPrefix + "/sites/{siteId}?studyId={studyId}&studyModeId={studyModeId}",
					visits: baseUrl + iSpiroPrefix + "/visits/?studyId={studyId}&studyModeId={studyModeId}",
					visitTasks: baseUrl + iSpiroPrefix + "/visit-tasks/?studyId={studyId}&studyModeId={studyModeId}&visitId={visitId}",
					scheduleEvent: baseUrl + iSpiroPrefix + "/scheduled-events/?studyId={studyId}&studyModeId={studyModeId}",
					rescheduleEvent: baseUrl + iSpiroPrefix + "/scheduled-events/?studyId={studyId}&studyModeId={studyModeId}",
					activateVideoSession: baseUrl + iSpiroPrefix + "/scheduled-events/{scheduledEventId}?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&subjectKey={subjectKey}&technicalSubjectId={technicalSubjectId}&visitId={visitId}&visitScheduleTaskId={visitScheduleTaskId}&scheduleDateTime={scheduleDateTime}",
					editSubject: {
						data: baseUrl + iSpiroPrefix + "/subjects/{subjectId}/edit-data?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}",
						update: baseUrl + iSpiroPrefix + "/subjects/{subjectId}/update?studyId={studyId}&studyModeId={studyModeId}",
						dispense: baseUrl + iSpiroPrefix + "/subjects/{subjectId}/dispense-data?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}",
						editDeviceValues: baseUrl + iSpiroPrefix + "/subjects/{subjectId}/edit-device-values?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}",
						updateDeviceValues: baseUrl + iSpiroPrefix + "/subjects/{subjectId}/update-device-values?studyId={studyId}&studyModeId={studyModeId}&forDispense={forDispense}&isRemoteAction={isRemoteAction}"
					},

					generateAuditReport: baseUrl + iSpiroPrefix + '/audit-report?studyId={studyId}&studyModeId={studyModeId}'
				},
				kardia: {
					actions: baseUrl + kardiaPrefix + "/subjects/{subjectId}/kardia-actions?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}",
					studyActivities: baseUrl + kardiaPrefix + "/study-activities?studyId={studyId}&studyModeId={studyModeId}",
					close: baseUrl + kardiaPrefix + "/subjects/{subjectId}/close?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}",
					subjects: baseUrl + kardiaPrefix + "/subjects?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}&actionId={actionId}",
					registrationCode: baseUrl + kardiaPrefix + "/registration-code?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&subjectId={subjectId}&actionId={actionId}",
					createSubject: {
						data: baseUrl + kardiaPrefix + "/subjects/create-data?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}",
						create: baseUrl + kardiaPrefix + "/subjects/create?studyId={studyId}&studyModeId={studyModeId}"
					},
					sitesMinimal: baseUrl + kardiaPrefix + "/sites/?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}",
					editSubject: {
						dispense: baseUrl + kardiaPrefix + "/subjects/{subjectId}/dispense-data?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}",
					}
				}
			},
			ecgData: {
				ecgDataExport: baseUrl + ecgDataPrefix + "/by-transmittal?studyId={studyId}&studyModeId={studyModeId}",
				exportByTransmittal: baseUrl + ecgDataPrefix + "/by-transmittal/export?studyId={studyId}&studyModeId={studyModeId}&columns={columns}&format={format}&studyName={studyName}",
				exportByLead: baseUrl + ecgDataPrefix + "/by-lead/export?studyId={studyId}&studyModeId={studyModeId}&columns={columns}&format={format}&studyName={studyName}"
			},
			sitemaps: {},
			downloads: {
				eventReportDownloads: baseUrl + downloadPrefix + "/reports?studyId={studyId}&studyModeId={studyModeId}&{oDataQuery}",
				eventReportDownloadInfo: baseUrl + downloadPrefix + "/reports/{queueId}/info?studyId={studyId}&studyModeId={studyModeId}"
			},
			notifications: {
				status: baseUrl + notificationPrefix + "/status?studyId={studyId}&studyModeId={studyModeId}"
			},
			gridExport: {
				clinicalData: {
					countries: baseUrl + gridExportPrefix + "/clinical-data/countries?studyId={studyId}&studyModeId={studyModeId}&{oDataQuery}",
					sites: baseUrl + gridExportPrefix + "/clinical-data/sites?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&{oDataQuery}",
					subjects: baseUrl + gridExportPrefix + "/clinical-data/subjects?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}&{oDataQuery}"
				}
			},
			documents: {
				returnRequests: baseUrl + documentPrefix + "/return-request?studyId={studyId}&studyModeId={studyModeId}&id={id}&orderId={orderId}",
				eproAlert: baseUrl + documentPrefix + "/epro-alert?studyId={studyId}&studyModeId={studyModeId}&siteId={siteId}&tdtId={tdtId}&id={id}&eventId={eventId}&eproSubjectId={eproSubjectId}&token={token}&name={name}",
				dataStatusForms: baseUrl + documentPrefix + "/status-forms?studyId={studyId}&studyModeId={studyModeId}&downloadAll={downloadAll}",
				myReports: baseUrl + documentPrefix + "/my-reports?studyId={studyId}&studyModeId={studyModeId}",
				trainingDocuments: baseUrl + documentPrefix + "/training-document?studyId={studyId}&studyModeId={studyModeId}",
				ecgSourceTracings: baseUrl + documentPrefix + "/source-tracings?studyId={studyId}&studyModeId={studyModeId}&id={id}&type={type}&name={name}",
				abpmReports: baseUrl + documentPrefix + "/abpm-reports?studyId={studyId}&studyModeId={studyModeId}&path={path}&name={name}",
				myDownloads: baseUrl + documentPrefix + "/my-downloads?studyId={studyId}&studyModeId={studyModeId}&queueId={queueId}",
				holterReports: baseUrl + documentPrefix + "/holter-reports?studyId={studyId}&studyModeId={studyModeId}",
				eventReports: baseUrl + documentPrefix + "/event-reports/sites/{siteId}/subjects/{subjectId}?studyId={studyId}&studyModeId={studyModeId}&reportId={reportId}",
				eventReportsForReportIds: baseUrl + documentPrefix + "/event-reports-for-reportids?studyId={studyId}&studyModeId={studyModeId}&useZip={useZip}",
				eventReportsForReportIdsAsync: baseUrl + documentPrefix + "/event-reports-for-reportids-async?studyId={studyId}&studyModeId={studyModeId}",
				eventReportsForReportIdsAsyncCount: baseUrl + documentPrefix + "/event-reports-for-reportids-async/count?studyId={studyId}&studyModeId={studyModeId}",
				eventReportsForEventIds: baseUrl + documentPrefix + "/event-reports-for-eventids/sites/{siteId}/subjects/{subjectId}?studyId={studyId}&studyModeId={studyModeId}&eproSubjectId={eproSubjectId}",
				eventReportsForTypes: baseUrl + documentPrefix + "/event-reports/sites/{siteId}/subjects/{subjectId}/fortypes?studyId={studyId}&studyModeId={studyModeId}&eproSubjectId={eproSubjectId}",
				downloadReport: baseUrl + documentPrefix + "/{id}/download/{type}?reportName={reportName}",
				downloadReportFailedNames: baseUrl + documentPrefix + "/{id}/failed",
				downloadReportStatus: baseUrl + documentPrefix + "/{id}/status"
			},
			emailConfirmation: {
				preStartRegistration: baseUrl + emailConfirmationPrefix + "/pre-start",
				startRegistration: baseUrl + emailConfirmationPrefix + "/start",
				loadRegistration: baseUrl + emailConfirmationPrefix + "/load",
				resendConfirmation: baseUrl + emailConfirmationPrefix + "/resend",
				confirmRegistration: baseUrl + emailConfirmationPrefix + "/email/confirm"
			},
			reports: {
				types: baseUrl + reportPrefix + "/types?studyId={studyId}&studyModeId={studyModeId}",
				all: baseUrl + reportPrefix + "/review/all?studyId={studyId}&studyModeId={studyModeId}",
				confirmation: baseUrl + reportPrefix + "/review/confirmation?studyId={studyId}&studyModeId={studyModeId}",
				confirmReports: baseUrl + reportPrefix + "/review/confirm?studyId={studyId}&studyModeId={studyModeId}",
				myReports: baseUrl + reportPrefix + "/reports/my?studyId={studyId}&studyModeId={studyModeId}",
				reportTemplates: baseUrl + reportPrefix + "/report-templates?studyId={studyId}&studyModeId={studyModeId}",
				reportTemplate: baseUrl + reportPrefix + "/report-templates/{templateId}?studyId={studyId}&studyModeId={studyModeId}",
				deleteReport: baseUrl + reportPrefix + "/reports/{reportId}?studyId={studyId}",
				selectionCriteria: baseUrl + reportPrefix + "/reports/{reportInstanceId}/selection-criteria?studyId={studyId}&reportInstanceId={reportInstanceId}",
				availableSelectionCriteria: baseUrl + reportPrefix + "/report-templates/{reportId}/selection-criteria?studyId={studyId}&studyModeId={studyModeId}",
				selectionCriteriaParameters: baseUrl + reportPrefix + "/report-templates/{reportId}/parameter-options/{paramId}?studyId={studyId}&studyModeId={studyModeId}&parentValueId={parentId}&grandParentValueId={grandParentId}",
				createReport: baseUrl + reportPrefix + "/reports/create?studyId={studyId}&studyModeId={studyModeId}",
				trainingDocuments: baseUrl + reportPrefix + "/training-documents?studyId={studyId}&studyModeId={studyModeId}",
				permission: baseUrl + reportPrefix + "/permission?studyId={studyId}&studyModeId={studyModeId}",
				abpmReports: baseUrl + reportPrefix + "/abpm-reports?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}&{oDataQuery}",
				dataStatusForms: baseUrl + reportPrefix + "/status-forms?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}&{oDataQuery}",
				sourceTracings: baseUrl + reportPrefix + "/source-tracings?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}&{oDataQuery}",
				holterReports: baseUrl + reportPrefix + "/holter-reports?studyId={studyId}&studyModeId={studyModeId}&countryId={countryId}&siteId={siteId}&{oDataQuery}"
			},
			studies: {
				recordLogin: baseUrl + studiesPrefix + '/record-login?studyId={studyId}',
				byId: baseUrl + studiesPrefix + '?ids={ids}',
				all: baseUrl + 'studies/all?{oDataQuery}',
				switchConfig: baseUrl + studiesPrefix + '/switch-config?studyId={studyId}&studyModeId={studyModeId}'
			},
			account: {
				resetPassword: baseUrl + accountPrefix + "/reset-password"
			},
			sso: {
				epx: baseUrl + ssoPrefix + '/epx/redirect?studyId={studyId}&clientTimezoneOffset={clientTimezoneOffset}',
				tableau: baseUrl + ssoPrefix + '/tableau/redirect?studyId={studyId}',
				loginUser: baseUrl + ssoPrefix + '/user/login',
				logoutUser: baseUrl + ssoPrefix + '/user/logout',
				validateSignKey: baseUrl + ssoPrefix + '/signature/validate?signKey={signKey}',
				signRedirect: baseUrl + ssoPrefix + '/signature/redirect',
				legacy: baseUrl + ssoPrefix + '/legacy/redirect?studyId={studyId}&token={token}',
				gssoLoginInfo: baseUrl + ssoPrefix + '/gsso/login-info?studyId={studyId}',
				checkSession: baseUrl + ssoPrefix + '/gsso/check-session'
			},
			logi: {
				redirect: baseUrl + logiPrefix + '/redirect?studyId={studyId}&studyMode={studyMode}&reportName={reportName}&token={token}',
				secureKey: baseUrl + logiPrefix + '/secure-key?studyId={studyId}'
			},
			ods: {
				redirect: baseUrl + odsPrefix + '/redirect?studyId={studyId}&studyMode={studyMode}&reportName={reportName}&token={token}',
				secureKey: baseUrl + odsPrefix + '/secure-key?studyId={studyId}'
			}
		},
		log: {
			apiUrl: baseUrl + 'logs',
			apiBufferSize: 20,
			level: UmsConfig.log.serverLogLevel,
			consoleLogLevel: UmsConfig.log.consoleLogLevel
		},
		cacheContext: {
			study: {
				cacheKey: 'study',
				expirationTime: UmsConfig.cache.studyExpirationTime,
				sessionStorage: true,
				localStorage: false
			},
			clinicalData: {
				cacheKey: 'clinicalData',
				expirationTime: UmsConfig.cache.clinicalDataExpirationTime,
				sessionStorage: true,
				localStorage: false
			},
			clinicalDataEntry: {
				cacheKey: 'pft',
				expirationTime: UmsConfig.cache.defaultExpirationTime,
				sessionStorage: true,
				localStorage: false
			},
			help: {
				cacheKey: 'help',
				expirationTime: UmsConfig.cache.helpMappingFileExpirationTime,
				sessionStorage: false,
				localStorage: true,
				hasStudyContext: false
			}
		},
		// constants for programmatic routing
		routes: {
			// todo: temporary set to tickets, use starting page from efdb
			default: 'app.home.dashboard',
			login: 'auth.login',
			resetPassword: 'auth.resetPassword',
			confirm: 'auth.confirm', // NOTE: routes starting with confirm will cause logout when user is logged int
			confirmTokenPath: 'auth.confirmTokenPath',
			confirmLegacy: 'auth.confirmLegacy',
			admin: 'app.home.admin',
			dashboard: 'app.home.dashboard',
			deviceManagement: {
				root: 'app.home.deviceManagement',
				masterscope: 'app.home.deviceManagement.masterscope',
				am3: 'app.home.deviceManagement.am3',
				diarypro: 'app.home.deviceManagement.diarypro',
				ecoaHandheld: 'app.home.deviceManagement.ecoaHandheld',
				sitepro: 'app.home.deviceManagement.sitepro',
				iSpiro: 'app.home.deviceManagement.iSpiro',
				spiroSphere: 'app.home.deviceManagement.spiroSphere',
				ecoaTablet: 'app.home.deviceManagement.ecoaTablet',
				cgm: 'app.home.deviceManagement.cgm',
				feno: 'app.home.deviceManagement.feno',
				flowscreen: 'app.home.deviceManagement.flowscreen',
				easyOnePro: 'app.home.deviceManagement.easyOnePro',
				clarioApp: 'app.home.deviceManagement.clarioApp',
				vivalink: 'app.home.deviceManagement.vivalink',
				cough: 'app.home.deviceManagement.cough'
			},
			studyTracking: 'app.home.admin.studyTracking',
			moduleTracking: 'app.home.admin.moduleTracking',
			userTracking: 'app.home.admin.userTracking',
			userLoginTracking: 'app.home.admin.userLoginTracking',
			studyConfiguration: 'app.home.admin.studyConfiguration',
			sqfList: 'app.home.sqfList',
			sqfListSaved: 'app.home.sqfList.saved',
			sqfListSubmitted: 'app.home.sqfList.submitted',
			ecoa: 'app.home.clinicalData.ecoa',
			clData: 'app.home.clinicalData',
			clDataCountries: 'app.home.clinicalData.countries',
			clDataSites: 'app.home.clinicalData.sites',
			clDataCountrySites: 'app.home.clinicalData.countrySites',
			clDataSubjects: 'app.home.clinicalData.subjects',
			clDataSubjectsISpiroDevice: 'app.home.clinicalData.subjects.iSpiroDevice',
			clDataSiteSubjects: 'app.home.clinicalData.siteSubjects',
			clDataVisits: 'app.home.clinicalData.visits',
			clDataScheduledEvents: 'app.home.clinicalData.scheduledEvents',
			clDataSourceTracing: 'app.home.clinicalData.sourceTracings',
			clDataHolterReports: 'app.home.clinicalData.holterReports',
			clDataStatusForms: 'app.home.clinicalData.statusForms',
			iClinicaDashboard: 'app.home.iclinica',
			spirometry: 'app.home.clinicalData.spirometry',
			spirometryBtrAfter: 'app.home.clinicalData.spirometry.btrAfter',
			spirometryBtrBefore: 'app.home.clinicalData.spirometry.btrBefore',
			spirometryBtrOriginalTrials: 'app.home.clinicalData.spirometry.btrOriginalTrials',
			ecg: 'app.home.clinicalData.ecg',
			deviceMicroPortal: 'app.home.clinicalData.deviceMicroPortal',
			epx: 'app.home.epx',
			eci: 'app.home.eci',
			tickets: 'app.home.tickets',
			ticketDetails: 'app.home.ticketdetails',
			reports: {
				root: 'app.home.reports',
				abpm: 'app.home.reports.abpm',
				holter: 'app.home.reports.holter',
				dataStatusForm: 'app.home.reports.dataStatusForm',
				ecgSourceTracing: 'app.home.reports.ecgSourceTracing'
			},
			createReport: 'app.home.reports.createreport',
			myReports: 'app.home.reports.myreports',
			reportsTraining: 'app.home.reports.training',
			logiReports: 'app.home.reports.logi',
			ecgDataExport: 'app.home.reports.ecgdataexport',
			reportReview: 'app.home.reports.reportreview',
			myDownloads: 'app.home.mydownloads',
			downloads: 'app.home.mydownloads.downloads',
			dataManagementFiles: 'app.home.mydownloads.dmFiles',
			studyPermissions: 'app.home.studyPermissions',
			studyUsers: 'app.home.studyPermissions.studyUsers',
			studyUsersEdit: 'app.home.studyPermissions.studyUsers.edit',
			studyUsersDelete: 'app.home.studyPermissions.studyUsers.delete',
			siteUsers: 'app.home.studyPermissions.siteUsers',
			siteUsersEdit: 'app.home.studyPermissions.siteUsers.edit',
			siteUsersDelete: 'app.home.studyPermissions.siteUsers.delete',
			siteAdmins: 'app.home.studyPermissions.siteAdmins',
			siteAdminsEdit: 'app.home.studyPermissions.siteAdmins.edit',
			siteAdminsDelete: 'app.home.studyPermissions.siteAdmins.delete',
			countryUsers: 'app.home.studyPermissions.countryUsers',
			countryUsersEdit: 'app.home.studyPermissions.countryUsers.edit',
			countryUsersDelete: 'app.home.studyPermissions.countryUsers.delete',
			logisticsMicroportal: {
				root: 'app.home.logisticsMicroportal',
				supplyOrder: 'app.home.logisticsMicroportal.supplyOrder',
				shipmentTracking: 'app.home.logisticsMicroportal.shipmentTracking',
				returnRequests: 'app.home.logisticsMicroportal.returnRequests',
			},
			cardiacUpload: 'app.home.cardiacUpload',
			logistics: 'app.home.logistics',
			supplyOrder: 'app.home.logistics.supplyorder',
			shipmentTracking: 'app.home.logistics.shipmenttracking',
			returnRequests: 'app.home.logistics.returnrequests',
			announcements: 'app.home.announcements',
			refMaterials: 'app.home.refMaterials',
			cdePftList: 'app.home.cdePftList',
			cdePftEntry: 'app.home.cdePftList.entry',
			cdePftNewEntry: 'app.home.cdePftList.newEntry',
			logi: 'app.home.logi',
			authRedirect: 'auth.redirect',
			contactCustomerCare: 'app.help.contact'
		},
		popups: {
			sqfWizard: 'MSP3.Sqf.SqfWizard',
			ticketDetails: 'MSP3.DataManagement.TicketDetails',
			answerDcr: 'MSP3.DataManagement.AnswerDcr',
			createTicket: 'MSP3.DataManagement.CreateTicket',
			orderDetails: 'MSP3.Logistics.OrderDetails',
			paperPftEntry: 'MSP3.PaperPft.Entry'
		},
		sigType: {
			createSubject: 'CreateSubject',
			createEditISpiroSubject: 'CreateEditISpiroSubject',
			createEditKardiaSubject: 'CreateEditKardiaSubject',
			editISpiroDeviceValues: 'EditISpiroDeviceValues',
			editSubject: 'EditSubject',
			answerDcr: 'AnswerDcr',
			createDcr: 'CreateNewDcr',
			createOrder: 'CreateNewOrder',
			createUser: 'CreateSiteUser',
			createSponsorUser: 'CreateSponsorUser',
			createPaperPft: 'CreateNewPaperPFT',
			rejectPaperPft: 'RejectPaperPft',
			approvePaperPft: 'ApprovePaperPft',
			updatePaperPft: 'UpdatePaperPft',
			secondPaperPft: 'SecondPaperPft',
			submitPaperPft: 'SubmitPaperPft',
			editRoles: 'EditRoles',
			deleteUser: 'deleteUser'
		},
		iSpiroActionId: {
			dispense: 1,
			close: 2,
			lost: 3,
			edit: 4,
			resetPin: 5,
			scheduleEvent: 6,
			rescheduleEvent: 7,
			activateVideoSession: 8,
			noActionAvailable: -1
		},
		kardiaActionId: {
			dispense: 1,
			close: 2,
			noActionAvailable: -1
		},
		deviceIds: {
			masterscope: '1',
			am3: '213',
			diaryPro: '215',
			siteProCgm: '216',
			ecoaHandheldTablet: '218',
			feno: '217',
			flowscreen: '300',
			easyOnePro: '301',
			spiroSphere: '502',
			iSpiro: '11',
			clarioApp: '20',
			vivalink: '602,603',
			cough: '901'
		},
		logisticsPage: {
			supplyOrder: 'orders',
			shipmentTracking: 'shipment-tracking',
			returnRequests: 'return-requests'
		},
		studyMode: {
			study: 0,
			training: 1,
			proficiency: 2,
			sample: 3,
			clientUat: 4
		},
		// authentication config items
		auth: {
			tokenUrl: UmsConfig.auth.tokenUrl,
			gssoLoginUrl: UmsConfig.auth.gssoLoginUrl,
			redirectUri: UmsConfig.auth.redirectUri,
			responseType: 'token+id_token',
			scope: 'openid%20profile',
			nonce: '0',
			grantType: 'password',
			clientId: 'msp3'
		},
		gsso: {
			navigatorHeaderBaseUrl: UmsConfig.gsso.navigatorHeaderBaseUrl,
			baseUrl: UmsConfig.gsso.gssoBaseUrl,
			getBaseUrl: () => {
				return (UmsConfig.gsso.gssoBaseUrl.endsWith('/'))
					? UmsConfig.gsso.gssoBaseUrl
					: `${UmsConfig.gsso.gssoBaseUrl}/`;
			},
			getNavigatorHeaderBaseUrl: () => {
				return (UmsConfig.gsso.navigatorHeaderBaseUrl.endsWith('/'))
					? UmsConfig.gsso.navigatorHeaderBaseUrl
					: `${UmsConfig.gsso.navigatorHeaderBaseUrl}/`;
			},
			useGlobalHeader: UmsConfig.gsso.useGlobalHeader,
		},
		navigator: {
			baseUrl: UmsConfig.navigator.navigatorBaseUrl
		},
		ods: {
			landingPage: UmsConfig.ods.odsLandingPage,
			clientId: UmsConfig.ods.odsRedirectUri
		},

		// global/rootScope broadcast events
		broadcast: {
			expertAvailabilityChange: 'expertAvailabilityChange',
			onError: "onError",
			openChangePasswordLogin: "openChangePassword.login",
			openChangePasswordLoggedIn: "openChangePassword.loggedIn",
			recentStudiesUpdated: "recentStudiesUpdated",
			popupShow: 'popupShow'
		},

		// for non global/local scope events
		events: {
			headerOpenStudySelector: 'header.openStudySelector'
		},
		// local storage keys
		localStorageKeys: {
			session: 'portalApp_session',
			gssoSession: 'msp3_global_header_auth',
			lastAction: 'lastAction',
			lastActionLocked: 'lastActionLocked',
			studies: 'portalApp_studies',
			loginWarning: 'portalApp_login_warning',
			globalHeaderInitTime: 'msp3_header_init'
		},

		// session storage keys
		sessionKeys: {
			study: 'portalApp_study',
			studyMode: 'portalApp_studyMode',
			trackerInfo: 'trackerInfo',
			lang: 'portalApp_locale'
		},

		workers: {
			progressBar: 'workers/ertButtonProgressBarWorker.js',
			polling: 'workers/pollingWorker.js',
			pdf: 'workers/pdf.worker.min.js'
		},

		constants: {
			applicationJson: 'application/json',
			gridPageSize: 30,
			recentStudiesLength: 5,
			defaultHelpModuleId: 'MSP3.Default',
			googleAnalyticsTrackingId: UmsConfig.account.googleAnalyticsTrackingId,
			loginUrl: '/login',
			empty: '^',
			downloadCookieName: 'ReportDownloadCookie',
			expertAvailability: {
				header: 'X-Expert-Availability',
				available: 'Available',
				unavailable: 'Unavailable'
			},
			patterns:{
				restrictedInput: /(^[a-zA-Z0-9,' _.$&\/À-ÿ-]*$)/,
				restrictedInputEscaped: "(^[a-zA-Z0-9,\\' _.$&\\/À-ÿ-]*$)",
				usaZipCode: "(^[0-9]{5}-[0-9]{4}$)|(^[0-9]{5}$)|(^[0-9]{9}$)",
				emailPattern: '[a-zA-Z0-9\\._\\-\\+]+@[a-zA-Z0-9\\.\\-_]+\\.[a-z0-9A-Z]{2,10}',
				dayInput: `^((((0?[1-9]|[12][0-9]|3[01])[-]([Jj][Aa][Nn]|[Mm][Aa][Rr]|[Mm][Aa][Yy]|[Jj][Uu][Ll]|[Aa][Uu][Gg]|[Oo][Cc][Tt]|[Dd][Ee][Cc]))|((0?[1-9]|[12][0-9]|30)[-]([Aa][Pp][Rr]|[Jj][Uu][Nn]|[Ss][Ee][Pp]|[Nn][Oo][Vv]))|((0?[1-9]|[12][0-9])[-]([Ff][Ee][Bb])))[-]((19|20)\\d\\d))?$`
			},
			spiroViewerVersion:{
				spiro2005: 'spiro2005',
				spiro2019: 'spiro2019'
			}
		},
		developmentSettings: {
			disableSignature: UmsConfig.developmentSettings && UmsConfig.developmentSettings.disableSignature
		},
		testDataType: {
			ecg: 1,
			holter: 2,
			extractionEcg: 3,
			apbm: 4,
			homeBP: 9,
			coaPhone: 10,
			coaViaPad: 11,
			coaViaPen: 12,
			coaAm: 13,
			feNo: 14,
			coaDiary: 15,
			coaSite: 16,
			feNoHome: 17,
			syndication: 18,
			slowSpirometry: 20,
			forcedSpirometry: 21,
			form: 22,
			Dlco: 23,
			Mbw: 24,
			Fot: 25,
			bodyPlethysmography: 26,
			homeForcedSpirometry: 27,
			cough: 28
		},
		modalityType: {
			ecoaPhone: 1,
			ecoaWeb: 2,
			ecoaText: 3,
			viaPad: 4,
			viaPen: 5,
			am3: 6,
			diaryPro: 10,
			sitePro: 11,
			ecoaHandheld: 12,
			ecoaTablet: 13,
			cgm: 14,
			feNoHome: 15,
			patch: 8
		},
		demographics: {
			initials: 1,
			dob: 2,
			subjectId1: 3,
			subjectId2: 4,
			ethnicity: 5,
			gender: 6,
			height: 10,
			weight: 11,
			age: 12
		},
		actions: {
			closeVisit: 'closeVisit'
		},
		cdeMode: {
			Ecg: 'Ecg',
			Pft: 'Pft'
		},
		// not sorted, temporarily here
		clinicalDataModuleNames: {
			country: 6,
			site: 7,
			subject: 8,
			visit: 9
		},
		siteMapModuleIds: {
			dashboard: 5005,
			clinicalData: 5006,
			dataManagement: 5007,
			reports: 5008,
			studyPermissions: 5009,
			announcements: 5010,
			logistics: 5114,
			admin: 5116,
			sqf: 5117,
			epx: 5230,
			dataEntry: 6276,
			deviceManagement: 6279,
			iClinica: 6285,
			refmat: 9999,
			cardiacUpload: 5280
		},
		getRouteForModuleId: function (moduleId) {
			switch (parseInt(moduleId)) {
				case this.siteMapModuleIds.dashboard: return this.routes.dashboard;
				case this.siteMapModuleIds.clinicalData: return this.routes.clData;
				case this.siteMapModuleIds.dataManagement: return this.routes.tickets;
				case this.siteMapModuleIds.reports: return this.routes.createReport;
				case this.siteMapModuleIds.studyPermissions: return this.routes.studyPermissions;
				case this.siteMapModuleIds.announcements: return this.routes.announcements;
				case this.siteMapModuleIds.logistics: return this.routes.logistics;
				case this.siteMapModuleIds.cardiacUpload: return this.routes.cardiacUpload;
				case this.siteMapModuleIds.admin: return this.routes.admin;
				case this.siteMapModuleIds.sqf: return this.routes.sqfListSaved;
				case this.siteMapModuleIds.epx: return this.routes.epx;
				case this.siteMapModuleIds.dataEntry: return this.routes.cdePftList;
				case this.siteMapModuleIds.deviceManagement: return this.routes.deviceManagement;
				default: return null;
			}
		},
		businessPermissions: {
			ecoaAudio: 'EcoaAudio',
			iSpiroCreateSubject: 'CreateISpiroSubject',
			iSpiroDeviceAction: 'ISpiroDeviceAction',
			kardiaAction: 'KardiaAction',
			createKardiaSubject: 'CreateKardiaSubject',
			homeBPDeviceAction: 'HomeBPDeviceAction',
			coughDeviceAction: 'CoughDeviceAction',
			arrhythmiaDeviceAction: 'ArrhythmiaDeviceAction',
			newDataEntry: 'NewDataEntry'
		},
		widgetIds: {
			studyMetrics: 5048,
			announcement: 5012,
			enrollment: 5013,
			openDcr: 5265,
			openQuery: 5040,
			visitOverview: 5037,
			upload: 5279
		},
		enrollmentColors: {
			widget: {
				created: "#bb3581",
				assignedCreated: "#BAC72C",
				enrolled: "#498fca",
				assignedEnrolled: "#004C99",
				included: "#d297c2",
				assignedIncluded: "#3399FF",
				randomized: "#e5820d",
				assignedRandomized: "#99CCFF",
				terminated: "#a036d1",
				assignedTerminated: "#663300",
				excluded: "#ac6315",
				assignedExcluded: "#CC6600",
				finished: "#0ec6e7",
				assignedFinished: "#FFB266",
				treatment: "#1eb99e",
				assignedTreatment: "#FFCCCC",
				activated: "#6E1ED6",
				assignedActivated: "#A2E61C"
			},
			info: {
				created: "#bb3581",
				enrolled: "#498fca",
				included: "#d297c2",
				randomized: "#e5820d",
				terminated: "#a036d1",
				excluded: "#ac6315",
				finished: "#0ec6e7",
				treatment: "#1eb99e",
				activated: "#A42AA8"
			}
		},
		testType: {
			forced: "21",
			slow: "20"
		},
		fileUpload: {
			announcement: {
				validFileType: ['jpg', 'png', 'jpeg', 'bmp', 'gif', 'html', 'htm']
			},
			referenceMaterial: {
				fileSize75MB: 75000000,
				fileMaxSize: 2000000000
			},
			defaultFilters: {
				validFileType: ['jpg', 'png', 'jpeg', 'bmp', 'gif', 'pdf', 'docx', 'doc', 'txt', 'xlsx', 'xls', 'xml',
					'pptx', 'ppt', 'csv', 'tif', 'tiff'],
				fileMaxSize: 100000000
			},
			paperPFT: {
				validFileType: UmsConfig.paperPft.validFileTypePft,
				fileMaxSize: UmsConfig.paperPft.fileMaxSizePft
			},
			paperECG: {
				validFileType: UmsConfig.paperEcg.validFileTypeEcg,
				fileMaxSize: UmsConfig.paperEcg.fileMaxSizeEcg
			},
			dcr: {
				validFileType: UmsConfig.paperPft.validFileTypePft,
				fileMaxSize: UmsConfig.paperPft.fileMaxSizePft
			},
			spiroSphere: {
				validFileType: UmsConfig.spiroSphere.validFileType,
				fileMaxSize: UmsConfig.spiroSphere.fileMaxSize
			}
		},
		dataEntryDisplayModes: {
			add: 0,
			edit: 1,
			secondEntry: 2,
			review: 3,
			viewOnly: 4,
			firstEntryByErtForSiteSubmission: 5,
			secondEntryByErtForSiteSubmission: 6,
		},
		fileServiceType: {
			paperPft: 1,
			dcr: 2
		},
		dataEntryState: {
			pendingReview: 'Pending Clario Review',
			pendingAdditionalReview: 'Pending Additional Clario Review',
			pendingFirstEntryByErtForSiteSubmission: 'Pending First Entry by Clario',
			pendingSecondEntry: 'Pending Second Entry by Clario',
			pendingSecondEntryForSiteSubmission: 'Pending Second Entry by Clario',
			pendingSiteInput: 'Pending Site Input',
			approved: 'Input Review Completed',
			rejected: 'Rejected',
			OpenMyTasks: 'OpenMyTasks',
			pendingFirstEntry: 'Pending First Entry',
			firstEntryInProgress: 'First Entry in Progress',
			secondEntryInProgress: 'Second Entry in Progress',
			pendingSiteInputReview: 'Pending Site Input Clario Review',
			overreadCompleted: 'Overread Completed'
		},
		dataManagementState: {
			querySentToSite: 'Query Sent To Site'
		},
		paperDataEntryState: {
			pendingReview: 1,
			pendingAdditionalReview: 2,
			pendingSecondEntry: 3,
			pendingSiteInput: 4,
			approved: 5,
			rejected: 6,
			pendingFirstEntry: 7,
			firstEntryInProgress: 8,
			secondEntryInProgress: 9,
			pendingSiteInputReview: 10,
			pendingFirstEntryByErtForSiteSubmission: 11,
			pendingSecondEntryByErtForSiteSubmission: 12,
		},
		ecoaQuestionType: {
			singleSelect: 2,
			date: 3,
			dateTime: 8,
			time: 7,
			text: 0,
			trueFalse: 100
		},
		fileTypes: {
			pdf: 'pdf',
			zip: 'zip',
			xlsx: 'xlsx'
		},
		ageUnits: {
			years: 'Years',
			months: 'Months',
			weeks: 'Weeks',
			days: 'Days'
		},
		documentIconTypes: {
			word: ['docx', 'doc'],
			pdf: ['pdf'],
			excel: ['xlsx', 'xls', 'csv', 'excel'],
			powerpoint: ['pptx', 'ppt'],
			image: ['tiff', 'gif', 'jpg', 'jpeg', 'png', 'tif', 'bmp'],
			text: ['txt'],
			xml: ['xml'],
			zip: ['zip']
		},
		regex: {
			date: `^((((0?[1-9]|[12][0-9]|3[01])[-]([Jj][Aa][Nn]|[Mm][Aa][Rr]|[Mm][Aa][Yy]|[Jj][Uu][Ll]|[Aa]
						[Uu][Gg]|[Oo][Cc][Tt]|[Dd][Ee][Cc]))|((0?[1-9]|[12][0-9]|30)[-]([Aa][Pp][Rr]|[Jj][Uu][Nn]|[Ss][Ee][Pp]|
						[Nn][Oo][Vv]))|((0?[1-9]|[12][0-9])[-]([Ff][Ee][Bb])))[-]((19|20)\\d\\d))?$`,
			time: `^([0-1][0-9]|[2][0-3]):[0-5][0-9]$`
		},
		visitStatus: {
			complete: 'Complete',
			incomplete: 'Incomplete',
			missed: 'Missed',
			future: 'Future',
			inProgress: 'In Progress',
			skipped: 'Skipped'
		},
		loadingIndicator: {
			sign: 'signature'
		},
		downloadStatus: {
			failure: 'Failure',
			partialSuccess: 'PartialSuccess',
			success: 'Success',
			inProgress: 'InProgress'
		},
		downloadDocumentType: {
			general: 'Document',
			announcement: 'Announcement',
			studyReport: 'My-Report',
			ecgExport: 'ECG-Export',
			trainingDocument: 'Training-Document',
			returnRequest: 'Return-Request',
			clinicalDataReport: 'Clinical-Data',
			reviewReport: 'Report-Review',
			ecgReport: 'ECG-Source-Tracing',
			holterReport: 'Holter',
			dataStatusFormsReport: 'Data-Status-Form',
			abpmReport: 'ABPM',
			myDownload: 'My-Download',
			refMat: 'Reference-Material'
		},
		trackCategory: {
			navigationState: 'NavigationState',
			navigateTo: 'NavigateTo',
			gridFilter: 'ERT Grid::filter',
			gridSort: 'ERT Grid::Sort',
			toggleSidebar: 'ToggleSidebar',
			ertListFilter: 'ERT List::filter',
			download: 'Download',
			reportReview: 'Report Review'
		},
		trackAction: {
			navigation: 'Navigation',
			gridEntry: 'Grid Entry',
			gridEntryScheduledEvents: 'Grid Entry - Scheduled Events',
			breadcrumb: 'Breadcrumb',
			breadcrumbDropdown: 'Breadcrumb-Dropdown',
			tab: 'Tab',
			expanded: 'Expanded',
			collapsed: 'Collapsed'

		},
		trackLabel: {
			levelStudy: 'Level: Study',
			levelCountry: 'Level: Country',
			levelSite: 'Level: Site',
			levelSubject: 'Level: Subject',
			levelEvent: 'Level: Event',
			levelVisit: 'Level: Subject (Visit)'
		},
		trackId: {
			dashboard: 'Dashboard',
			clinicalDataSingle: 'ClinicalDataSingle',
			clinicalData: 'ClinicalData',
			clinicalDataCountry: 'ClinicalDataCountry',
			clinicalDataSite: 'ClinicalDataSite',
			clinicalDataSubject: 'ClinicalDataSubject',
			clinicalDataVisit: 'ClinicalDataVisit',
			clinicalDataSpiroViewer: 'ClinicalDataSpiroViewer',
			clinicalDataECGViewer: 'ClinicalDataECGViewer',
			clinicalDataEcoaViewer: 'ClinicalDataEcoaViewer',
			clinicalDataEcgSourceTracing: 'ClinicalDataEcgSourceTracing',
			clinicalDataHolterReports: 'ClinicalDataHolterReports',
			clinicalDataAbpmReports: 'ClinicalDataAbpmReports',
			clinicalDataStatusForms: 'ClinicalDataStatusForms',
			iSpiroScheduledEvents: 'iSpiroScheduledEvents',
			dataManagement: 'DataManagement',
			reports: 'Reports',
			reportsCreateReport: 'ReportsCreateReport',
			reportsMyReports: 'ReportsMyReports',
			reportsReportReview: 'ReportsReportReview',
			reportsLogi: 'ReportsLogi',
			reportsOds: 'ReportsOds',
			reportsTraining: 'ReportsTraining',
			reportsEcgDataExport: 'ReportsEcgDataExport',
			dataAnalytics: 'DataAnalytics',
			studyPermissions: 'StudyPermissions',
			deviceManagement: 'DeviceManagement',
			deviceManagementFlowscreen: 'DeviceManagementFlowscreen',
			deviceManagementEasyOnePro: 'DeviceManagementEasyOnePro',
			deviceManagementMasterscope: 'DeviceManagementMasterscope',
			deviceManagementSitePro: 'DeviceManagementSitePro',
			deviceManagementSpiroSphere: 'DeviceManagementSpiroSphere',
			deviceManagementISpiro: 'DeviceManagementISpiro',
			deviceManagementClarioApp: 'DeviceManagementClarioApp',
			deviceManagementVivalink: 'DeviceManagementVivalink',
			deviceManagementCough: 'DeviceManagementCough',
			deviceManagementFeno: 'DeviceManagementFeno',
			deviceManagementCgm: 'DeviceManagementCgm',
			deviceManagementEcoaTablet: 'DeviceManagementEcoaTablet',
			deviceManagementEcoaHandheld: 'DeviceManagementEcoaHandheld',
			deviceManagementAM3: 'DeviceManagementAM3',
			deviceManagementDiaryPro: 'DeviceManagementDiaryPro',
			logistics: 'Logistics',
			logisticsSupplyOrder: 'LogisticsSupplyOrder',
			logisticsShipmentTracking: 'LogisticsShipmentTracking',
			logisticsReturnRequests: 'LogisticsReturnRequests',
			announcements: 'Announcements',
			admin: 'Admin',
			adminStudyTracking: 'AdminStudyTracking',
			adminModuleTracking: 'AdminModuleTracking',
			adminUserTracking: 'AdminUserTracking',
			adminUserLoginTracking: 'AdminUserLoginTracking',
			adminStudyConfiguration: 'AdminStudyConfiguration',
			adminStudyConfigurationSingle: 'AdminStudyConfigurationSingle',
			sqf: 'Sqf',
			dataEntry: 'DataEntry',
			dataEntryPaperPft: 'DataEntryPaperPft',
			referenceMaterials: 'ReferenceMaterials',
			myDownloads: 'MyDownloads',
			customerCare: 'CustomerCare',
			epxPortal: 'EpxPortal',
			vcsPortal: 'VcsPortal',
			help: 'Help',
			cardiacUpload: 'CardiacUpload',
		},
		oDataQueryMaxItemsNumber: 120,
		defaultPaginationPageSize: 30,
		roleIds: {
			principalInvestigator: '1',
			shippingContact: '10'
		}
	};
})(UmsConfig);
