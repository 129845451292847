/*
 * dashboardController
 * */

let expertObservable, expertSubscription;

class DashboardController {
	constructor($log, appI18n, configurationService, $filter, dashboardService,
				appConfig, appSession, notificationService) {
		'ngInject';

		this.$log = $log;
		this.appI18n = appI18n;
		this.configurationService = configurationService;
		this.$filter = $filter;
		this.dashboardService = dashboardService;
		this.appConfig = appConfig;
		this.appSession = appSession;
		this.notificationService = notificationService;

		this.dashboardItems = {
			widgets: []
		};
		expertObservable = this.appSession.getExpertAvailabilityObservable();

	}

	$onInit() {
		const self = this;

		this.$log = this.$log.getInstance('DashboardController');
		this.$log.debug('loaded');

		expertSubscription = expertObservable.subscribe(isExpertUnavailable => {
			self.expertAvailable = !isExpertUnavailable;
		});

		this.configurationService.getDashboard().then((data) => {
			this.dashboardItems = data;
			/*
			 This variable holds the position or ordering of each widget based on the configuration for the study
			 and based on the role of the user as defined below.
			 */
			this.widgetOrder = 0;
			/*
			 IMPORTANT!! The order in which the below calls are made is important to preserve the order of the widgets.
			 Site Level role
			 1  Announcements       2   Visits Overview
			 3  Open Queries to Site   4 Study metrics
			 5  Open DCRs               6 Enrollment chart
			 7	Uploads

			 Study Level role or internal user
			 1  Announcements       2 Enrollment chart
			 3  Study metrics            4 Visits Overview
			 5  Open DCRs               6 Open Queries to Site*/

			if (this.dashboardItems.isSiteLevelPermission) {
				this.announcementTitle = this.getTitle(this.appConfig.widgetIds.announcement);
				this.visitOverviewTitle = this.getTitle(this.appConfig.widgetIds.visitOverview);
				this.openQueryTitle = this.getTitle(this.appConfig.widgetIds.openQuery);
				this.studyMetricsTitle = this.getTitle(this.appConfig.widgetIds.studyMetrics);
				this.openDCRTitle = this.getTitle(this.appConfig.widgetIds.openDcr);
				this.enrollmentTitle = this.getTitle(this.appConfig.widgetIds.enrollment);
				this.uploadTitle = this.getTitle(this.appConfig.widgetIds.upload);
			} else {
				this.announcementTitle = this.getTitle(this.appConfig.widgetIds.announcement);
				this.enrollmentTitle = this.getTitle(this.appConfig.widgetIds.enrollment);
				this.studyMetricsTitle = this.getTitle(this.appConfig.widgetIds.studyMetrics);
				this.visitOverviewTitle = this.getTitle(this.appConfig.widgetIds.visitOverview);
				this.openDCRTitle = this.getTitle(this.appConfig.widgetIds.openDcr);
				this.openQueryTitle = this.getTitle(this.appConfig.widgetIds.openQuery);
			}


			if (this.showOpenDCRs || this.showOpenQueries) {
				this.dashboardService.getDcrMetrics();
			}
		}, (data) => {
			this.$log.error(`Error in getting dashboard items: ${data}`);
			//TODO: Show notification MPORT-3604
		});
	}

	$onDestroy() {
		this.dashboardService.unsetDcrMericsSteram();
		expertSubscription.dispose();
		expertObservable = undefined;
		expertSubscription = undefined;
	}

	getWidgetCount() {
		return new Array(this.dashboardItems.widgets.length);
	}

	/* eslint-disable complexity */
	getTitle(moduleId) {
		const item = this.$filter('filter')(this.dashboardItems.widgets, { id: moduleId }, false);
		const showVar = item && item.length > 0;
		
		switch (moduleId) {
			case this.appConfig.widgetIds.studyMetrics:
				this.showStudyMetrics = showVar;
				this.studyMetricsOrder = showVar ? this.widgetOrder : 0;
				break;
			case this.appConfig.widgetIds.announcement:
				this.showAnnouncement = showVar;
				if (showVar) {
					this.announcementsTitle = item[0].title;
				}
				this.announcementOrder = showVar ? this.widgetOrder : 0;
				break;
			case this.appConfig.widgetIds.enrollment:
				this.showEnrollment = showVar;
				if (showVar) {
					this.enrollmentWgtConfig = item[0];
				}
				this.enrollmentOrder = showVar ? this.widgetOrder : 0;
				break;
			case this.appConfig.widgetIds.openDcr:
				this.showOpenDCRs = showVar;
				this.openDCRThreshold = item[0] && item[0].additionalInfo ? item[0].additionalInfo : '';
				this.openDcrOrder = showVar ? this.widgetOrder : 0;
				break;
			case this.appConfig.widgetIds.openQuery:
				this.showOpenQueries = showVar;
				this.openQueryThreshold = item[0] && item[0].additionalInfo ? item[0].additionalInfo : '';
				this.openQueryOrder = showVar ? this.widgetOrder : 0;
				break;
			case this.appConfig.widgetIds.visitOverview:
				this.showVisitOverview = showVar;
				this.visitOverviewOrder = showVar ? this.widgetOrder : 0;
				break;
			 case this.appConfig.widgetIds.upload:
				this.showUploads = showVar;
				if (showVar) {
				this.uploadTitle = item[0].title;
				}
				this.uploadsOrder = showVar ? this.widgetOrder : 0;
				break;
		}

		if (showVar) {
			this.widgetOrder += 1;
		}
		
		return item && item.length > 0 ? item[0].title : '';
	}
	/* eslint-enable complexity */
}

export default DashboardController;
